import { Box, Divider, Grid, Heading, GridItem, FormLabel, Input, FormControl, Button, Popover, PopoverTrigger, PopoverContent, PopoverArrow, PopoverCloseButton, PopoverHeader, PopoverBody, Card, CardHeader, TableContainer, Table, Thead, Tr, Th, Tbody, Td } from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import React from 'react'
import { useNavigate } from 'react-router-dom'


export const PPendingForm = () => {

  const navigate = useNavigate();

  return (
    <>
      <Heading variant='title'>
        Solicitud de Recoleccion
      </Heading>
      <Divider my={5} />
      <Box my={5}>
        <Card>
          <CardHeader bg={'gray.100'}>
            <Heading size='md' variant='title'>Resumen</Heading>
          </CardHeader>
          <Divider mb={4} color='gray.300' />
          <TableContainer>
            <Table variant='simple'>
              <Thead>
                <Tr>
                  <Th>Lorem</Th>
                  <Th>Lorem</Th>
                  <Th>Lorem</Th>
                  <Th>Lorem</Th>
                  <Th>Lorem</Th>
                  <Th>Lorem</Th>
                  <Th>Lorem</Th>
                  <Th>Lorem</Th>
                  <Th>Lorem</Th>
                  <Th>Lorem</Th>
                  <Th>Lorem</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>Ipsum</Td>
                  <Td>Ipsum</Td>
                  <Td>Ipsum</Td>
                  <Td>Ipsum</Td>
                  <Td>Ipsum</Td>
                  <Td>Ipsum</Td>
                  <Td>Ipsum</Td>
                  <Td>Ipsum</Td>
                  <Td>Ipsum</Td>
                  <Td>Ipsum</Td>
                  <Td>Ipsum</Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </Card>
        <Formik
          initialValues={{
            date: ''
          }}
          
          onSubmit={(values) => {
            console.log(values);
          }}
        >
          {({values, getFieldProps, errors}) => (
            <Form>
              <Grid templateColumns='repeat(4, 1fr)' my={5} gap={3} alignItems={'end'}>
                <GridItem colSpan={1}>
                  <FormControl isRequired>
                    <FormLabel>Fecha recoleccion</FormLabel>
                    <Input
                      {...getFieldProps('date')}
                      type='datetime-local'
                    />
                  </FormControl>
                </GridItem>
                <GridItem colSpan={1}>
                <Popover>
                  <PopoverTrigger>
                    <Button fontSize='22px'><i class="bi bi-question-circle"></i></Button>
                  </PopoverTrigger>
                  <PopoverContent bg='gray.100'>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverHeader>Aviso!!</PopoverHeader>
                    <PopoverBody>Recolecciones mismo dia antes de la 1:00 pm</PopoverBody>
                  </PopoverContent>
                </Popover>
                </GridItem>
              </Grid>
              <Grid templateColumns='repeat(2, 1fr)' gap={4} mt={10}>
                      <GridItem colSpan={1}>
                          <Button variant='secondary' onClick={() => navigate('/recolecciones/pendientes')}>Cancelar</Button>
                      </GridItem>
                      <GridItem colStart={4} colEnd={6}>
                          <Button type='submit' variant='primary'>{'Generar'}</Button>
                      </GridItem>
                  </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </>
  )
}
