import { Box, Button, Card, CardBody, CardHeader, Checkbox, Divider, Flex, FormControl, FormErrorMessage, FormLabel, Grid, GridItem, Heading, Input, Menu, MenuButton, MenuItem, MenuList, Select, SimpleGrid, Spacer, Stack, StackDivider, Table, TableCaption, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tr } from '@chakra-ui/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'

export const OrdersSummary = () => {

  const navigate = useNavigate();

  return (
    <>
      <Heading variant={'title'} mb={5}>
        Resumen
      </Heading>
      <Grid templateColumns={'repeat(2, 1fr)'} gap={5} my={5}>
        <Card>
          <CardHeader bg={'gray.100'}>
            <Heading size='md' variant='title'>Datos de origen</Heading>
          </CardHeader>
          <Divider mb={1} color='gray.300' />
  
          <CardBody>
            <Stack spacing='4'>
              <Box>
                <Heading size='xs' textTransform='uppercase'>
                  REX99
                </Heading>
                <Text pt='2' fontSize='sm'>
                  Datos
                </Text>
              </Box>
              <Box>
                <Heading size='xs' textTransform='uppercase'>
                  Referencias
                </Heading>
                <Text pt='2' fontSize='sm'>
                  datos
                </Text>
              </Box>
            </Stack>
          </CardBody>
        </Card>

        <Card>
          <CardHeader bg={'gray.100'}>
            <Heading size='md' variant='title'>Datos de destino</Heading>
          </CardHeader>
          <Divider mb={1} color='gray.300' />
  
          <CardBody>
            <Stack spacing='4'>
              <Box>
                <Heading size='xs' textTransform='uppercase'>
                  REX99
                </Heading>
                <Text pt='2' fontSize='sm'>
                  Datos
                </Text>
              </Box>
              <Box>
                <Heading size='xs' textTransform='uppercase'>
                  Correo contacto
                </Heading>
                <Text pt='2' fontSize='sm'>
                  Prueba
                </Text>
              </Box>
              <Box>
                <Heading size='xs' textTransform='uppercase'>
                  Referencias
                </Heading>
                <Text pt='2' fontSize='sm'>
                  datos
                </Text>
              </Box>
            </Stack>
          </CardBody>
        </Card>
      </Grid>

      <Card>
        <CardHeader bg={'gray.100'}>
          <Heading size='md' variant='title'>Cargas</Heading>
        </CardHeader>
        <Divider mb={4} color='gray.300' />
        <TableContainer>
          <Table variant='simple'>
            <Thead>
              <Tr>
                <Th>Lorem</Th>
                <Th>Lorem</Th>
                <Th>Lorem</Th>
                <Th>Lorem</Th>
                <Th>Lorem</Th>
                <Th>Lorem</Th>
                <Th>Lorem</Th>
                <Th>Lorem</Th>
                <Th>Lorem</Th>
                <Th>Lorem</Th>
                <Th>Lorem</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>Ipsum</Td>
                <Td>Ipsum</Td>
                <Td>Ipsum</Td>
                <Td>Ipsum</Td>
                <Td>Ipsum</Td>
                <Td>Ipsum</Td>
                <Td>Ipsum</Td>
                <Td>Ipsum</Td>
                <Td>Ipsum</Td>
                <Td>Ipsum</Td>
                <Td>Ipsum</Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
      </Card>

      <Grid templateColumns={'repeat(5, 1fr)'} gap={5} my={5}>
        <Card>
            <CardHeader bg={'gray.100'}>
              <Heading size='md' variant='title'>Lorem</Heading>
            </CardHeader>
            <Divider mb={1} color='gray.300' />
    
            <CardBody>
              <Stack spacing='4'>
                <Box>
                  <Text fontSize='md' textTransform={'uppercase'}>
                    Contado
                  </Text>
                </Box>
              </Stack>
            </CardBody>
          </Card>

          <Card>
            <CardHeader bg={'gray.100'}>
              <Heading size='md' variant='title'>Lorem</Heading>
            </CardHeader>
            <Divider mb={1} color='gray.300' />
    
            <CardBody>
              <Stack spacing='4'>
                <Box>
                  <Text fontSize='md' textTransform={'uppercase'}> 
                    EAD
                  </Text>
                </Box>
              </Stack>
            </CardBody>
          </Card>

          <Card>
            <CardHeader bg={'gray.100'}>
              <Heading size='md' variant='title'>Lorem</Heading>
            </CardHeader>
            <Divider mb={1} color='gray.300' />
    
            <CardBody>
              <Stack spacing='4'>
                <Box>
                  <Text fontSize='md' textTransform={'uppercase'}>
                    Cobertura minima
                  </Text>
                </Box>
              </Stack>
            </CardBody>
          </Card>

          <Card>
            <CardHeader bg={'gray.100'}>
              <Heading size='md' variant='title'>Lorem</Heading>
            </CardHeader>
            <Divider mb={1} color='gray.300' />
    
            <CardBody>
              <Stack spacing='4'>
                <Box>
                  <Heading size='xs' textTransform='uppercase'>
                    Orden de almacen
                  </Heading>
                  <Text fontSize='md'>
                    - algo
                  </Text>
                </Box>
              </Stack>
            </CardBody>
          </Card>

          <Card>
            <CardHeader bg={'gray.100'}>
              <Heading size='md' variant='title'>Lorem</Heading>
            </CardHeader>
            <Divider mb={1} color='gray.300' />
    
            <CardBody>
              <Stack spacing='4'>
                <Box>
                  <Text fontSize='md' textTransform={'uppercase'}>
                    Maniobra de carga
                  </Text>
                </Box>
              </Stack>
            </CardBody>
          </Card>
  
      </Grid>
      <Flex mt={5} flexDir={['column-reverse', 'row', 'row', 'row']} gap={5}>
        <Button onClick={() => navigate('/orden/cargas')} variant='secondary'>Regresar</Button>
        <Spacer/>
        <Button onClick={() => navigate('/orden/gracias')} variant='primary'>Continuar</Button>
      </Flex>
    </>
  )
}
