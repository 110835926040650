import { createStore } from 'redux'
import { userReducer } from "./slices/userSlice";
import { applyMiddleware, combineReducers } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage'
import { creditRequestReducer } from './slices/creditRequestSlice';
import { thunk } from 'redux-thunk';
import { cartReducer } from './slices/cartSlice';

const rootReducer = combineReducers({
    user: userReducer,
    credit_request: creditRequestReducer,
    cart: cartReducer
})

const persistConfig = {
    key: 'root',
    storage
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(persistedReducer, applyMiddleware(thunk));
export const persistor = persistStore(store);