import { Box, Button, HStack, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Stack, Text, VStack } from '@chakra-ui/react'
import React, { useState } from 'react'
import BadgeStatusCR from './BadgeStatusCR'
import { Form, Formik } from 'formik'
import useCreditRequest from '../../hooks/useCreditRequest'
import { CRStatus } from '../../enums/CRStatus'
import { useNavigate } from 'react-router-dom'
import { colors } from '../../themes/colors'
import Swal from 'sweetalert2'

const ModalReviewForm = ({
    creditRequest: cr,
    onOpen,
    isOpen,
    onClose
}) => {

    const { notifyReviewToUser, authorizeCR, changeStatusToCR } = useCreditRequest();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const handleChangeStatus = async (status_id) => {
        setIsLoading(true);
        await changeStatusToCR(status_id, cr.id)
            .then(({ success, message }) => {
                if (success) {
                    onClose();
                    Swal.fire({
                        title: `Solicitud de crédito ${(status_id == CRStatus.Autorizada) ? 'autorizada' : 'denegada'}`,
                        text: 'Se ha cambiado el estatus de la solicitud de crédito y se le ha notificado al usuario.',
                        icon: 'success',
                        confirmButtonColor: colors['primary']['900']
                    });
                }
            });
    }

    const handleNotifyReview = async () => {
        await notifyReviewToUser(cr.id)
            .then((data) => {
                if (data.status) {
                    Swal.fire({
                        title: `Notificación enviada.`,
                        text: 'Se le ha notificado al usuario que ha habido retroalimentación en su solicitud de créditle ha notificado al usuario que ha habido retroalimentación en su solicitud de crédito.',
                        icon: 'success',
                        confirmButtonColor: colors['primary']['900']
                    });
                }
            })
            .catch(err => console.log(err.message));
    }

    const wasReviewed = () => {
        return (cr.documentation.csf_comments ||
            cr.documentation.ine_comments ||
            cr.documentation.constitutional_act_comments ||
            cr.documentation.account_status_comments ||
            cr.documentation.address_comprobant_comments ||
            cr.documentation.representative_power_comments ||
            cr.documentation.sign_comments)
    }

    return (
        <Modal size='lg' isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalContent>
                    <ModalHeader>Información del crédito</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <BadgeStatusCR status={cr.status.status} statusId={cr.status.id} mb={3} />

                        <Formik
                            initialValues={{
                                amount_authorized: cr.amount_requested,
                                term_authorized: cr.term_requested
                            }}
                            onSubmit={(values, setErrors) => {
                                authorizeCR(values, cr.id)
                                    .then(({ errors }) => {
                                        if (errors) {
                                            setErrors(errors);
                                        } else {
                                            onClose();
                                            Swal.fire({
                                                title: 'Solcitud de crédito autorizada',
                                                text: 'Se ha notificado al cliente, acerca de su solicitud de crédito.',
                                                icon: 'success',
                                                showCloseButton: false,
                                                didClose: () => {
                                                    navigate('/solicitudes-de-credito');
                                                },
                                                confirmButtonColor: colors['primary']['900']
                                            })
                                        }
                                    })
                            }}
                        >
                            {({ isSubmitting }) => (
                                <Form>
                                    <VStack spacing={5} alignItems='start'>
                                        <Text><i className='bi bi-person-fill'></i> <b>Nombre</b>: {cr.fiscal_address.company_name}</Text>

                                        {/* Inicio Credito */}
                                        <Box>
                                            <Text fontWeight='bold'><i className='bi bi-currency-dollar'></i> Crédito</Text>
                                            <HStack flex={1} alignItems='start'>
                                                <VStack alignItems='start' flex={1}>
                                                    <Text fontWeight='semibold' fontSize='sm'>Monto solicitado</Text>
                                                    <Input value={`${cr.amount_requested}`} disabled />
                                                </VStack>
                                                <VStack alignItems='start' flex={1}>
                                                    <Text fontWeight='semibold' fontSize='sm'>Monto propuesta</Text>
                                                    <Input placeholder={`${cr.amount_requested}`} disabled={wasReviewed()} />
                                                </VStack>
                                            </HStack>
                                        </Box>
                                        {/* Fin Credito */}

                                        {/* Inicio Plazo */}
                                        <Box>
                                            <Text fontWeight='bold'><i className='bi bi-calendar'></i> Plazo (días)</Text>
                                            <HStack w='full' flex={1}>
                                                <HStack flex={1} alignItems='start'>
                                                    <VStack alignItems='start' flex={1}>
                                                        <Text fontWeight='semibold' fontSize='sm'>Plazo solicitado</Text>
                                                        <Input value={`${cr.term_requested}`} disabled />
                                                    </VStack>
                                                    <VStack alignItems='start' flex={1}>
                                                        <Text fontWeight='semibold' fontSize='sm'>Plazo propuesta</Text>
                                                        <Input placeholder={`${cr.term_requested}`} disabled={wasReviewed()} />
                                                    </VStack>
                                                </HStack>
                                            </HStack>
                                        </Box>
                                        {/* Fin Plazo */}

                                        {
                                            wasReviewed()
                                                ?
                                                (
                                                    <Stack flexDirection='row' w='100%' mb={3}>
                                                        <Button variant='secondary' onClick={onClose} flex={1}>Cancelar</Button>
                                                        <Button isLoading={isSubmitting} variant='primary' onClick={handleNotifyReview} >Notificar retroalimentación</Button>
                                                    </Stack>
                                                )
                                                : (
                                                    <Stack flexDirection='row' w='100%' mb={3}>
                                                        <Button onClick={() => handleChangeStatus(CRStatus.Denegada)} flex={1} variant='secondary'>Denegar</Button>
                                                        <Button isLoading={isSubmitting} loadingText='Procesando' type='submit' flex={1} variant='primary'>Autorizar</Button>
                                                    </Stack>
                                                )
                                        }
                                    </VStack>
                                </Form>
                            )}
                        </Formik>
                    </ModalBody>
                </ModalContent>
            </ModalContent>
        </Modal>
    )
}

export default ModalReviewForm