import React, { useEffect } from 'react'
import { Outlet, Route, Routes } from 'react-router-dom'
import OrdersPage from '../pages/portal/OrdersPage'
import CRFirstStep from '../pages/portal/credit_request/CRFirstStep'
import CRListPage from '../pages/portal/credit_request/CRListPage'
import NewCRLayout from '../pages/layouts/credit_request/NewCRLayout'
import PublicLayout from '../pages/layouts/PublicLayout';
import { useSelector } from 'react-redux'
import moment from 'moment'
import {persistor} from '../redux/globalStore'
import ProfilePage from '../pages/user/ProfilePage'
import UsersListPage from '../pages/user/UsersListPage'
import CRSecondStep from '../pages/portal/credit_request/CRSecondStep'
import CRThirdStep from '../pages/portal/credit_request/CRThirdStep'
import CRFourthStep from '../pages/portal/credit_request/CRFourthStep'
import CRSummary from '../pages/portal/credit_request/CRSummary'
import CRThankYou from '../pages/portal/credit_request/CRThankYou'
import NewUserPage from '../pages/user/NewUserPage'

import AddressesPage from '../pages/portal/address/AddressesPage'
import TemplatesPage from '../pages/portal/templates/TemplatesPage'
import { NotificationListPage } from '../pages/portal/notification/NotificationListPage'
import NSUPage from '../pages/user/NSUPage'
import { AddressForm } from '../components/address/AddressForm'
import { TemplatesForm } from '../components/templates/TemplatesForm'
import { MyCardsPage } from '../pages/portal/cards/MyCardsPage'
import InvoicesListPage from '../pages/invoices/InvoicesListPage'
import InvoicePaymentPage from '../pages/invoices/InvoicePaymentPage'
import TestSocket from '../pages/portal/TestSocket'
import { CreateNewsPage } from '../components/news/CreateNewsPage'
import { ListNewsPage } from '../pages/portal/news/ListNewsPage'
import { OrdersForm } from '../components/orders/first_step/OrdersFirstStep'
import { FreightForm } from '../components/orders/second_step/FreigthForm'
import { OrdersSummary } from '../components/orders/third_step/OrdersSummary'
import { ConfigPage } from '../pages/user/config/ConfigPage'
import { PickingListPage } from '../pages/picking/PickingListPage'
import { PPendingListPage } from '../pages/picking/PPendingListPage'
import { PPendingForm } from '../components/picking/PPendingForm'
import { OThankYou } from '../components/orders/thank_you/OThankYou'
import { ShowNews } from '../components/news/ShowNews'
import CRReview from '../pages/portal/credit_request/CRReview'
import { QuoterPage } from '../pages/quoter/QuoterPage'
import { PendingInvoicePage } from '../pages/invoices/PendingInvoicePage'

const InitRouter = () => {

  const user = useSelector((state) => state.user);

  useEffect(() => {
    if(user.data.expireTime && moment().diff(user.data.expireTime) >= 0) {
      persistor.purge();
    }
  }, []);

  return (
    <PublicLayout>
      <Routes>
        <Route path="/" element={<OrdersPage />} />
        <Route path="/:user_id" element={<OrdersPage />} />
        <Route path='/orden' element={<OrdersForm />} />
        <Route path="/orden" element={<Outlet />}>
          <Route path="cargas" element={<FreightForm />} />
          <Route path="resumen" element={<OrdersSummary />} />
          <Route path='gracias' element={<OThankYou />} />
        </Route>

        {/* MIS DIRECCIONES */}
        <Route path="/mis-direcciones" element={<AddressesPage />} />
          <Route path="/direccion" element={<AddressForm />} />
          <Route path="/direccion/:address_id" element={<AddressForm />} />
        {/* FIN MIS DIRECCIONES */}

        {/* MIS PLANTILLAS */}
        <Route path="/mis-plantillas" element={<TemplatesPage />} />
          <Route path="/plantilla" element={<TemplatesForm />} />
          <Route path="/plantilla/:tp_id" element={<TemplatesForm />} />
        {/* FIN MIS PLANTILLAS */}

        {/* PERFIL USUARIO */}
        <Route path="/usuarios" element={<UsersListPage />} />
        <Route path="/usuarios" element={<Outlet />}>
          <Route path="nuevo" element={<NewUserPage />} />
          <Route path="editar/:user_id" element={<NewUserPage />} />
          <Route path="nuevo-secundario" element={<NSUPage />} />
          <Route path="editar/secundario/:user_id" element={<NSUPage />} />
        </Route>
        <Route path="/mi-perfil" element={<ProfilePage />} />
        <Route path="/mi-configuracion" element={<ConfigPage />} />
        {/* FIN PERFIL USUARIO */}

        {/* SOLICITUDES DE CREDITO */}
        <Route path="/solicitudes-de-credito" element={<CRListPage />} />
        <Route path="/solicitudes-de-credito" element={<NewCRLayout />}>
          <Route path="datos-fiscales" element={<CRFirstStep />} />
          <Route path="procedimiento-de-pago" element={<CRSecondStep />} />
          <Route path="informacion-del-cliente" element={<CRThirdStep />} />
          <Route path="documentacion" element={<CRFourthStep />} />
          <Route path="resumen" element={<CRSummary />} />
          <Route path="retroalimentacion/:cr_id" element={<CRReview />} />
          <Route path="previsualizacion/:cr_id" element={<CRReview />} />
          <Route path="gracias" element={<CRThankYou />} />
        </Route>
        {/* FIN SOLICITUDES DE CREDITO */}

        {/* NOTIFICACIONES */}
        <Route path="/notificaciones" element={ <NotificationListPage />} />
        <Route path="/noticias" element={ <ListNewsPage />} />
        <Route path="/noticia/edit/:news_id" element={ <CreateNewsPage />} />
        <Route path="/noticia/:news_id" element={ <ShowNews />} />
        <Route path="/noticia" element={ <CreateNewsPage />} />
        {/* FIN DE NOTIFICACIONES */}

        {/* MIS TARJETAS */}
        <Route path="/mis-tarjetas" element={<MyCardsPage />} />
          
        {/* FACTURACION */}
        <Route path="/facturas" element={<InvoicesListPage />} />
        <Route path="/facturas" element={<Outlet />}>
          <Route path="pagar" element={<InvoicePaymentPage />} />
        </Route>
        <Route path="/facturas-pendientes" element={<PendingInvoicePage />} />
        {/*  */}

        {/* MIS RECOLECCIONES */}
        <Route path='/recolecciones' element={<PickingListPage />} />
        <Route path='/recolecciones-pendientes' element={<PPendingListPage />} />
        <Route path='/recoleccion' element={<PPendingForm />} />
        {/* FIN RECOLECCIONES */}

        {/* COTIZADOR */}
        <Route path="/cotizador" element={<QuoterPage />} />
        {/* FIN COTIZADOR */}

        <Route path="/test" element={<TestSocket />} />
      </Routes>
    </PublicLayout>
  )
}

export default InitRouter