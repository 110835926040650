import { createSlice } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";

const initialState = {
    loggedin: false,
    data: null
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        login: (_, action) => ({
            loggedIn: true,
            data: action.payload
        }),
        logout: () => ({
            loggedIn: false,
            data: null
        }),
        setProfilePic: (state, action) => ({
            ...state,
            data: {
                ...state.data,
                profile_pic: action.payload
            }
        }),
        syncHeaderData: (state, action ) => ({
            ...state,
            data: {
                ...state.data,
                name: action.payload.name,
                last_name: action.payload.last_name
            }
        })
    },
    extraReducers: (builder) => {
        builder.addCase(PURGE, () => {
            return initialState;
        });
    }
});

export const { login, logout, setProfilePic, syncHeaderData } = userSlice.actions;
export const userReducer = userSlice.reducer;