import { Button, Divider, Flex, FormControl, FormErrorMessage, FormLabel, Grid, GridItem, Input, Select, Text, Textarea } from '@chakra-ui/react'
import { Form, Formik } from 'formik';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import useSepomex from '../../../hooks/useSepomex';

export const OriginData = ({
    setAccordionIndex
}) => {
    const navigate = useNavigate();
    const maxDigitsNumber = 10;
    
    const { getDataFromPostalCode } = useSepomex();
    const [ cpData, setCpData ] = useState(null);
    const [ valueNumber, setValueNumber ] = useState('')
    const [ valueRfc, setValueRfc ] = useState('');
    const [ isMaxDigits, setIsMaxDigits ] = useState(false);
    const [ showWarning, setShowWarning ] = useState(false);

    const handlePostalCode = (event, handleChange) => {
        handleChange(event);
        const postal_code = event.target.value;

        loadCitiesAndStates(postal_code);
    }

    const loadCitiesAndStates = async (postal_code) => {
        if (postal_code.toString().length === 5) {
            const data = await getDataFromPostalCode(postal_code);
            setCpData(data);
        } else {
            setCpData(null);
        }
    }

    const handleValidateNumber = (e) => {
        const inputValue = e.target.value;
        const onlyNums = inputValue.replace(/\D/g, '');

        if ( onlyNums.length <= maxDigitsNumber ) {
            setValueNumber(onlyNums);
            setIsMaxDigits(false);
        } else {
            setIsMaxDigits(true);
        }
    }

    const handleWarning = (e) => {
        const newValue = e.target.value;
        setValueRfc(newValue);
        if (newValue.length <= 13) {
            setShowWarning(false);
        } else {
            setShowWarning(true);
        }
    }

  return (
    <>
        <Formik
            initialValues={{
                company_name: '',
                vatnum: '',
                telephone: '',
                street: '',
                num_ext: '',
                num_int: null,
                postal_code: '',
                suburb: '',
                city: '',
                state: '',
                reference: ''
            }}
            onSubmit={(values, {setErrors}) => {
                values.telephone = valueNumber;
                values.vatnum = valueRfc;
                console.log(values);
                setAccordionIndex(1);
            }}
        >
            {({ values, getFieldProps, errors, handleChange }) => (
                <Form>
                    <Grid templateColumns='repeat(2, 1fr)'>
                    {/* <FormControl>
                        <FormLabel>Favoritos</FormLabel>
                        <Select>
                            <option value='option1'>Option 1</option>
                            <option value='option2'>Option 2</option>
                            <option value='option3'>Option 3</option>
                        </Select>
                        <FormErrorMessage className='form-error-message'>
                        </FormErrorMessage>
                    </FormControl> */}
                    </Grid>
                    <Divider my={4} />
                    <Grid templateColumns='repeat(4, 1fr)' gap={2}>
                        <GridItem colSpan={2}>
                            <FormControl isRequired mb={3}>
                                <FormLabel>Razón Social</FormLabel>
                                <Input type='text' {...getFieldProps('company_name')} />
                                <FormErrorMessage className='form-error-message'>
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl isRequired mb={3}>
                                <FormLabel>RFC (XAXX010101000)</FormLabel>
                                <Input type='text' {...getFieldProps('vatnum')} maxLength={13} value={valueRfc} onChange={handleWarning} />
                                {showWarning && (
                                    <Text color="red.500">¡Has alcanzado el límite de 13 caracteres!</Text>
                                )}
                                <FormErrorMessage className='form-error-message'>
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl isRequired mb={3}>
                            <FormLabel>Código postal</FormLabel>
                                <Input type='text' {...getFieldProps('postal_code')} onChange={(e) => handlePostalCode(e, handleChange)} />
                                <FormErrorMessage className='form-error-message'>
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl isRequired mb={3}>
                                <FormLabel>Colonia</FormLabel>
                                <Select {...getFieldProps('suburb')}>
                                    <option>Selecciona una colonia</option>
                                    {
                                        (cpData && cpData.colonias) && cpData.colonias.map((col) => <option value={col.colonia} key={col.id}>{col.colonia}</option>)
                                    }
                                </Select>
                                <FormErrorMessage className='form-error-message'>
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl isRequired mb={3}>
                                <FormLabel>Estado</FormLabel>
                                <Select {...getFieldProps('state')}>
                                    <option>Selecciona un estado</option>
                                    {
                                        cpData && cpData.estado && <option value={cpData.estado}>{cpData.estado}</option>
                                    }
                                </Select>
                                <FormErrorMessage className='form-error-message'>
                                </FormErrorMessage>
                            </FormControl>
                        </GridItem>
                        <GridItem colSpan={2} >
                            <FormControl isRequired mb={3}>
                                <FormLabel>Municipio</FormLabel>
                                <Select {...getFieldProps('city')}>
                                    <option>Selecciona un Municipio</option>
                                    {
                                        cpData && cpData.municipio && <option value={cpData.municipio}>{cpData.municipio}</option>
                                    }
                                </Select>
                                <FormErrorMessage className='form-error-message'>
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl isRequired mb={3}>
                                <FormLabel>Calle</FormLabel>
                                <Input type='text' {...getFieldProps('street')} />
                                <FormErrorMessage className='form-error-message'>
                                </FormErrorMessage>
                            </FormControl>
                            <Grid templateColumns='repeat(2, 1fr)' gap={3}>
                                <FormControl isRequired mb={3}>
                                    <FormLabel>Número Exterior</FormLabel>
                                    <Input type='number' {...getFieldProps('num_ext')} />
                                    <FormErrorMessage className='form-error-message'>
                                    </FormErrorMessage>
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Número Interior</FormLabel>
                                    <Input type='number' {...getFieldProps('num_int')} />
                                    <FormErrorMessage className='form-error-message'>
                                    </FormErrorMessage>
                                </FormControl>
                            </Grid>
                            <FormControl isRequired mb={3}>
                                <FormLabel>Teléfono</FormLabel>
                                <Input type='text' {...getFieldProps('telephone')} value={valueNumber} onChange={handleValidateNumber} />
                                {isMaxDigits && (
                                    <Text color="red.500">¡Has alcanzado el límite de 10 dígitos!</Text>
                                )}
                            </FormControl>
                            <FormControl isRequired mb={3}>
                                <FormLabel>Referencias</FormLabel>
                                <Textarea placeholder='Here is a sample placeholder' {...getFieldProps('reference')} />
                                <FormErrorMessage className='form-error-message'>
                                </FormErrorMessage>
                            </FormControl>
                        </GridItem>
                    </Grid>
                    <Flex mt={2} flexDir={['column-reverse', 'row', 'row', 'row']} gap={5}>
                        <Button onClick={() => navigate('/mis-ordenes')} variant='secondary'>Regresar</Button>
                        <Button type='submit' variant='primary'>Continuar</Button>
                    </Flex>
                </Form>
            )}
        </Formik>
    </>
  )
}
