import { Badge, Box, Button, ButtonGroup, Card, CardBody, CardFooter, Divider, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, HStack, Heading, Image, Input, ListItem, Menu, MenuButton, MenuItem, MenuList, Skeleton, Stack, Table, Tbody, Td, Text, Th, Thead, Tooltip, Tr, UnorderedList, VStack, useDisclosure } from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import { useNotification } from '../../../hooks/useNotification'
import { Link, useNavigate } from 'react-router-dom'
import moment from 'moment'
import { useSelector } from 'react-redux'

export const NotificationListPage = () => {

    const btnRef = useRef();
    const { getNotifications, getNotificationTypes, setAllAsSeen } = useNotification();
    const [ notifications, setNotifications ] = useState([]);
    const [ isLoading, setIsLoading ] = useState(true);
    const {token} = useSelector((state) => state.user.data);
    const [ notificationTypes, setNotificationTypes ] = useState('');

    const handleNavigate = (url, nid) => {
        fetch(`${process.env.REACT_APP_API_URL}/api/v1/notification/set_as/opened/${nid}`, {
            method: 'POST',
            body: JSON.stringify({
                type: 'seen'
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        window.location.href = url;
    }

    useEffect(() => {
        (async () => {
            const result = await getNotifications();

            console.log(result.total);

            if(result.total) {
                setAllAsSeen()
                    .then((data) => {
                        console.log(data)
                    });
            }

            setNotifications(result.notifications);
            setIsLoading(false);
        })();
    }, []);

    useEffect(() => {
        (async () => {
            const result = await getNotificationTypes();
            setNotificationTypes(result);
        })();
    }, []);
    

  return (
    <>
      <Heading variant='title'>Centro de Notificaciones</Heading>
      <Divider my={2} />
        {
            !isLoading
                ?
        <Box mt='10px'>
            {
                notifications.map((noti) => (
                    <Box key={noti.id} my={2}>
                        <Link onClick={() => handleNavigate(noti.url, noti.id)}>
                            <Card w='100%' ref={btnRef}>
                                <CardBody display='flex' justifyContent='space-between' w='100%' backgroundColor={(noti.opened) ? 'transparent' : 'gray.100' }>
                                    <Stack spacing='3'>
                                        <Box display='flex' alignItems='center' gap='10px'>
                                            <VStack  alignItems='start'>
                                                <Badge colorScheme='purple' key={noti.id}>
                                                    {noti.type.type}
                                                </Badge>
                                                <Text fontWeight={(noti.opened) ? 'regular' : 'bold'} display='flex' alignItems='center' size='md'>
                                                    {(!noti.opened) && <Text mr={2} fontSize='10px' color='red'><i class="bi bi-circle-fill"></i></Text>} 
                                                    {noti.message}
                                                </Text>
                                            </VStack>
                                        </Box>
                                    </Stack>
                                    <Stack>
                                        <Text fontSize='sm' color='gray'>
                                            { moment(noti.created_at).format('d/MM/Y h:mm')  }
                                        </Text>
                                    </Stack>
                                </CardBody>
                            </Card>
                        </Link>
                    </Box>
            ))}
        </Box>
        :
        <Stack mt={10}>
          <Skeleton height='30px' />
          <Skeleton mt={3} height='30px' />
          <Skeleton mt={3} height='30px' />
          <Skeleton mt={3} height='30px' />
          <Skeleton mt={3} height='30px' />
          <Skeleton mt={3} height='30px' />
          <Skeleton mt={3} height='30px' />
        </Stack>
        }
    </>
  )
}
