import { useSelector } from 'react-redux';

export const useAddresses = () => {

    const base_url = `${process.env.REACT_APP_API_URL}/api/v1/addresses`;

    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }

    const { token } = useSelector((state) => state.user.data);

    const getAddreses = async (url = null, params = null) => {
        // const url = params === '' ? base_url : (!params ? base_url : `${base_url}?` + new URLSearchParams(params).toString());
        // url = url || base_url;

        if(url) {
            url = params === '' ? url : (!params ? url : `${url}&` + params.toString());
        } else {
            url = params === '' ? base_url : (!params ? base_url : `${base_url}?` + params.toString());
        }

        const res = await fetch(url, {
            method: 'GET',
            headers: {
                ...headers,
                'Authorization': `Bearer ${token}`
            }
        });
        return await res.json();
    }

    const getAddress = async (address_id) => {
        const res = await fetch(`${base_url}/${address_id}`, {
            method: 'GET',
            headers: {
                ...headers,
                'Authorization': `Bearer ${token}`
            }
        });
        const { data } = await res.json();
        return data;
    }


    const createAddress = async (values) => {

        const res = await fetch(`${base_url}`, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                ...headers,
                'Authorization': `Bearer ${token}`
            }
        });

        if(res.status === 200)  {
            const data = await res.json();
            return data;
        } else { 
            return 0;
        }
    }

    const updateAddress = async (body, address_id) => {
        const res = await fetch(`${base_url}/${address_id}`, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                ...headers,
                'Authorization': `Bearer ${token}`
            }
        });
        
        if(res.status == 200) {
            const data = await res.json();
            return data;
        } else {
            return {
                success: false,
                errors: {
                    global: 'Hubo un error con la petición o con el servidor, intenta de nuevo.'
                }
            }
        }
    }

    const deleteAddress = async (address_id) => {
        const res = await fetch(`${base_url}/${address_id}`, {
            method: 'DELETE',
            headers: {
                ...headers,
                'Authorization': `Bearer ${token}`
            }
        });

        const data = await res.json();
        return data; 
    }

    const getAddressesTypes = async () => {
        const res = await fetch(`${base_url}/get_addresses_types`, {
            method: 'GET',
            headers: {
                ...headers,
                'Authorization': `Bearer ${token}`
            }
        });

        const data = await res.json();
        return data;
    }

    const toggleFavoriteAddress = async (id) => {
        const res = await fetch(`${base_url}/toggle_favorite/${id}`, {
            method: 'POST',
            headers: {
                ...headers,
                'Authorization': `Bearer ${token}`
            }
        });

        if(res.status === 200)  {
            const data = await res.json();
            return data;
        } else { 
            return 0;
        }
    }
    
  return {
    getAddreses,
    getAddress,
    createAddress,
    updateAddress,
    deleteAddress,
    getAddressesTypes,
    toggleFavoriteAddress
  };
}
