import React from 'react'
import { Box, Button, Flex, FormControl, FormErrorMessage, FormLabel, Heading, Image, Input, InputGroup, InputLeftElement, Spinner, Text, VStack } from '@chakra-ui/react'
import logo from '../../assets/images/commons/logo.png';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useUser } from '../../hooks/useUser';
import { useState } from 'react';

const RecoveryPage = () => {

    const { sendResetPasswordMail } = useUser();
    const [sended, setSended] = useState(false);

    return (
        <Box
            display='flex'
            justifyContent='center'
        >
            <Box
                flex={1}
                maxW='600px'
            >
                <VStack
                    w='100%'
                >
                    <Image width='300px' src={logo} />
                    <VStack alignItems='start'
                        mt={2}
                        w='100%'
                    >
                        <Heading color='white'>Recuperar cuenta</Heading>
                        {
                            !sended
                                ?

                                <>
                                    <Text fontSize='xl' fontWeight='semibold' color='white'>Escribe el correo electrónico del cual quieres recuperar la contraseña.</Text>
                                    <Formik
                                        initialValues={{
                                            email: ''
                                        }}
                                        validateOnChange={false}
                                        validateOnBlur={false}
                                        validationSchema={Yup.object({
                                            email: Yup.string().email().required('El campo email es obligatorio'),
                                        })}
                                        onSubmit={async (values, { setErrors }) => {
                                            const { errors } = await sendResetPasswordMail(values.email);

                                            if (errors) {
                                                setErrors(errors);
                                            } else {
                                                setSended(true);
                                            }
                                        }}
                                    >
                                        {({ getFieldProps, errors, touched, isSubmitting }) => (
                                            <Form style={{ width: '100%' }}>
                                                <FormControl mb={2} isInvalid={(errors.email && touched.email)}>
                                                    <FormLabel color='white'>Correo electrónico</FormLabel>
                                                    <InputGroup>
                                                        <InputLeftElement>
                                                            <i className='bi bi-envelope-fill' fontSize='20px'></i>
                                                        </InputLeftElement>
                                                        <Input type="email" bgColor='white' w='100%' {...getFieldProps('email')} />
                                                    </InputGroup>
                                                    <FormErrorMessage className='form-error-message'>
                                                        {errors.email}
                                                    </FormErrorMessage>
                                                </FormControl>
                                                <Flex flexDir={['column', 'column', 'row', 'row']} w='100%' mt={3}>
                                                    <Button type='submit' variant='primary' w={['100%', '100%', 'auto', 'auto']} mr={[0, 0, 2, 2]}>
                                                        {(isSubmitting) && <Spinner size='md' color='white' mr={2} />}
                                                        Recuperar
                                                    </Button>
                                                    <Link to='/ingresar'>
                                                        <Button variant='tertiary' w={['100%', '100%', 'auto', 'auto']} mt={[2, 2, 0, 0]}>Cancelar</Button>
                                                    </Link>
                                                </Flex>
                                            </Form>
                                        )}
                                    </Formik>
                                </>
                                :
                                <>
                                    <Text fontSize='xl' fontWeight='semibold' color='white'>Se te ha enviado un correo de recuperación, para que puedas actualizar tu contraseña.</Text>
                                </>
                        }
                    </VStack>
                </VStack>
            </Box>
        </Box>
    )
}

export default RecoveryPage