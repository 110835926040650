import { Box, Button, Checkbox, Divider, HStack, Heading, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const pending = [
    {
        id: 1,
        num_guia: 23,
        origin: 'Lol',
        cant: 23,
        vol: 23,
        peso: 23,
    },
    {
        id: 2,
        num_guia: 23,
        origin: 'Lol',
        cant: 23,
        vol: 23,
        peso: 23,
    },
]

export const PPendingListPage = () => {

    const navigate = useNavigate();

  return (
    <>
        <Heading variant='title'>Mis Recolecciones Pendientes</Heading>
        <Divider my={2}/>
        <HStack>
            <Button variant='primary' onClick={ () => navigate('/recoleccion') }>Generar Recoleccion</Button>
        </HStack>
        <Box mt={5}>
            <Table>
                <Thead>
                    <Tr>
                        <Th>Selección</Th>
                        <Th>Num Guia</Th>
                        <Th>Origen</Th>
                        <Th>Cantidad</Th>
                        <Th>Volumen</Th>
                        <Th>Peso</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {
                        pending.map((pending) => (
                            <Tr key={pending.id}>
                                <Td><Checkbox /></Td>
                                <Td>{pending.num_guia}</Td>
                                <Td>{pending.origin}</Td>
                                <Td>{pending.cant}</Td>
                                <Td>{pending.vol}</Td>
                                <Td>{pending.peso}</Td>
                            </Tr>
                        ))
                    }
                </Tbody>
            </Table>
        </Box>
    </>
  )
}
