import { Box, Button, Stack } from '@chakra-ui/react'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteDocument } from '../../../redux/slices/creditRequestSlice';

const ShowOrDeleteFile = ({
    title,
    filePath,
    typeFile,
    onlyShow = false
}) => {

    const dispatch = useDispatch();
    const { credit_id } = useSelector((state) => state.credit_request);

    const handleDeleteDocument = () => {
        dispatch(deleteDocument(credit_id, typeFile));
    }

    return (
        <Stack direction={['column', 'column', 'column', 'row']} justifyContent='space-between' alignItems='center'>
            <span style={{ fontWeight: 'bold', marginRight: '5px', width: '100%' }}>{title}</span>
            <Stack direction={['column', 'column', 'column', 'row']} justifyContent='space-between' width='full'>
                <a href={`${process.env.REACT_APP_FILE_URL}/${filePath}`} target='_blank' style={{flex: 1}}>
                    <Button bgColor='transparent' borderColor='primary.900' color='primary.900' fontSize='small' borderWidth={3} w='100%' borderRadius={3} padding={2} _hover={{
                        bgColor: 'blue.100',
                    }} >
                        <i className='bi bi-eye'></i> Ver documento
                    </Button>
                </a>
                {
                    !onlyShow &&
                        <Button flex={1} bgColor='transparent' borderColor='secondary.900' color='secondary.900' fontSize='small' borderWidth={3} borderRadius={3} padding={2} _hover={{
                            bgColor: 'secondary.100'
                        }} onClick={handleDeleteDocument}>
                            <i className='bi bi-trash'></i> Eliminar documento
                        </Button>
                }
            </Stack>
        </Stack>
    )
}

export default ShowOrDeleteFile