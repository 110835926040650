import React from 'react'
import { useSelector } from 'react-redux'

const useInvoice = () => {

    const base_url = `${process.env.REACT_APP_API_URL}/api/v1/invoice`;
    const { token } = useSelector((state) => state.user.data);
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }

    const getInvoiceFields = async () => {
        let [
            tax_regimes,
            payment_methods,
            payment_ways,
            uso_cfdi
        ] = await Promise.all([
            fetch(`${base_url}/tax_regimes`, {
                headers: {
                    ...headers,
                    'Authorization': `Bearer ${token}`
                }
            }),
            fetch(`${base_url}/payment_methods`, {
                headers: {
                    ...headers,
                    'Authorization': `Bearer ${token}`
                }
            }),
            fetch(`${base_url}/payment_ways`, {
                headers: {
                    ...headers,
                    'Authorization': `Bearer ${token}`
                }
            }),
            fetch(`${base_url}/uso_cfdi`, {
                headers: {
                    ...headers,
                    'Authorization': `Bearer ${token}`
                }
            }),
        ]);

        tax_regimes = await tax_regimes.json();
        payment_ways = await payment_ways.json();
        payment_methods = await payment_methods.json();
        uso_cfdi = await uso_cfdi.json();

        return {
            tax_regimes: tax_regimes.data,
            payment_ways: payment_ways.data,
            payment_methods: payment_methods.data,
            uso_cfdi: uso_cfdi.data
        };
    }

    const getInvoices = async () => {
        const res = await fetch(`${base_url}`, {
            method: 'GET',
            headers: {
                ...headers,
                'Authorization': `Bearer ${token}`
            }
        });

        if(res.ok) {
            const data = await res.json();
            return data;
        } else {
            return {
                errors: {
                    global: 'Error en servidor.'
                }
            }
        }
    }

    return {
        getInvoiceFields,
        getInvoices
    }
}

export default useInvoice