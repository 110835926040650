import { Box, Button, Flex, FormControl, FormErrorMessage, FormLabel, Heading, Input, InputGroup, InputLeftElement, InputRightElement, Spinner, VStack } from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import React from 'react'
import { Link } from 'react-router-dom'
import { useUser } from '../../../hooks/useUser'
import { useSelector } from 'react-redux'
import * as Yup from 'yup';
import { useState } from 'react'
import Swal from 'sweetalert2'
import { colors } from '../../../themes/colors'

const NewPasswordForm = () => {

    const { updatePassword } = useUser();
    const { token } = useSelector((state) => state.user.data);

    const [viewPassword, setViewPassword] = useState(false);
    const [viewNewPassword, setViewNewPassword] = useState(false);
    const [viewNewPasswordConfirmation, setViewNewPasswordConfirmation] = useState(false);

    return (
        <VStack w='full' alignItems='start'>
            <Heading size='md'>Contraseña</Heading>
            <VStack w='full'>
                <Formik
                    initialValues={{
                        password: '',
                        new_password: '',
                        new_password_confirmation: ''
                    }}
                    validateOnChange={false}
                    validateOnBlur={false}
                    validationSchema={Yup.object({
                        password: Yup.string().required('Este campo es necesario.'),
                        new_password: Yup.string().required('Este campo es necesario.'),
                        new_password_confirmation: Yup.string().required('Este campo es necesario.')
                    })}
                    onSubmit={async (values, { setErrors, resetForm }) => {
                        const { errors } = await updatePassword(token, values);

                        if (errors) {
                            setErrors(errors);
                        } else {
                            Swal.fire({
                                title: 'Actualizado',
                                text: 'Se ha actualizado la contraseña de tu perfil correctamente.',
                                icon: 'success',
                                showCloseButton: false,
                                confirmButtonColor: colors['primary']['900'],
                                confirmButtonText: 'Continuar'
                            })
                            resetForm();
                        }
                    }}
                    style={{ width: '100%' }}
                >
                    {({ getFieldProps, isSubmitting, errors, touched }) => (
                        <Form
                            style={{ width: '100%' }}
                        >
                            <FormControl isInvalid={(errors.password && touched.password)}>
                                <FormLabel>Contraseña actual</FormLabel>
                                <InputGroup>
                                    <InputLeftElement>
                                        <i className='bi bi-lock-fill' fontSize='20px'></i>
                                    </InputLeftElement>
                                    <Input type={(viewPassword) ? 'text' : 'password'} bgColor='white' w='100%' {...getFieldProps('password')} />
                                    <InputRightElement cursor='pointer'  onClick={() => setViewPassword(!viewPassword)}>
                                        <i className={`bi bi-eye${(!viewPassword) ? '-slash' : ''}-fill`} fontSize='20px'></i>
                                    </InputRightElement>
                                </InputGroup>
                                <FormErrorMessage className='form-error-message'>
                                    {errors.password}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={(errors.new_password && touched.new_password)}>
                                <FormLabel>Nueva contraseña</FormLabel>
                                <InputGroup>
                                    <InputLeftElement>
                                        <i className='bi bi-lock-fill' fontSize='20px'></i>
                                    </InputLeftElement>
                                    <Input type={(viewNewPassword) ? 'text' : 'password'} bgColor='white' w='100%' {...getFieldProps('new_password')} />
                                    <InputRightElement cursor='pointer'  onClick={() => setViewNewPassword(!viewNewPassword)}>
                                        <i className={`bi bi-eye${(!viewNewPassword) ? '-slash' : ''}-fill`} fontSize='20px'></i>
                                    </InputRightElement>
                                </InputGroup>
                                <FormErrorMessage className='form-error-message'>
                                    {errors.new_password}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={(errors.new_password_confirmation && touched.new_password_confirmation)}>
                                <FormLabel>Confirmar contraseña</FormLabel>
                                <InputGroup>
                                    <InputLeftElement>
                                        <i className='bi bi-lock-fill' fontSize='20px'></i>
                                    </InputLeftElement>
                                    <Input type={(viewNewPasswordConfirmation) ? 'text' : 'password'} bgColor='white' w='100%' {...getFieldProps('new_password_confirmation')} />
                                    <InputRightElement cursor='pointer' onClick={() => setViewNewPasswordConfirmation(!viewNewPasswordConfirmation)}>
                                        <i className={`bi bi-eye${(!viewNewPasswordConfirmation) ? '-slash' : ''}-fill`} fontSize='20px'></i>
                                    </InputRightElement>
                                </InputGroup>
                                <FormErrorMessage className='form-error-message'>
                                    {errors.new_password_confirmation}
                                </FormErrorMessage>
                            </FormControl>
                            <Flex flexDir={['column-reverse', 'column-reverse', 'row', 'row']} justifyContent='space-between' w='100%' mt={3}>
                                <Link to='/'>
                                    <Button variant='secondary' w={['100%', '100%', 'auto', 'auto']} mt={[2, 2, 0, 0]}>
                                        Cancelar
                                    </Button>
                                </Link>
                                <Button type='submit' variant='primary' w={['100%', '100%', 'auto', 'auto']}
                                    isDisabled={isSubmitting}
                                >
                                    {(isSubmitting) && <Spinner size='md' color='white' mr={2} />}
                                    Cambiar contraseña
                                </Button>
                            </Flex>
                        </Form>
                    )}
                </Formik>
            </VStack>
        </VStack>
    )
}

export default NewPasswordForm