import { Box, Button, Link, Stack } from '@chakra-ui/react'
import React from 'react'

const Paginator = ({
    paginator,
    setCurrentUrl
}) => {
    return (
        <Box mt={3}>
            <Stack spacing={2} flexDir='row' className='paginator' justifyContent='end'>
                {
                    (paginator.total > paginator.per_page) && paginator.links.map((link) => (
                        link.url !== null &&
                        <Link onClick={() => setCurrentUrl(link.url)}>
                            <Button colorScheme={(link.active) ? 'red' : 'gray'}>
                                {
                                    (link.label.includes('Next'))
                                        ? <i className='bi bi-chevron-right'></i>
                                        : (link.label.includes('Prev'))
                                            ? <i className='bi bi-chevron-left'></i>
                                            : link.label
                                }
                            </Button>
                        </Link>
                    ))
                }
            </Stack>
        </Box>
    )
}

export default Paginator