import React, { useEffect, useState } from 'react';
import { useUser } from '../../hooks/useUser';
import { Badge, Box, Button, HStack, Heading, Menu, MenuButton, MenuItem, MenuList, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Stack, Table, Tbody, Td, Text, Th, Thead, Tr, VStack, useDisclosure } from '@chakra-ui/react';
import Paginator from '../partials/Paginator';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { colors } from '../../themes/colors';

const UserRequestList = ({
    token
}) => {

    const { getUsersRequests, changeStatusToUserRequest } = useUser();
    const { isOpen, onClose, onOpen } = useDisclosure();
    const [currentUrl, setCurrentUrl] = useState(null);
    const [paginator, setPaginator] = useState(null);
    const [requests, setRequests] = useState();
    const [request, setRequest] = useState();
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState({});

    const badgeScheme = {
        '1': 'gray',
        '2': 'red',
        '3': 'green',
    };

    const handleChangeStatus = async (status_id) => {
        const {success, errors: req_errors} = await changeStatusToUserRequest(request.id, status_id, token);

        if(success) {
            onClose();

            if(status_id === 2) {
                Swal.fire({
                    title: 'Rechazado',
                    text: 'Se ha rechazado la solicitud de alta.',
                    icon: 'error',
                    showCloseButton: false,
                    confirmButtonColor: colors['secondary']['900'],
                    confirmButtonText: 'Continuar'
                })
            } else if(status_id === 3) {
                Swal.fire({
                    title: 'Creado',
                    text: 'Se ha aceptado la solicitud de alta.',
                    icon: 'success',
                    showCloseButton: false,
                    confirmButtonColor: colors['secondary']['900'],
                    confirmButtonText: 'Continuar'
                })
            }

            handleGetUsers();
        } else {
            setErrors(req_errors);
            console.log(req_errors);
        }
    }
    
    const handleOpenModal = (req) => {
        setRequest(req);
        onOpen();
    }

    const handleGetUsers = () => {
        getUsersRequests(currentUrl, token)
            .then(({ success, data, errors }) => {
                if (success) {
                    setLoading(false);
                    setRequests(data.data);
                    setPaginator(data);
                } else {
                    alert(errors);
                }
            })
    }

    useEffect(() => {
        handleGetUsers();
    }, [currentUrl]);

    return (
        <>
            <Box mt={5}>
                <Table>
                    <Thead>
                        <Tr>
                            <Th>Nombre</Th>
                            <Th>Email</Th>
                            <Th>Razón social</Th>
                            <Th>Estatus</Th>
                            <Th>Acciones</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {
                            !loading && requests.map((req) => (
                                <Tr key={req.id}>
                                    <Td>{req.name} {req.last_name}</Td>
                                    <Td>{req.email}</Td>
                                    <Td>
                                        {req.company_name}
                                    </Td>
                                    <Td>
                                        <Badge rounded='15px' py={1} px={3} colorScheme={badgeScheme[req.status_id]}>{req.status.status}</Badge>
                                    </Td>
                                    <Td>
                                        <Menu>
                                            <MenuButton as={Button}>
                                                <i className='bi bi-three-dots-vertical'></i>
                                            </MenuButton>
                                            <MenuList>
                                                <MenuItem onClick={() => handleOpenModal(req)} icon={<i className='bi bi-eye'></i>}>Revisar solicitud</MenuItem>
                                            </MenuList>
                                        </Menu>
                                    </Td>
                                </Tr>
                            ))
                        }
                    </Tbody>
                </Table>
                {
                    !loading && <Paginator setCurrentUrl={setCurrentUrl} paginator={paginator} />
                }
            </Box>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
            >
                <ModalContent>
                    <ModalHeader>
                        <Heading variant='title' size='md'>Solicitud alta de usuario</Heading>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <HStack>
                            <i className='bi bi-briefcase' style={{ fontSize: '35px' }}></i>
                            <VStack spacing={0} alignItems='start'>
                                <Text fontWeight='bold'>{request?.company_name}</Text>
                                <Text colorScheme='gray' fontSize='xs'>Rázon social</Text>
                            </VStack>
                        </HStack>
                        <HStack>
                            <i className='bi bi-person-vcard' style={{ fontSize: '35px' }}></i>
                            <VStack spacing={0} alignItems='start'>
                                <Text fontWeight='bold'>{request?.vatnum}</Text>
                                <Text colorScheme='gray' fontSize='xs'>RFC</Text>
                            </VStack>
                        </HStack>
                        <HStack>
                            <i className='bi bi-person' style={{ fontSize: '35px' }}></i>
                            <VStack spacing={0} alignItems='start'>
                                <Text fontWeight='bold'>{request?.name} {request?.last_name}</Text>
                                <Text colorScheme='gray' fontSize='xs'>Nombre</Text>
                            </VStack>
                        </HStack>
                        <HStack>
                            <i className='bi bi-envelope' style={{ fontSize: '35px' }}></i>
                            <VStack spacing={0} alignItems='start'>
                                <Text fontWeight='bold'>{request?.email}</Text>
                                <Text colorScheme='gray' fontSize='xs'>Correo electrónico</Text>
                            </VStack>
                        </HStack>
                        <HStack>
                            <i className='bi bi-telephone' style={{ fontSize: '35px' }}></i>
                            <VStack spacing={0} alignItems='start'>
                                <Text fontWeight='bold'>{request?.telephone}</Text>
                                <Text colorScheme='gray' fontSize='xs'>Teléfono</Text>
                            </VStack>
                        </HStack>
                        <Stack justifyContent='space-between' mt={3} flexDir={['column-reverse', 'column-reverse', 'row', 'row']}>
                            <Button variant='secondary' onClick={() => handleChangeStatus(2)}>Rechazar</Button>
                            <Button variant='primary' onClick={() => handleChangeStatus(3)}>Aceptar</Button>
                        </Stack>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export default UserRequestList