import { Image } from '@chakra-ui/image';
import { Box } from '@chakra-ui/layout';
import { Spinner } from '@chakra-ui/spinner';
import React, { useState } from 'react'

export const PreviewImage = ({ file, image }) => {

    const [ preview, setPreview ] = useState(null);

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
        setPreview(reader.result);
    }

  return (
    <>
        <Box>
            { preview ?
            <Image 
              className='animate__animated animate__bounceIn' 
              src={(file) ? preview : image} 
              alt='preview'
              rounded={'5px'}
              style={{
                width: '100%',
                height: 'auto'
              }}
            /> 
            : <Spinner size='xl' />}
        </Box>
    </>
  )
}
