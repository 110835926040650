import { HStack, Skeleton, Stack, Text, VStack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useCards } from '../../hooks/useCards';
import CreditCard from './CreditCard';

export const SavedCardBox = ({ onOpen, setCard, setCards, cards, isLoading }) => {

    const { deleteCard, getCard } = useCards();

    const handleEditCard = async (id) => {
        
        try {
            let res = await getCard(id);
            if (!res) {
              alert("Error getting the information of this address");
              return;
            }
            onOpen();
            setCard(id);
        } catch (err) {
            console.error(err);
          }
    }

    const handleDeleteCard = async (id) => {
        const { success, message } = await deleteCard(id);
        if (success) {
            setCards(() => cards.filter((card) => id !== card.id));
        } else {
            alert(message);
        }
    }

    return (
        <>
            {
                !isLoading
                    ?
                    <>
                        {
                            (cards) && cards.map((cd) => (
                                <HStack mt={5} key={cd.id}>
                                    <VStack w={'100%'}>
                                        <HStack w='full' alignItems='end' justifyContent='space-between'>
                                            <Text fontWeight='bold'>{cd.holder_name}</Text>
                                            <VStack alignItems='end' spacing={0}>
                                                <small style={{ color: 'gray' }}>Fecha de registro</small>
                                                <Text>10/09/2023</Text>
                                            </VStack>
                                        </HStack>
                                        <CreditCard card={cd} handleDeleteCard={handleDeleteCard} handleEditCard={handleEditCard} />
                                    </VStack>
                                </HStack>
                            ))
                        }
                    </>
                    :
                    <Stack mt={10}>
                        <Skeleton height='30px' />
                        <Skeleton mt={3} height='30px' />
                        <Skeleton mt={3} height='30px' />
                        <Skeleton mt={3} height='30px' />
                        <Skeleton mt={3} height='30px' />
                        <Skeleton mt={3} height='30px' />
                        <Skeleton mt={3} height='30px' />
                    </Stack>
            }
        </>
    )
}
