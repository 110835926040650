import { Box, Button, Divider, FormLabel, Grid, GridItem, Heading, Icon, IconButton, Image, VStack } from '@chakra-ui/react'
import React from 'react'
import zuck from '../../assets/images/zuck.webp';
import NewPasswordForm from '../../components/user/profile/NewPasswordForm';
import UpdateInfoForm from '../../components/user/profile/UpdateInfoForm';
import { useDispatch, useSelector } from 'react-redux';
import { useUser } from '../../hooks/useUser';
import { setProfilePic } from '../../redux/slices/userSlice';

const ProfilePage = () => {

    const dispatch = useDispatch();
    const { token, profile_pic } = useSelector((state) => state.user.data);
    const { getUserData, updateProfilePic, deleteProfilePic } = useUser();

    const handleUpdateImage = (e) => {
        const body = new FormData();
        body.append('profile_pic', e.target.files[0]);

        updateProfilePic(token, body)
            .then(async ({ success, message, errors }) => {
                if(success) {
                    const { profile_pic: new_pp } = await getUserData(token);
                    dispatch(setProfilePic(new_pp));
                } else {
                    alert("Hubo un error");
                }
            })
    }

    const handleDeleteImage = () => {
        deleteProfilePic(token)
            .then(async ({success, message, errors}) => {
                if(success) {
                    const { profile_pic: new_pp } = await getUserData(token);
                    dispatch(setProfilePic(new_pp));
                } else  {
                    alert("Hubo un error");
                }
            });
    }

    return (
        <>
            <Heading variant='title'>Mi perfil</Heading>
            <Divider my={2} />
            <Box flex={1}>
                <VStack alignItems='start'>
                    <Grid w='full' templateColumns={['repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(12, 1fr)']} gap={5}>
                        <GridItem colSpan={[1, 1, 1, 4]} display='flex' justifyContent='center'>
                            <Box position='relative' maxH='300px' maxW='300px'>
                                <input onChange={handleUpdateImage} id='profile_pic' type='file' name='profile_pic' style={{ display: 'none' }} />
                                <Button colorScheme='blue' color='white' right={0}  bottom={0} borderRadius='full' position='absolute' p={0}>
                                    <label htmlFor='profile_pic' style={{cursor: 'pointer'}}>
                                        <i className='bi bi-pencil'></i>
                                    </label>
                                </Button>
                                <IconButton onClick={handleDeleteImage} colorScheme='red' color='white' left={0}  bottom={0} borderRadius='full' position='absolute' icon={<i className='bi bi-trash'></i>} />

                                <Box borderColor='gray.200' borderWidth={2} w='280px' h='280px' borderRadius='full' overflow='hidden'>
                                    <Image src={profile_pic} width='100%' />
                                </Box>
                            </Box>
                        </GridItem>
                        <GridItem colSpan={[1, 1, 1, 8]}>
                            <UpdateInfoForm />
                            <Divider my={3} />
                            <NewPasswordForm />
                        </GridItem>
                    </Grid>
                </VStack>
            </Box>
        </>
    )
}

export default ProfilePage