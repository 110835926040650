import { Box, Button, FormControl, FormLabel, HStack, Input, Skeleton, Stack, VStack } from '@chakra-ui/react'
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react'
import { formatCVC, formatCreditCardNumber, formatExpirationDate } from '../../helpers/general_functions';
import Cards from 'react-credit-cards-2';
import { useCards } from '../../hooks/useCards';

const CardForm = ({
  onSubmit,
  cancelFunction,
  primaryButtonTitle,
  idCard
}) => {

  const { getCard } = useCards();
  const [ cardsFields, setCardsFields ] = useState(null);
  const [focus, setFocus] = useState(null);

  const handleFocus = (event) => {
    setFocus(event.target.name);
  }

  const handleInputChange = (event, handleChange) => {
    if (event.target.name === 'card_number') {
      event.target.value = formatCreditCardNumber(event.target.value)
    } else if (event.target.name === 'expiry') {
      event.target.value = formatExpirationDate(event.target.value)
    } else if (event.target.name === 'cvv2') {
      event.target.value = formatCVC(event.target.value)
    }

    handleChange(event);
  }

  useEffect(() => {
    (async () => {
      if (idCard) {
        const result = await getCard(idCard);
        let expiry = result.expiration_month.concat('/', result.expiration_year);
        setCardsFields({
          bank_name: result.bank_name,
          brand: result.brand,
          expiry: expiry,
          holder_name: result.holder_name,
          id: result.id,
          last_4_digits: result.last_4_digits,
          type: result.type,
        });
      }
    })();
  }, [])

  return (
    (!cardsFields && idCard)
      ?
      <Stack my='25px'>
        <Skeleton height='25px' />
        <Skeleton height='25px' />
        <Skeleton height='25px' />
        <Skeleton height='25px' />
        <Skeleton height='25px' />
        <Skeleton height='25px' />
      </Stack>
      :
    <Formik
      initialValues={{
        holder_name: cardsFields?.holder_name || '',
        card_number: cardsFields?.card_number || '',
        expiry: cardsFields?.expiry || '',
        cvv2: cardsFields?.cvv2 || ''
      }}
      onSubmit={onSubmit}
    >
      {({ getFieldProps, isSubmitting, values, handleChange, errors }) => (
        <Box w='100%' display='flex' flexDir='column' alignItems='center'>
          <Cards
            number={values.card_number}
            expiry={values.expiry}
            cvc={values.cvv2}
            name={values.holder_name}
            placeholders={{
              holder_name: 'Nombre de la tarjeta',
            }}
            locale={{
              valid: 'Expiración'
            }}
            acceptedCards={['visa', 'mastercard', 'amex']}
            focused={focus}
            preview={false}
            style={{ width: '100% !important' }}
          />
          {
            errors.globals && <Box w='100%' className='form-error-message' mt={3}>{errors.globals}</Box>
          }
          <Form 
            id='cardForm'
            style={{ width: '100%', marginTop: 10 }}
          >
            <VStack w='100%' justifyContent='start'>
              <FormControl isRequired={(idCard) ? false : true}>
                <FormLabel color={(idCard) ? 'grey' : 'black'}>Tarjeta</FormLabel>
                <Input 
                  {...getFieldProps('card_number')} 
                  onChange={(e) => handleInputChange(e, handleChange)} 
                  onFocus={handleFocus} 
                  isDisabled={(idCard) ? true : false}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Nombre en la tarjeta</FormLabel>
                <Input 
                  pattern='[a-z A-Z-]+' 
                  {...getFieldProps('holder_name')} 
                  onChange={(e) => handleInputChange(e, handleChange)} 
                  onFocus={handleFocus} 
                />
              </FormControl>
              <HStack w='100%'>
                <FormControl isRequired>
                  <FormLabel>Fecha de expiración</FormLabel>
                  <Input 
                    type='tel' 
                    pattern='\d\d/\d\d'  
                    {...getFieldProps('expiry')} 
                    onChange={(e) => handleInputChange(e, handleChange)} 
                    onFocus={handleFocus} 
                    placeholder='Mes / Año'
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>CVC</FormLabel>
                  <Input 
                    type='password'  
                    {...getFieldProps('cvv2')} 
                    onChange={(e) => handleInputChange(e, handleChange)} 
                  />
                </FormControl>
              </HStack>
              <Stack w='100%' justifyContent='space-between' flexDir={['column-reverse', 'column-reverse', 'row', 'row']}>
                <Button mt={5} mb={5} onClick={cancelFunction} variant='secondary'>Cancelar</Button>
                <Button mt={5} mb={5} type='submit' variant='primary' isLoading={isSubmitting} loadingText="Guardando tarjeta" disabled={isSubmitting}>{primaryButtonTitle}</Button>
              </Stack>
            </VStack>
          </Form>
        </Box>
      )}
    </Formik>
  )
}

export default CardForm