export const Roles = {
    SuperAdmin: 1,
    Cobranza: 2,
    Comercial: 3,
    Altas: 4,
    Cliente: 5
}

export const SecondaryRoles = {
    Administrador: 1,
    Facturacion: 2,
    Creador: 3,
    Altas: 4,
    Personalizado: 5
}