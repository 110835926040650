import React from 'react';
import trailer from '../../../assets/images/commons/trailer.png';
import { Box, Button, Heading, Image, Link, Stack, Text, VStack } from '@chakra-ui/react';

const CRThankYou = () => {
    return (
        <div>
            <VStack w='100%'>
                <Heading size='lg' color='primary.900'>¡Tu solicitud de crédito ha sido enviada!</Heading>
                <Heading size='md'>Nuestra área de creditos validará la infromación proporcionada.</Heading>
                <Box w={['70%', '70%', '20%', '20%']} position='relative'>
                    <svg viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
                        <g stroke="currentColor" stroke-width="2" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                            <path class="circle" d="M13 1C6.372583 1 1 6.372583 1 13s5.372583 12 12 12 12-5.372583 12-12S19.627417 1 13 1z" />
                            <path class="tick" d="M6.5 13.5L10 17 l8.808621-8.308621" />
                        </g>
                    </svg>
                    <Box className='animate__animated animate__lightSpeedInRight' w={['100%', '100%', '100%', '150%']} position='absolute' textAlign='center' bottom='-25px' left={['0', '0', '-25%', '-40%']}>
                        <Image src={trailer} width='100%' />
                    </Box>
                </Box>
                <Stack w='100%' flexDir={['column', 'column', 'row', 'row']} justifyContent='space-between' alignItems='center' mt={5}>
                    <Text color='gray.500'>Puedes <Link fontWeight='bold' textDecoration='underline'>activar</Link> las notificaciones del estatus de tu solicitud de crédito para su seguimiento.</Text>
                    <Link href='/solicitudes-de-credito'>
                        <Button variant='primary'>
                            Continuar
                        </Button>
                    </Link>
                </Stack>
            </VStack>
        </div>
    )
}

export default CRThankYou