import React from 'react'
import NavLinked, { NavLinkDropdown } from './NavLinked'

const LinkList = ({
    mobile = false,
    open = true,
}) => {
    return (
        <ul className={`nav-list ${mobile && 'mobile'}`}>
            <li>
                <NavLinked title="Mis ordenes" href="/mis-ordenes" icon="bi bi-box" hide={!open} />
            </li>
            <li>
                <NavLinkDropdown
                    title="Mis recolecciones"
                    icon="bi bi-truck"
                    hide={!open}
                    menuIcon='menu-icon'
                    py='2'
                    px='10px'
                    dropdownItems={[
                        { href: '/recolecciones', title: 'Historial', icon: 'bi bi-table', menuIcon: 'menu-icon' },
                        { href: '/recolecciones-pendientes', title: 'Pendientes', icon: 'bi bi-box2', menuIcon: 'menu-icon' },
                    ]}
                />

            </li>
            <li>
                <NavLinked title="Usuarios" href="/usuarios" icon="bi bi-person" hide={!open} />
            </li>
            <li>
                <NavLinked title="Solicitudes de crédito" href="/solicitudes-de-credito" icon="bi bi-journal-check" hide={!open} />
            </li>
            <li>
                <NavLinked title="Rastreo" href="/test" icon="bi bi-pin-map" hide={!open} />
            </li>
            <li>
                <NavLinked title="Cobertura" href="/cobertura" icon="bi bi-globe-americas" hide={!open} />
            </li>
            <li>
                <NavLinked title="Mis tarjetas" href="/mis-tarjetas" icon="bi bi-credit-card" hide={!open} />
            </li>
            <li>
                <NavLinkDropdown
                    title="Mis facturas"
                    icon="bi bi-receipt-cutoff"
                    hide={!open}
                    menuIcon='menu-icon'
                    py='2'
                    px='10px'
                    dropdownItems={[
                        { href: '/facturas', title: 'Historial', icon: 'bi bi-folder2-open', menuIcon: 'menu-icon' },
                        { href: '/facturas-pendientes', title: 'Pendientes', icon: 'bi bi-receipt', menuIcon: 'menu-icon' },
                    ]}
                />
            </li>
            <li>
                <NavLinked title="Creador de Anuncios" href="/noticias" icon="bi bi-newspaper" hide={!open} />
            </li>
            <li>
                <NavLinked title="Cotizador" href="/cotizador" icon="bi bi-currency-dollar" hide={!open} />
            </li>
        </ul>
    )
}

export default LinkList