import { extendTheme } from "@chakra-ui/react";
import { colors } from "./colors";

export const theme = extendTheme({
    colors: colors,
    components: {
        Button: {
            variants: {
                primary: {
                    bgColor: colors.secondary['900'],
                    color: 'white',
                    borderRadius: '40px',
                    _hover: {
                        bgColor: colors.secondary.hover
                    }
                },
                secondary: {
                    bgColor: 'transparent',
                    borderWidth: '2px',
                    borderRadius: '40px',
                    borderColor: colors.secondary['900'],
                    color: colors.secondary['900'],
                    _hover: {
                        borderColor: colors.secondary.hover,
                        color: colors.secondary.hover,
                    }
                },
                tertiary: {
                    bgColor: 'transparent',
                    borderWidth: '2px',
                    borderRadius: '40px',
                    borderColor: 'white',
                    color: 'white',
                    _hover: {
                        borderColor: 'gray',
                        color: 'gray',
                    }
                }
            }
        },
        Heading: {
            variants: {
                title: {
                    color: colors.primary['900']
                }
            }
        },
        Checkbox: {
            baseStyle: {
                icon: {
                    color: 'white'
                },
            }
        }
    }
});