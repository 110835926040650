import { createSlice } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";

const initialState = {
    subtotal: 0,
    completed: false,
    items: []
};

export const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        addToCart: (state, action) => ({
            items: [...state.items, action.payload.item],
            subtotal: state.subtotal + action.payload.item.total
        }),
        addMultipleToCart: (_, action) => action.payload,
        removeFromCart: (state, action) => ({
            items: state.items.filter((item) => item.item_id !== action.payload.item_id),
            subtotal: state.subtotal - action.payload.item.total
        }),
        completeCart: (state, _) => ({
            ...state,
            completed: true
        }),
        cleanCart: () => (initialState)
    },
    extraReducers: (builder) => {
        builder.addCase(PURGE, () => {
            return initialState;
        });
    }
});

export const { addToCart, removeFromCart, cleanCart, addMultipleToCart, completeCart } = cartSlice.actions;
export const cartReducer = cartSlice.reducer;