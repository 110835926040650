import { Box, Button, Center, Divider, Heading, Img, Text, VStack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useNews } from '../../hooks/useNews'
import { useNavigate, useParams } from 'react-router-dom';

export const ShowNews = () => {

    const { getNews } = useNews();
    const [_new, setNew] = useState();
    const { news_id } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        if (news_id) {
            (async () => {
                const result = await getNews(news_id);
                setNew(result);
                setIsLoading(false);
            })();
        } else {
            navigate('/noticias');
        }
    }, []);

    return !isLoading && (
        <>
            {
            (_new === undefined)
            ?
                <Center>
                    <Box>
                        <VStack maxH='max-content'>
                            <Heading variant='title' fontSize='9xl' display='flex' >Oops!!!</Heading>
                            <Text fontSize='7xl' display='flex' >No se encontró el contenido</Text>
                            <Button mt='30px' variant='primary' onClick={() => navigate('/notificaciones')} >Regresar</Button>
                        </VStack>
                    </Box>
                </Center>
            :
            <>
                <Heading variant='title'>{_new.title}</Heading>
                <Divider my={2} />
                <VStack m='30px' alignItems='flex-start'>
                    <Box w='100%' my='20px' display='flex' justifyContent='center'>
                        <Img src={_new.image_url} boxSize='70%' rounded='5px' />
                    </Box>
                    <Box>
                        <Text align='justify' fontSize='22px'>
                            <div id='newsHTML' dangerouslySetInnerHTML={{ __html: _new.details }} />
                        </Text>
                    </Box>
                </VStack>
            </>
            }
        </>
    )
}
