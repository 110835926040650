import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Box, Heading } from '@chakra-ui/react'
import React, { useState } from 'react'
import { OriginData } from './OriginData'
import { DestinationData } from './DestinationData'

export const OrdersForm = () => {

    const [ accordionIndex, setAccordionIndex ] = useState(0);

  return (
    <>
        <Accordion index={accordionIndex}>
            <AccordionItem>
                <AccordionButton>
                    <Box as="span" flex='1' textAlign='left'>
                        <Heading variant='title' fontSize='20px'>Datos de origen</Heading>
                    </Box>
                </AccordionButton>
                <AccordionPanel pb={4}>
                    <OriginData setAccordionIndex={setAccordionIndex} currentStep={accordionIndex} />
                </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionButton>
                    <Box as="span" flex='1' textAlign='left'>
                        <Heading variant='title' fontSize='20px'>Datos de destino</Heading>
                    </Box>
                </AccordionButton>
                <AccordionPanel pb={4}>
                    <DestinationData setAccordionIndex={setAccordionIndex} currentStep={accordionIndex} />
                </AccordionPanel>
            </AccordionItem>
        </Accordion>
    </>
  )
}
