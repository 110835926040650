import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import LoginPage from '../pages/login/LoginPage'
import SignUpPage from '../pages/login/SignUpPage'
import RecoveryPage from '../pages/login/RecoveryPage'
import LoginLayout from '../pages/layouts/LoginLayout';
import ResetPasswordPage from '../pages/login/ResetPasswordPage'

const LoginRouter = () => {
  return (
    <LoginLayout>
      <Routes>
        <Route path="/" element={<Navigate to="/ingresar" />} />
        <Route path="/ingresar" element={<LoginPage />} />
        <Route path="/solicitud-de-alta" element={<SignUpPage />} />
        <Route path="/solicitud-de-recuperacion" element={<RecoveryPage />} />
        <Route path="/recuperar-cuenta" element={<ResetPasswordPage />} />

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </LoginLayout>
  )
}

export default LoginRouter