import { Box, Button, Divider, FormControl, FormErrorMessage, FormLabel, Grid, GridItem, Heading, Img, Input, Select, VStack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as Yup from 'yup'
import { useNews } from '../../hooks/useNews';
import { PreviewImage } from './PreviewImage';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import moment from 'moment';

export const CreateNewsPage = () => {

    const navigate = useNavigate();
    const { news_id } = useParams();
    const { createNews, updateNews, getNews, setImage, getNewsTypes } = useNews();
    const [ file, setFile ] = useState(null);
    const [ newsFields, setNewsFields ] = useState(null);
    const [ newDatetime, setNewDatetime ] = useState('');
    const [ newsTypes, setNewsTypes ] = useState([]);

    const handleChangeDate = (e, name, setFieldValue) => {
        const newDate = e.target.value;
        const formattedDatetime = formatDateTime(newDate);
        
        setFieldValue(name, newDate);
        setNewDatetime(formattedDatetime);
    };

    const formatDateTime = (value) => {
        if (!value) return '';

        const date = new Date(value);

        const year = date.getFullYear();
        const month = (`0${date.getMonth() + 1}`).slice(-2);
        const day = (`0${date.getDate()}`).slice(-2);
        const hours = (`0${date.getHours()}`).slice(-2);
        const minutes = (`0${date.getMinutes()}`).slice(-2);
        const seconds = (`0${date.getSeconds()}`).slice(-2);

        const newDate =  `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

        return newDate;
    };

    const handleCreateNews = async (values, { setErrors }) => {
        const body = new FormData();
        body.append('image', file);

        const details = values.details.replace("<p></p>\n", "");

        values.details = details;
        if(values.launch_date) {
            values.launch_date = newDatetime;
        } else {
            values.launch_date = null;
        }

        if (file) {
            const data1 = await createNews(values);
            const id = data1.data.new.id;
            const data2 = await setImage(body, id);

            if (data1.success && data2.success) {
                navigate('/noticias');
            } else {
                if (data1.errors || data2.errors) {
                    setErrors(data1.errors);
                    setErrors(data2.errors);
                } else {
                    setErrors({
                        global: 'Hubo un error en general.'
                    });
                }
            }
        } else {
            setErrors({
                global: 'No se cargó la imagen.'
            });
        }
    }; 

    const handleUpdateNews = async (values, { setErrors }) => {
        const body = new FormData();
        body.append('image', file);

        values.launch_date = newDatetime;

        if ( news_id) {
            const data1 = await updateNews(values, news_id);
            const id = data1.data.new.id;

            if ( file ){
                const data2 = await setImage(body, id);

                if (data1.success && data2.success) {
                    navigate('/noticias');
                } else {
                    if (data1.errors || data2.errors) {
                        setErrors(data1.errors);
                        setErrors(data2.errors);
                    } else {
                        setErrors({
                            global: 'Hubo un error en general.'
                        });
                    }
                }
            } else {
                if (data1.success) {
                    navigate('/noticias');
                } else {
                    if (data1.errors) {
                        setErrors(data1.errors);
                    } else {
                        setErrors({
                            global: 'Hubo un error en general.'
                        });
                    }
                }
            }
        } else {
            setErrors({
                global: 'No se cargó la imagen.'
            });
        }
    };
    
    useEffect(() => {
        (async () => {
            
            if (news_id) {
                const result = await getNews(news_id);
                setNewDatetime(result.launch_date);
                if(result.launch_date !== null) {
                    result.launch_date = result?.launch_date.split(" ")[0];
                }
                setNewsFields(result);
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            const result = await getNewsTypes();
            setNewsTypes(result);
        })();
    }, []);

  return (
    (!newsFields && news_id)
        ?
        <p>Cargando...</p>
        :
        <Formik
            initialValues={{
                title: newsFields?.title || '',
                notification_type: newsFields?.notification_type || '',
                launch_date: newsFields?.launch_date || '',
                details: newsFields?.details || '',
            }}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={Yup.object({
                title: Yup.string().required('Este campo es necesario'),
                details: Yup.string().required('Este campo es necesario'),
                notification_type: Yup.string().required('Este campo es necesario'),
                launch_date: Yup.string(),
            })}

            onSubmit={(news_id) ? handleUpdateNews : handleCreateNews}
            >
            {({ values, getFieldProps, errors, setFieldValue }) => (
            <Form>
                {
                    (errors.global) && <Box className='form-error-message' mb={2}>{errors.global}</Box>
                }
                <Heading variant='title' mb='15px'>
                    {
                        (news_id) ? 'Editar Anuncio' : 'Crear Anuncio'
                    }
                </Heading>
                <Divider />
                <Grid templateColumns='repeat(2, 1fr)' my='10px' gap={4}>
                    <GridItem colSpan={1}>

                        <FormControl FormControl isRequired isInvalid={(errors.notification_type)} mb={5}>
                            <FormLabel>Tipo anuncio</FormLabel>
                            <Select placeholder='Seleccione el tipo de anuncio' {...getFieldProps('notification_type')} value={values.notification_type} >
                                {
                                    newsTypes && newsTypes.map((news) => <option value={news.id} key={news.id}>{news.type}</option>) 
                                }                                
                            </Select>
                            <FormErrorMessage className='form-error-message'>
                                {errors.notification_type}
                            </FormErrorMessage>
                        </FormControl>

                        <FormControl isRequired FormControl isInvalid={(errors.title)} mb={5}>
                            <FormLabel>Titulo anuncio</FormLabel>
                            <Input placeholder='' {...getFieldProps('title')} />
                            <FormErrorMessage className='form-error-message'>
                                {errors.title}
                            </FormErrorMessage>
                        </FormControl>
            
                        <FormControl isRequired FormControl isInvalid={(errors.details)} mb={5}>
                            <FormLabel>Contenido</FormLabel>
                            <CKEditor 
                                editor={ClassicEditor}
                                config={{
                                    placeholder: 'Escribe aquí tu contenido...',
                                }}
                                data={newsFields?.details}
                                onChange={(_, editor) => {
                                    console.log(editor.getData());
                                    setFieldValue('details', editor.getData());
                                }}
                            />
                            <FormErrorMessage className='form-error-message'>
                                {errors.details}
                            </FormErrorMessage>
                        </FormControl>
    
                        <FormControl FormControl mb={5}>
                            <FormLabel>Fecha programada</FormLabel>
                            <small>Dejar vacía si no hay fecha especifica.</small>
                            <Input 
                                {...getFieldProps('launch_date')}
                                placeholder='Selecciona una fecha'
                                size='md'
                                type='date'
                                onChange={(e) => handleChangeDate(e, 'launch_date', setFieldValue)}
                                min={moment().add(1, 'd').format("YYYY-MM-DD")}
                            ></Input>
                            <FormErrorMessage className='form-error-message'>
                                {errors.launch_date}
                            </FormErrorMessage>
                        </FormControl>
                    </GridItem>
                    <Box mx={20}>
                        <GridItem colSpan={1}>
                            <FormControl isRequired FormControl isInvalid={(errors.image)} mb={5}>
                                <FormLabel mb='10px'>Imagen</FormLabel>
                                    <VStack>
                                        <label 
                                            className='file-news file-news-input'
                                            hidden={file || newsFields?.image_url} 
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                color: '#1c4b8b',
                                                cursor: 'pointer',
                                                width: '100%'
                                            }}>
                                            <i 
                                                class="bi bi-cloud-arrow-up-fill" 
                                                style={{
                                                    fontSize: '100px', 
                                                    marginRight:'10px',
                                                }}></i>
                                            <input 
                                                {...getFieldProps('image')}
                                                type='file'
                                                name='image'
                                                id='image'
                                                accept='image/*'
                                                onChange={(e) => setFile(e.target.files[0])}
                                                style={{display: 'none'}}
                                            /><span style={{
                                                marginBottom: '10px',
                                                fontSize: '20px'
                                            }} >{(file) ? file.name : 'Select image to upload'}</span>
                                        </label>
                                        <label 
                                            for='image' 
                                            className='file-news file-news-input'
                                            hidden={!file && !newsFields?.image_url}
                                            style={{
                                                width: '100%',
                                                height: 'auto'
                                            }}
                                        >
                                            <VStack>
                                                { file && <PreviewImage 
                                                    file={file} 
                                                    image={newsFields?.image_url}
                                                />}
                                                <span style={{fontSize: '18px'}}> 
                                                    <Img hidden={file} src={newsFields?.image_url} rounded={3} />
                                                    {(file) ? file?.name : newsFields?.image} 
                                                </span>
                                                <span style={{fontSize: '20px', color: '#1c4b8b'}}>Select another image</span>
                                            </VStack>
                                        </label> 
                                    </VStack>
                            </FormControl>
                            <FormErrorMessage className='form-error-message'>
                                {errors.image}
                            </FormErrorMessage>
                        </GridItem>
                    </Box>
                </Grid>
                <Grid templateColumns='repeat(2, 1fr)' gap={4} mt={10}>
                    <GridItem colSpan={1}>
                        <Button variant='secondary' onClick={() => navigate('/noticias')}>Cancelar</Button>
                    </GridItem>
                    <GridItem colStart={4} colEnd={6}>
                        <Button type='submit' variant='primary'>{(news_id) ? 'Modificar' : 'Agregar'}</Button>
                    </GridItem>
                </Grid>
            </Form>
            )}
        </Formik>
  )
}
