import { Box, Input, Button, Divider, Grid, GridItem, HStack, Heading, Icon, IconButton, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Stack, Text, Textarea, VStack, useDisclosure, AbsoluteCenter } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import InfoBox from '../../../components/credit_requests/summary/InfoBox'
import { useDispatch, useSelector } from 'react-redux'
import useDeparments from '../../../hooks/useDeparments'
import { useNavigate, useParams } from 'react-router-dom'
import ShowOrDeleteFile from '../../../components/credit_requests/fourth_step/ShowOrDeleteFile'
import useCreditRequest from '../../../hooks/useCreditRequest'
import Swal from 'sweetalert2'
import { Form, Formik } from 'formik'
import BadgeStatusCR from '../../../components/credit_requests/BadgeStatusCR'
import { colors } from '../../../themes/colors'
import { CRStatus } from '../../../enums/CRStatus'
import ModalReviewForm from '../../../components/credit_requests/ModalReviewForm'
import { Roles } from '../../../enums/Roles'
import ModalProposalForm from '../../../components/credit_requests/ModalProposalForm'

const CRReview = () => {

  const MXNFormat = new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN'
  });

  const { getCreditRequestInfo, addCommentToDocumentationCR, addCommentToComRef } = useCreditRequest();
  const { cr_id } = useParams();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { isOpen: isCROpen, onClose: onCRClose, onOpen: onCROpen } = useDisclosure();
  const { isOpen: isCRPOpen, onClose: onCRPClose, onOpen: onCRPOpen } = useDisclosure();

  const {role} = useSelector((state) => state.user.data);

  const navigate = useNavigate();
  const [departments, setDepartments] = useState();
  const [cr, setCR] = useState();
  const [commentModal, setCommentModal] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState([]);

  const { getDepartments } = useDeparments();

  const handleBack = () => {
    navigate('/solicitudes-de-credito');
  }

  const handleCommentModal = (document = null, title = null, comment = null, com_ref_id = null) => {
    const titles = {
      csf_comments: 'Constancia de situación fiscal',
      ine_comments: 'INE',
      constitutional_act_comments: 'Acta constitucional',
      account_status_comments: 'Estado de cuenta',
      address_comprobant_comments: 'Comprobante de domicilio',
      representative_power_comments: 'Poder de representante',
      sign_comments: 'Firma del solicitante',
    }

    setCommentModal({
      title: titles[document] || title,
      document,
      com_ref_id,
      comment: cr.documentation[document] || comment
    });
    onOpen();
  }

  useEffect(() => {
    (async () => {
      const { data } = await getDepartments();

      setDepartments(data);
    })();
  }, []);

  useEffect(() => {
    if (cr_id) {
      setIsLoading(true);
      getCreditRequestInfo(cr_id)
        .then((data) => {
          setCR(data);
          setIsLoading(false);
        });
    }
  }, []);

  return (
    <div>
      {
        !isLoading
          ? (
            <>
              <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>{commentModal?.title}</ModalHeader>
                  <ModalCloseButton />

                  <ModalBody>
                    <Text mb={2}>Agregar comentarios</Text>
                    <Formik
                      initialValues={{
                        comment: commentModal?.comment || ''
                      }}
                      onSubmit={(values) => {
                        const fetched = (commentModal.document) ? addCommentToDocumentationCR(commentModal.document, values.comment, cr.id) : addCommentToComRef(values.comment, commentModal.com_ref_id);

                        fetched.then(async ({ errors, success, message }) => {
                          if (success) {
                            await getCreditRequestInfo(cr_id)
                              .then((data) => {
                                setCR(data);
                              });
                            onClose();
                          } else {
                            setErrors(errors);
                          }
                        })
                      }}
                    >
                      {({ getFieldProps }) => (
                        <Form>
                          <Textarea placeholder='Escribe tus comentarios...' {...getFieldProps('comment')} />
                          <Stack flexDirection='row' w='100%' my={5}>
                            <Button onClick={onClose} flex={1} variant='secondary'>Cancelar</Button>
                            <Button type='submit' flex={1} variant='primary'>Agregar</Button>
                          </Stack>
                        </Form>
                      )}
                    </Formik>
                  </ModalBody>
                </ModalContent>
              </Modal>

              <ModalReviewForm creditRequest={cr} onClose={onCRClose} isOpen={isCROpen} onOpen={onCROpen} />
              <ModalProposalForm creditRequest={cr} onClose={onCRPClose} isOpen={isCRPOpen} onOpen={onCRPOpen} />

              <VStack w='100%'>
                <Heading variant='title'></Heading>
                <Grid templateColumns={['repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(3, 1fr)']} w='100%' gap={2}>
                  <GridItem>
                    <InfoBox title='Datos fiscales'>
                      <p><b>{cr.company_name}</b></p>
                      <p>{cr.fiscal_address.vatnum}</p>
                      <p>{cr.fiscal_address.street} #{cr.fiscal_address.num_ext} {cr.fiscal_address.num_int}, Col. {cr.fiscal_address.suburb}, C.P. {cr.fiscal_address.postal_code}, {cr.fiscal_address.city}, {cr.fiscal_address.state}</p>
                      <p><b>Teléfono:</b> {cr.fiscal_address.telephone}</p>
                    </InfoBox>
                  </GridItem>
                  <GridItem>
                    <InfoBox title='Información del crédito'>
                      <p><b>Monto solicitado:</b> {MXNFormat.format(cr.amount_requested)}</p>
                      <p><b>Plazo solicitado:</b> {cr.term_requested} días</p>
                      <p><b>Consumo mensual:</b> {MXNFormat.format(cr.mensual_consume)}</p>
                    </InfoBox>
                  </GridItem>
                  <GridItem>
                    <InfoBox title='Datos de facturación'>
                      <p><b>Método de pago:</b> {cr.fiscal_data.payment_method_id}</p>
                      <p><b>Forma de pago:</b> {cr.fiscal_data.payment_way_id}</p>
                      <p><b>Uso de CFDI:</b> {cr.fiscal_data.uso_cfdi_id}</p>
                      <p><b>Régimen fiscal:</b> {cr.fiscal_data.tax_regime_id}</p>
                    </InfoBox>
                  </GridItem>
                  <GridItem>
                    <InfoBox title='Procedimiento de pago'>
                      <VStack textAlign='left'>
                        <p><b>Días de pago</b></p>
                        <Grid templateColumns='repeat(7, 1fr)'
                          w='100%'
                          borderWidth={1}
                          borderColor='gray.400'
                          padding={3}
                          borderRadius={5}
                        >
                          {[...Array(31)].map((x, i) => (
                            <Box
                              m={1}
                              padding={1}
                              height='40px'
                              borderColor='gray'
                              borderRadius='full'
                              display='flex'
                              justifyContent='center'
                              alignItems='center'
                              backgroundColor={(cr.pay_days.indexOf(i + 1) !== -1) ? 'primary.900' : 'white'}
                              color={(cr.pay_days.indexOf(i + 1) !== -1) ? 'white' : 'black'}
                              fontWeight='bold'
                              transition='0.8s'
                              key={i + 1}
                            >
                              {i + 1}
                            </Box>
                          ))}
                        </Grid>
                      </VStack>
                      <VStack mt={3}>
                        <p><b>Días de revisión</b></p>
                        <HStack justifyContent='space-around' w='100%'>
                          <p>Lu</p>
                          <p>Ma</p>
                          <p>Mi</p>
                          <p>Ju</p>
                          <p>Vi</p>
                          <p>Sa</p>
                          <p>Do</p>
                        </HStack>
                        <HStack justifyContent='space-around' w='100%'>
                          <i className={`bi ${JSON.parse(cr.review_days).indexOf(1) !== -1 ? 'bi-check-circle review-day-selected' : 'bi-circle'} review-day`}></i>
                          <i className={`bi ${JSON.parse(cr.review_days).indexOf(2) !== -1 ? 'bi-check-circle review-day-selected' : 'bi-circle'} review-day`}></i>
                          <i className={`bi ${JSON.parse(cr.review_days).indexOf(3) !== -1 ? 'bi-check-circle review-day-selected' : 'bi-circle'} review-day`}></i>
                          <i className={`bi ${JSON.parse(cr.review_days).indexOf(4) !== -1 ? 'bi-check-circle review-day-selected' : 'bi-circle'} review-day`}></i>
                          <i className={`bi ${JSON.parse(cr.review_days).indexOf(5) !== -1 ? 'bi-check-circle review-day-selected' : 'bi-circle'} review-day`}></i>
                          <i className={`bi ${JSON.parse(cr.review_days).indexOf(6) !== -1 ? 'bi-check-circle review-day-selected' : 'bi-circle'} review-day`}></i>
                          <i className={`bi ${JSON.parse(cr.review_days).indexOf(7) !== -1 ? 'bi-check-circle review-day-selected' : 'bi-circle'} review-day`}></i>
                        </HStack>
                      </VStack>
                      <Divider my={2} />
                      <p><b>Cuenta bancaria:</b> {cr.bank_account}</p>
                      <p>{cr.payment_address.street} #{cr.payment_address.num_ext} {cr.payment_address.num_int}, Col. {cr.payment_address.suburb}, C.P. {cr.payment_address.postal_code}, {cr.payment_address.city}, {cr.payment_address.state}</p>
                    </InfoBox>
                  </GridItem>
                  <GridItem>
                    <InfoBox title='Referencias Comerciales'>
                      {
                        cr.commercial_references.map((com_ref) => (
                          <HStack alignItems='start'>
                            <Box flex={1} mb={3}>
                              <p><b>{com_ref.name} {com_ref.last_name} - {com_ref.company_name}</b></p>
                              <p><b>Teléfono:</b> {com_ref.telephone}</p>
                              <p><b>Correo contacto:</b> {com_ref.email}</p>
                              <p><b>Monto:</b> {MXNFormat.format(com_ref.limit_credit)}</p>
                              <p><b>Plazo:</b> {MXNFormat.format(com_ref.term_credit)}</p>
                            </Box>
                            <IconButton hidden={cr.status.id !== CRStatus.Creada && cr.status.id !== CRStatus.EnValidacion} onClick={() => handleCommentModal(null, `Referencia - ${com_ref.name} ${com_ref.last_name}`, com_ref.comments, com_ref.id)} icon={<i className={`bi bi-chat${(com_ref.comments) ? '-fill' : ''}`}></i>} />
                          </HStack>
                        ))
                      }
                    </InfoBox>
                  </GridItem>
                  <GridItem>
                    <InfoBox title='Principales ejecutivos'>
                      {
                        cr.executives.map((executive) => (
                          <Box mb={3}>
                            <p><b>{executive.name} {executive.last_name}</b></p>
                            <p><b>Teléfono:</b> {executive.telephone}</p>
                            <p><b>Correo de contacto:</b> {executive.email}</p>
                            {
                              departments && <p><b>Departamento:</b> {departments.filter((dep) => dep.id == executive.department_id)[0].department}</p>
                            }
                          </Box>
                        ))
                      }
                    </InfoBox>
                  </GridItem>
                </Grid>
                <Grid templateColumns={'repeat(1, 1fr)'} w='100%' gap={2}>
                  <GridItem>
                    <InfoBox title='Documentación'>
                      <Grid templateColumns={['repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(2, 1fr)']} gap={5}>
                        <GridItem>
                          <HStack>
                            <Box flex={1}>
                              <ShowOrDeleteFile title='Constancia de situación fiscal' filePath={cr.documentation.csf_filename} typeFile='csf_filename' onlyShow />
                            </Box>
                            <IconButton hidden={cr.status.id !== CRStatus.Creada && cr.status.id !== CRStatus.EnValidacion} onClick={() => handleCommentModal('csf_comments')} icon={<i className={`bi bi-chat${(cr.documentation.csf_comments) ? '-fill' : ''}`}></i>} />
                          </HStack>
                        </GridItem>
                        <GridItem>
                          <HStack>
                            <Box flex={1}>
                              <ShowOrDeleteFile title='INE (Frontal y reversa)' filePath={cr.documentation.ine_filename} typeFile='ine_filename' onlyShow />
                            </Box>
                            <IconButton hidden={cr.status.id !== CRStatus.Creada && cr.status.id !== CRStatus.EnValidacion} onClick={() => handleCommentModal('ine_comments')} icon={<i className={`bi bi-chat${(cr.documentation.ine_comments) ? '-fill' : ''}`}></i>} />
                          </HStack>
                        </GridItem>
                        <GridItem>
                          <HStack>
                            <Box flex={1}>
                              <ShowOrDeleteFile title='Acta constitucional' filePath={cr.documentation.constitutional_act_filename} typeFile='constitutional_act_filename' onlyShow />
                            </Box>
                            <IconButton hidden={cr.status.id !== CRStatus.Creada && cr.status.id !== CRStatus.EnValidacion} onClick={() => handleCommentModal('constitutional_act_comments')} icon={<i className={`bi bi-chat${(cr.documentation.constitutional_act_comments) ? '-fill' : ''}`}></i>} />
                          </HStack>
                        </GridItem>
                        <GridItem>
                          <HStack>
                            <Box flex={1}>
                              <ShowOrDeleteFile title='Caratula de estado de cuenta' filePath={cr.documentation.account_status_filename} typeFile='account_status_filename' onlyShow />
                            </Box>
                            <IconButton hidden={cr.status.id !== CRStatus.Creada && cr.status.id !== CRStatus.EnValidacion} onClick={() => handleCommentModal('account_status_comments')} icon={<i className={`bi bi-chat${(cr.documentation.account_status_comments) ? '-fill' : ''}`}></i>} />
                          </HStack>
                        </GridItem>
                        <GridItem>
                          <HStack>
                            <Box flex={1}>
                              <ShowOrDeleteFile title='Comprobante de domicilio' filePath={cr.documentation.address_comprobant_filename} typeFile='address_comprobant_filename' onlyShow />
                            </Box>
                            <IconButton hidden={cr.status.id !== CRStatus.Creada && cr.status.id !== CRStatus.EnValidacion} onClick={() => handleCommentModal('address_comprobant_comments')} icon={<i className={`bi bi-chat${(cr.documentation.address_comprobant_comments) ? '-fill' : ''}`}></i>} />
                          </HStack>
                        </GridItem>
                        <GridItem>
                          <HStack>
                            <Box flex={1}>
                              <ShowOrDeleteFile title='Poder de representante' filePath={cr.documentation.representative_power_filename} typeFile='representative_power_filename' onlyShow />
                            </Box>
                            <IconButton hidden={cr.status.id !== CRStatus.Creada && cr.status.id !== CRStatus.EnValidacion} onClick={() => handleCommentModal('representative_power_comments')} icon={<i className={`bi bi-chat${(cr.documentation.representative_power_comments) ? '-fill' : ''}`}></i>} />
                          </HStack>
                        </GridItem>
                      </Grid>

                      <Divider my={2} />

                      <p><b>Instrucciones:</b> Para continuar con el proceso será necesario que nos apoye con una firma aceptando la solicitud de crédito, por lo que necesitas imprimir el formato firmarlo y enviarlo de vuelta.</p>


                      <Stack direction={['column', 'column', 'column', 'row']} justifyContent='start' alignItems='center'>
                        <span style={{ fontWeight: 'bold', marginRight: '5px', textAlign: 'right' }}>Firma de solicitud de crédito</span>
                        {
                          <ShowOrDeleteFile onlyShow={cr.status.id !== CRStatus.Pendiente} filePath={cr.documentation.sign_filename} typeFile='sign_filename' />
                        }
                        <IconButton hidden={cr.status.id !== CRStatus.Creada && cr.status.id !== CRStatus.EnValidacion} onClick={() => handleCommentModal('sign_comments')} icon={<i className={`bi bi-chat${(cr.documentation.sign_comments) ? '-fill' : ''}`}></i>} />
                      </Stack>
                    </InfoBox>
                  </GridItem>
                </Grid>
                <Stack direction='row' justifyContent='space-between' w='100%'>
                  <Button variant='secondary' onClick={handleBack}>Regresar</Button>
                  {
                    (role.id === Roles.SuperAdmin)
                      ? <Button variant='primary' onClick={onCROpen}>Continuar</Button>
                      : (cr.status.id === CRStatus.Autorizada) ? <Button variant='primary' onClick={onCRPOpen}>Ver propuesta</Button> : <Text>Tu solicitud se encuentra en revisión</Text>
                  }
                </Stack>
              </VStack>
            </>
          )
          : <p>Cargando</p>
      }
    </div>
  )
}

export default CRReview