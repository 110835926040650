import React from 'react'
import { Box, Container, HStack } from '@chakra-ui/react';
import { colors } from '../../themes/colors';

const LoginLayout = ({ children }) => {
    return (
        <Container
            bgColor='primary.900'
            maxW='100%'
            h='100vh'
        >
            <HStack
                h='100%'
                justifyContent='space-around'
            >
                <Box
                    display={['none', 'none', 'none', 'flex']}
                    flexDir='row'
                    marginLeft='-100px'
                >
                        <div style={{
                            clipPath: "polygon(0% 0%, 50% 0%, 100% 50%, 50% 100%, 0% 100%, 50% 50%)",
                            backgroundColor: 'white',
                            width: '300px',
                            height: '100vh'
                        }} className='animate__animated animate__fadeInLeft'></div>
                        <div style={{
                            clipPath: "polygon(0% 0%, 50% 0%, 100% 50%, 50% 100%, 0% 100%, 50% 50%)",
                            backgroundColor: colors.secondary['900'],
                            width: '300px',
                            marginLeft: '-150px',
                            height: '100vh'
                        }} className='animate__animated animate__fadeInLeft'></div>
                </Box>
                <Box
                    flex={1}
                >
                    {children}
                </Box>
            </HStack>
        </Container>
    )
}

export default LoginLayout