import { Box, Button, HStack } from '@chakra-ui/react'
import React from 'react'

const InfoBox = ({title, handleEditSection=null, children}) => {
    return (
        <Box
            borderRadius={3}
            h='100%'
            overflow='hidden'
            borderWidth={1}
            borderColor='gray.200'
        >
            <HStack
                backgroundColor='gray.200'
                p={2}
                fontWeight='bold'
                justifyContent='space-between'
            >
                <p>{title}</p>
                {
                    (handleEditSection) && <Button onClick={handleEditSection} variant='unstyled'><i className='bi bi-pencil'></i></Button>
                }
            </HStack>
            <Box
                p={2}
            >
                {children}
            </Box>
        </Box>
    )
}

export default InfoBox