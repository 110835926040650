import { Box, Button, Divider, FormControl, FormErrorMessage, FormLabel, Grid, GridItem, HStack, Heading, Input, Select } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup'

import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useAddresses } from '../../hooks/useAddresses';
import useSepomex from '../../hooks/useSepomex';

export const AddressForm = () => {

    const { address_id } = useParams();

    const { createAddress, updateAddress, getAddress, getAddressesTypes } = useAddresses();

    const { getDataFromPostalCode } = useSepomex();
    const [ cpData, setCpData ] = useState(null);
    const [ addressFields, setAddressFields] = useState(null);
    const [ addressType, setAddressType ] = useState([]);

    const navigate = useNavigate();

    const handleNumericInput = (e, setFieldValue) => {
        const value = e.target.value;
        const numericValue = value.replace(/[^\d]/g, '');
        setFieldValue('telephone', numericValue);
    };

    const handlePostalCode = (event, handleChange) => {
        handleChange(event);
        const postal_code = event.target.value;

        loadCitiesAndStates(postal_code);
    }

    const loadCitiesAndStates = async (postal_code) => {
        if (postal_code.toString().length == 5) {
            const data = await getDataFromPostalCode(postal_code);
            setCpData(data);
        } else {
            setCpData(null);
        }
    }

    const handleCreateAddress = async (values, { setErrors }) => {
        const data = await createAddress(values);
        
        if (data.success) {
            navigate('/mis-direcciones');
        } else {
            if (data.errors) {
                setErrors(data.errors);
            } else {
                setErrors({
                global: 'Hubo un error en general.'
                });
            }
        }
    };

    const handleUpdateAddress = async (values, { setErrors }) => {
        const data = await updateAddress(values, address_id);
        
        if (data.success) {
            navigate('/mis-direcciones');
        } else {
            if (data.errors) {
                setErrors(data.errors);
            } else {
                setErrors({
                global: 'Hubo un error en general.'
                });
            }
        }
    };
    
    useEffect(() => {
        (async () => {
            if (address_id) {
                const result = await getAddress(address_id);
                setAddressFields(result);
    
                if (result.postal_code) {
                    const postalCodeData = await getDataFromPostalCode(result.postal_code);
                    setCpData(postalCodeData);
                }
            }
        })();
    }, [address_id]);

    useEffect(() => {
        (async () => {
            const result = await getAddressesTypes();
            setAddressType(result);
        })();
      }, []);
    
    
  return (
    (!addressFields && address_id)
        ?
        <p>Cargando...</p>
        :
    <Formik
        initialValues={{
            address_type: addressFields?.address_type.id || '',
            company_name: addressFields?.company_name || '',
            vatnum: addressFields?.vatnum || '',
            telephone: addressFields?.telephone || '',
            street: addressFields?.street || '',
            num_ext: addressFields?.num_ext || '',
            num_int: addressFields?.num_int || '',
            postal_code: addressFields?.postal_code || '',
            suburb: addressFields?.suburb || '',
            city: addressFields?.city || '',
            state: addressFields?.state || '',
            references: addressFields?.references || ''
        }}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={Yup.object({
            address_type: Yup.string().required('Este campo es necesario'),
            company_name: Yup.string().required('Este campo es necesario.'),
            vatnum: Yup.string().required('Este campo es necesario.'),
            telephone: Yup.string().matches(/^[0-9]{10}?/, "El número de teléfono debe tener solo 10 dígitos.").required('Este campo es necesario.'),
            street: Yup.string().required('Este campo es necesario.'),
            num_ext: Yup.string().required('Este campo es necesario.'),
            num_int: Yup.string().notRequired(),
            postal_code: Yup.string().required('Este campo es necesario.'),
            suburb: Yup.string().required('Este campo es necesario.'),
            city: Yup.string().required('Este campo es necesario.'),
            state: Yup.string().required('Este campo es necesario.'),
            references: Yup.string().required('Este campo es necesario.'),
        })}

        onSubmit={(address_id) ? handleUpdateAddress : handleCreateAddress } 
        >
            {({ values, getFieldProps, errors, handleChange, setFieldValue }) => (
            <Form>
                {
                    (errors.global) && <Box className='form-error-message' mb={2}>{errors.global}</Box>
                }
            <Heading variant='title'>
                {
                    (address_id) ? 'Editar Dirección' : 'Crear Dirección'
                }
            </Heading>
            <Divider my={2} />
            <HStack
                justifyContent='space-between'
            >
            </HStack>
                <Grid templateColumns='repeat(2, 1fr)' gap={4}>
                    <GridItem colSpan={1}>
                        <FormControl isRequired FormControl isInvalid={(errors.address_type)} mb={5}>
                            <FormLabel>Tipo de dirección</FormLabel>
                            <Select {...getFieldProps('address_type')}>
                                <option>Selecciona una dirección</option>
                                {
                                    (addressType && addressType.data) && addressType.data.filter(ad => ad.id > 1).map((adi) => <option value={adi.id} key={adi.id}>{adi.address_type}</option>)
                                }
                            </Select>
                            <FormErrorMessage className='form-error-message'>
                                {errors.address_type}
                            </FormErrorMessage>
                        </FormControl>
            
                        <FormControl isRequired isInvalid={(errors.company_name)} mb={5}>
                            <FormLabel>Razón social</FormLabel>
                            <Input type='text' {...getFieldProps('company_name')} />
                            <FormErrorMessage className='form-error-message'>
                                {errors.company_name}
                            </FormErrorMessage>
                        </FormControl>
            
                        <FormControl isRequired isInvalid={(errors.vatnum)} mb={5}>
                            <FormLabel>RFC</FormLabel>
                            <Input type='text' {...getFieldProps('vatnum')} maxLength={13} />
                            <FormErrorMessage className='form-error-message'>
                                {errors.vatnum}
                            </FormErrorMessage>
                        </FormControl>
            
                        <FormControl isRequired isInvalid={(errors.postal_code)} mb={5}>
                            <FormLabel>Código postal</FormLabel>
                            <Input type='text' {...getFieldProps('postal_code')} onChange={(e) => handlePostalCode(e, handleChange)} />
                            <FormErrorMessage className='form-error-message'>
                                {errors.postal_code}
                            </FormErrorMessage>
                        </FormControl>
            
                        <FormControl isRequired isInvalid={(errors.suburb)} mb={5}>
                            <FormLabel>Colonia</FormLabel>
                            <Select {...getFieldProps('suburb')}>
                                <option>Selecciona una colonia</option>
                                {
                                    (cpData && cpData.colonias) && cpData.colonias.map((col) => <option value={col.colonia} key={col.id}>{col.colonia}</option>)
                                }
                            </Select>
                            <FormErrorMessage className='form-error-message'>
                                {errors.suburb}
                            </FormErrorMessage>
                        </FormControl>
            
                        <FormControl isRequired isInvalid={(errors.state)} mb={5}>
                            <FormLabel>Estado</FormLabel>
                            <Select {...getFieldProps('state')}>
                                <option>Selecciona un estado</option>
                                {
                                    cpData && cpData.estado && <option value={cpData.estado}>{cpData.estado}</option>
                                }
                            </Select>
                            <FormErrorMessage className='form-error-message'>
                                {errors.state}
                            </FormErrorMessage>
                        </FormControl>
            
                        <FormControl isRequired isInvalid={(errors.city)} mb={5}>
                            <FormLabel>Municipio</FormLabel>
                            <Select {...getFieldProps('city')}>
                                <option>Selecciona un Municipio</option>
                                {
                                    cpData && cpData.municipio && <option value={cpData.municipio}>{cpData.municipio}</option>
                                }
                            </Select>
                            <FormErrorMessage className='form-error-message'>
                                {errors.city}
                            </FormErrorMessage>
                        </FormControl>
            
                        {/* <FormControl isRequired mb={5} isInvalid={(errors.country)}>
                            <FormLabel>Pais</FormLabel>
                            <Select placeholder='México'>
                                <option>México</option>
                            </Select>
                        </FormControl> */}
                    </GridItem>
        
        
        
                    <GridItem colStart={2} colEnd={6}>
                        <FormControl isRequired isInvalid={(errors.street)} mb={5}>
                            <FormLabel>Calle</FormLabel>
                            <Input type='text' {...getFieldProps('street')} />
                            <FormErrorMessage className='form-error-message'>
                                {errors.street}
                            </FormErrorMessage>
                        </FormControl>
            
                        <FormControl isRequired isInvalid={(errors.num_ext)} mb={5}>
                            <FormLabel>Número Exterior</FormLabel>
                            <Input type='text' {...getFieldProps('num_ext')} />
                            <FormErrorMessage className='form-error-message'>
                                {errors.num_ext}
                            </FormErrorMessage>
                        </FormControl>

                        <FormControl isInvalid={(errors.num_int)} mb={5}>
                            <FormLabel>Número interior</FormLabel>
                            <Input type='text' {...getFieldProps('num_int')} />
                            <FormErrorMessage className='form-error-message'>
                                {errors.num_int}
                            </FormErrorMessage>
                        </FormControl>
            
                        <FormControl isRequired isInvalid={(errors.telephone)} mb={5}>
                            <FormLabel>Teléfono</FormLabel>
                            <Input type='text' {...getFieldProps('telephone')} maxLength={10} onChange={(e) => handleNumericInput(e, setFieldValue)} />
                            <FormErrorMessage>
                                {errors.telephone}
                            </FormErrorMessage>
                        </FormControl>
            
                        <FormControl isRequired isInvalid={(errors.references)} mb={5}>
                            <FormLabel>Rerencias</FormLabel>
                            <Input type='text' {...getFieldProps('references')} />
                            <FormErrorMessage>
                                {errors.references}
                            </FormErrorMessage>
                        </FormControl>
                    </GridItem>
                </Grid>

                <Grid templateColumns='repeat(2, 1fr)' gap={4} mt={5}>
                    <GridItem colSpan={1}>
                        <Button variant='secondary' onClick={() => navigate('/mis-direcciones')}>Cancelar</Button>
                    </GridItem>
                    <GridItem colStart={4} colEnd={6}>
                        <Button type='submit' variant='primary'>{(address_id) ? 'Modificar' : 'Agregar'}</Button>
                    </GridItem>
                </Grid>
            </Form>
            )}
        </Formik>
    )
}
