import { Box, Button, FormControl, FormErrorMessage, FormLabel, Grid, GridItem, HStack, Heading, Input, Modal, ModalBody, ModalContent, ModalHeader, Select, Stack, useDisclosure } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import React, { useEffect } from 'react'
import * as Yup from 'yup';
import useDeparments from '../../../hooks/useDeparments';
import { useState } from 'react';
import useCreditRequest from '../../../hooks/useCreditRequest';
import { useDispatch, useSelector } from 'react-redux';
import { syncCreditRequestInfo } from '../../../redux/slices/creditRequestSlice';

const Executives = ({
    setExecutives,
    executives
}) => {

    const { isOpen, onClose, onOpen } = useDisclosure();
    const { getDepartments } = useDeparments();
    const { addExecutiveToCR, updateExecutiveToCR, deleteExecutiveToCR } = useCreditRequest();
    const [departments, setDepartments] = useState();
    const [executive, setExecutive] = useState();

    const dispatch = useDispatch();
    const { executives: cr_executives } = useSelector((state) => state.credit_request.info);

    const handleDeleteExecutive = async (executive_id) => {
        const { success, message } = await deleteExecutiveToCR(executive_id);

        if (success) {
            setExecutives((executives) => executives.filter(({ id }) => id != executive_id));
            dispatch(syncCreditRequestInfo());
        } else {
            alert(message);
        }
    }

    const handleEditExecutive = (ref) => {
        onOpen();
        setExecutive(ref);
    }

    const handleNewExecutive = () => {
        onOpen();
        setExecutive(null);
    }

    const handleCreateExecutive = async (values, setErrors) => {
        const { success, executive_id, errors } = (!executive) ? await addExecutiveToCR(values) : await updateExecutiveToCR(values, executive.id);

        if (success) {
            if (!executive) {
                setExecutives([
                    ...executives,
                    {
                        id: executive_id,
                        ...values
                    }
                ]);
            } else {
                setExecutives((executives) => executives.map((e) => {
                    if (e.id == executive_id) {
                        return {
                            id: executive_id,
                            ...values
                        }
                    } else {
                        return e;
                    }
                }));
            }

            dispatch(syncCreditRequestInfo());
        } else {
            setErrors(errors);
        }
    }

    useEffect(() => {
        (async () => {
            const { data } = await getDepartments();

            setDepartments(data);
        })();

    }, []);

    useEffect(() => {
        setExecutives(cr_executives)
    }, [cr_executives]);

    return (
        <>
            <Heading size='md'>Principales ejecutivos <i className='bi bi-info-circle'></i></Heading>
            <Grid
                templateColumns={['repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(2, 1fr)']}
                gap={3}
            >
                {
                    executives &&
                    executives.map((executive_ref, index) => (
                        <GridItem key={index}>
                            <Box className='client-info-box'>
                                <Box>
                                    <p><b>Nombre:</b> {executive_ref.name} {executive_ref.last_name}</p>
                                </Box>
                                <Box>
                                    <p><b>Correo:</b> {executive_ref.email}</p>
                                </Box>
                                <Box>
                                    <p><b>Teléfono:</b> {executive_ref.telephone}</p>
                                </Box>
                                <Box>
                                    {
                                        departments && <p><b>Departamento:</b> {departments.filter((dep) => dep.id == executive_ref.department_id)[0].department}</p>
                                    }
                                </Box>
                                <HStack mt={2} justifyContent='space-between'>
                                    <Button colorScheme='red' onClick={() => handleDeleteExecutive(executive_ref.id)}>
                                        <i className='bi bi-trash'></i>
                                    </Button>
                                    <Button colorScheme='blue' onClick={() => handleEditExecutive(executive_ref)}>
                                        <i className='bi bi-pencil'></i>
                                    </Button>
                                </HStack>
                            </Box>
                        </GridItem>
                    ))
                }
                {
                    executives.length < 5
                    &&
                    <GridItem>
                        <Box
                            h='100%'
                            fontSize={50}
                            display='flex'
                            justifyContent='center'
                            alignItems='center'
                            borderColor='gray.400'
                            color='gray.400'
                            borderWidth='2px'
                            borderRadius='4px'
                            cursor='pointer'
                            onClick={handleNewExecutive}
                            transition='0.2s'
                            _hover={{
                                borderColor: 'gray.500',
                                color: 'gray.500',
                            }}
                        >
                            <i className='bi bi-plus'></i>
                        </Box>
                    </GridItem>
                }
            </Grid>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
            >
                <ModalContent py={3}>
                    <ModalHeader>Agregar ejecutivo <i className='bi bi-info-circle'></i></ModalHeader>
                    <ModalBody>
                        <Formik
                            initialValues={{
                                name: executive?.name || '',
                                last_name: executive?.last_name || '',
                                email: executive?.email || '',
                                telephone: executive?.telephone || '',
                                department_id: executive?.department_id || ''
                            }}
                            validateOnChange={false}
                            validateOnBlur={false}
                            validationSchema={Yup.object({
                                name: Yup.string().required('Este campo es requerido.'),
                                last_name: Yup.string().required('Este campo es requerido.'),
                                telephone: Yup.number().test('tel', 'El teléfono debe tener solo 10 dígitos.', val => val.toString().length === 10).required('Este campo es requerido.'),
                                email: Yup.string().email().required('Este campo es requerido.'),
                                department_id: Yup.string().required('Este campo es requerido.')
                            })}
                            onSubmit={(values, { setErrors }) => {
                                handleCreateExecutive(values, setErrors);
                                onClose();
                            }}
                        >
                            {({ errors, getFieldProps }) => (
                                <Form>
                                    <HStack>
                                        <FormControl isInvalid={errors.name}>
                                            <FormLabel>Nombre</FormLabel>
                                            <Input type='text' {...getFieldProps('name')} />
                                            <FormErrorMessage className='form-error-message'>
                                                {errors.name}
                                            </FormErrorMessage>
                                        </FormControl>
                                        <FormControl isInvalid={errors.last_name}>
                                            <FormLabel>Apellido</FormLabel>
                                            <Input type='text' {...getFieldProps('last_name')} />
                                            <FormErrorMessage className='form-error-message'>
                                                {errors.last_name}
                                            </FormErrorMessage>
                                        </FormControl>
                                    </HStack>
                                    <FormControl isInvalid={errors.email}>
                                        <FormLabel>Correo de contacto</FormLabel>
                                        <Input type='text' {...getFieldProps('email')} />
                                        <FormErrorMessage className='form-error-message'>
                                            {errors.email}
                                        </FormErrorMessage>
                                    </FormControl>
                                    <FormControl isInvalid={errors.telephone}>
                                        <FormLabel>Teléfono</FormLabel>
                                        <Input type='string' {...getFieldProps('telephone')} maxLength={10} />
                                        <FormErrorMessage className='form-error-message'>
                                            {errors.telephone}
                                        </FormErrorMessage>
                                    </FormControl>
                                    <FormControl isInvalid={errors.department_id}>
                                        <FormLabel>Departamento</FormLabel>
                                        <Select {...getFieldProps('department_id')}>
                                            <option>Selecciona un departamento</option>
                                            {
                                                departments.map((department) => <option key={department.id} value={department.id}>{department.department}</option>)
                                            }
                                        </Select>
                                        <FormErrorMessage className='form-error-message'>
                                            {errors.department_id}
                                        </FormErrorMessage>
                                    </FormControl>
                                    <Stack direction={['column', 'column', 'row', 'row']} mt={5} justifyContent='space-between'>
                                        <Button variant='secondary' onClick={onClose}>Cancelar</Button>
                                        <Button type='submit' variant='primary'>{(executive) ? 'Modificar' : 'Agregar'}</Button>
                                    </Stack>
                                </Form>
                            )}
                        </Formik>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export default Executives