import { Badge, Box, Button, Divider, HStack, Heading, Table, Tbody, Td, Th, Thead, Tr, Stack, Link, Tabs, TabList, Tab, TabPanels, TabPanel, Menu, MenuButton, MenuList, MenuItem, Accordion, TableCaption, TableContainer, createMultiStyleConfigHelpers, MenuGroup, Input, Text, MenuDivider, Select } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useUser } from '../../hooks/useUser';
import Paginator from '../../components/partials/Paginator';
import { useSelector } from 'react-redux';
import UserRequestList from '../../components/user/UserRequestList';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Roles } from '../../enums/Roles';
import { UserTableRow, AccordionUserTableRow } from '../../components/user/UserTableRow';

const UsersListPage = () => {

  const { getUsers } = useUser();
  const navigate = useNavigate();
  const data = useSelector((state) => state.user.data);
  const {token, role} = useSelector((state) => state.user.data);
  const [users, setUsers] = useState(null);

  // Agregar cuando funcione paginador
  const [paginator, setPaginator] = useState(null);
  const [currentUrl, setCurrentUrl] = useState(null);

  const [loading, setLoading] = useState(true);

  const [ searchParams, setSearchParams ] = useSearchParams();
  const [ hidden, setHidden ] = useState(true);
  const [ selectedSupplier, setSelectedSupplier ] = useState();

  const handleUserFilter = (e) => {
    const { name, value } = e.target;

    const newParams = new URLSearchParams(searchParams)

    if(value !== ''){ 
      newParams.set(name, value);
      setHidden(false);
    } else {
      newParams.delete(name);
      setHidden(true);
    }

    setSearchParams(newParams);
  };

  const handleSelectChange = (e) => {
    setSelectedSupplier(e.target.value);
  };

  const handleCleanFilters = () => {
    setSearchParams('');
    setHidden(true);
  };

  const handleUpdateCRStatus = (user_id) => {
    setUsers((users) => users.map((user) => {
      if(user.id == user_id) {
        user.has_credit_request = !user.has_credit_request;
      }

      return user;
    }))
  }

  useEffect(() => {
    getUsers(currentUrl, token, searchParams)
      .then(({ success, data, errors }) => {
        if (success) {
          setUsers(data.data);
          setPaginator(data);
          setLoading(false);
        } else {
          console.log(errors);
        }
      });
  }, [currentUrl, searchParams]);

  return (
    <>
      <Heading variant='title'>Administra tus usuarios</Heading>
      <Divider my={2} />
      <Tabs colorScheme='red' variant='enclosed'>
        <TabList>
          <Tab>Usuarios</Tab>
          {
            (role.id === Roles.SuperAdmin) && <Tab>Solicitudes de altas <Badge rounded='15px' py={1} px={3} hidden ml={2} colorScheme='red' variant='subtle'>1</Badge></Tab>
          }
        </TabList>
        <TabPanels>
          <TabPanel>
            <HStack
              justifyContent='space-between'
            >
              <Link href={(role.id === Roles.SuperAdmin || role.id === Roles.Altas ? '/usuarios/nuevo' : '/usuarios/nuevo-secundario')}>
                <Button variant='primary'>Agregar nuevo</Button>
              </Link>
              <Menu closeOnSelect={false}>
              <HStack>
                <MenuButton 
                  hidden={hidden}
                  onClick={handleCleanFilters}
                >
                  <Box>
                    <Button>
                      Limpiar Filtros
                    </Button>
                  </Box>
                </MenuButton>
                <MenuButton as={Button}>
                  <p>Filtrar por <i class="bi bi-caret-down-fill"></i></p>
                </MenuButton>
              </HStack>
              <MenuList>
                <MenuGroup title='Fecha'>
                  <MenuItem>
                    <Box mr='5'>
                      <Text mb='8px'>Inicio </Text>
                      <Input
                        onClick={e => e.stopPropagation()}
                        placeholder="Select Date and Time"
                        value={searchParams?.start_date}
                        name='start_date'
                        onChange={handleUserFilter}
                        size="md"
                        type="date"
                      />
                    </Box>
                    <Box>
                      <Text mb='8px'>Fin</Text>
                      <Input
                        onClick={e => e.stopPropagation()}
                        placeholder="Select Date and Time"
                        value={searchParams?.end_date}
                        name='end_date'
                        onChange={handleUserFilter}
                        size="md"
                        type="date"
                      />
                    </Box>
                  </MenuItem>
                <MenuDivider />
                </MenuGroup>
                <MenuGroup title='Búsqueda'>
                  <MenuItem>
                    <Select
                      onClick={e => e.stopPropagation()}
                      onChange={handleSelectChange}
                      placeholder='Selecciona...'
                    >
                      <option value='name'>Nombre</option>
                      <option value='email'>Correo</option>
                    </Select>
                  </MenuItem>
                  <MenuItem>
                    <Input
                      disabled={!selectedSupplier}
                      onClick={e => e.stopPropagation()}
                      placeholder="Filtrar..."
                      onChange={handleUserFilter}
                      name={selectedSupplier}
                    />
                  </MenuItem>
                  <MenuDivider />
                  <MenuGroup title='Estatus'>
                    <MenuItem>
                      <Select
                        onClick={e => e.stopPropagation()}
                        placeholder='Selecciona el tipo de estatus'
                        name='status_id'
                        onChange={handleUserFilter}
                        mb={4}
                      >
                        <option value='1'>Pendiente</option>
                        <option value='2'>Creada</option>
                        <option value='3'>Autorizada</option>
                        <option value='4'>Autorizada</option>
                        <option value='5'>Denegada</option>
                      </Select>
                    </MenuItem>
                  </MenuGroup>
                </MenuGroup>
              </MenuList>
            </Menu>
            </HStack>
            <Box mt={5}>
              <Accordion allowMultiple reduceMotion>
                <TableContainer>
                  <Table>
                    <Thead>
                      <Tr>
                        <Th />
                        <Th>Usuario</Th>
                        <Th>Correo</Th>
                        <Th>Perfil de usuario</Th>
                        <Th>Estatus</Th>
                        <Th>Acciones</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {
                        !loading 
                          && users && users.map 
                            && users.map((user) => (user.secondary_users && user.secondary_users.length > 0 && user.role_id === Roles.Cliente) 
                              ? <AccordionUserTableRow key={user.id} handleUpdateCRStatus={handleUpdateCRStatus} token={token} user={user} /> 
                              : <UserTableRow key={user.id} handleUpdateCRStatus={handleUpdateCRStatus} token={token} user={user} />
                            )
                      }
                    </Tbody>
                  </Table>
                </TableContainer>
              </Accordion>
              {
                !loading && <Paginator setCurrentUrl={setCurrentUrl} paginator={paginator} />
              }
            </Box>
          </TabPanel>
          {
            (role.id === Roles.SuperAdmin) && (
              <TabPanel>
                <UserRequestList token={token} />
              </TabPanel>
            )
          }
        </TabPanels>
      </Tabs>
    </>
  )
}


export default UsersListPage