import { Box, Button, Divider, HStack, Heading, Input, Menu, MenuButton, MenuDivider, MenuGroup, MenuItem, MenuList, Select, SimpleGrid, Skeleton, Stack, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useTemplates } from '../../../hooks/useTemplates'
import Paginator from '../../../components/partials/Paginator'

const TemplatesPage = () => {

  const navigate = useNavigate();

  const { getTemplates, deleteTemplate, getTemplate, toggleFavoriteTemplate } = useTemplates();

  const [ templates, setTemplates ] = useState(null);
  const [ paginator, setPaginator ] = useState(null);
  const [ currentUrl, setCurrentUrl ] = useState(null);
  const [ isLoading, setIsLoading ] = useState(true);
  const [ hidden, setHidden ] = useState(true);
  const [ selectedSupplier, setSelectedSupplier ] = useState();
  const [ searchParams, setSearchParams ] = useSearchParams();

  const handleTemplatesFilter = (e) => {
    const { name, value } = e.target;

    const newParams = new URLSearchParams(searchParams)

    if(value !== ''){ 
      newParams.set(name, value);
      setHidden(false);
    } else {
      newParams.delete(name);
      setHidden(true);
    }

    setSearchParams(newParams);
  };

  const handleCleanFilters = () => {
    setSearchParams({});
    setHidden(true);
  };


  const toggleFavorite = async (id, { setErrors = () => {} } = {}) => {
    const data = await toggleFavoriteTemplate(id);

    if (data.success) {
      setTemplates(prevTemplates => {
        return prevTemplates.map(template =>
          template.id === id ? { ...template, is_favorite: !template.is_favorite } : template
        );
      });
    } else {
        if (data.errors) {
            setErrors(data.errors);
        } else {
            setErrors({
            global: 'Hubo un error en general.'
            
            });
        }
    }
  };

  const handleSelectChange = (e) => {
    setSelectedSupplier(e.target.value);
  };

  const handleEditTemplate = async (id) => {
    try {
      let res = await getTemplate(id);
      if (!res) {
        alert("Error getting the information of this address");
        return;
      }
      navigate(`/plantilla/${id}`);
    } catch (err) {
      console.error(err);
    }
  };

  const handleDeleteTemplate = async (id) => {
    const {success, message} = await deleteTemplate(id);
      if(success) {
        setTemplates(() => templates.filter((template) => id !== template.id));
      } else {
          alert(message);
      }
  };

  useEffect(() => {
    getTemplates(currentUrl, searchParams)
      .then(({ success, data, errors }) => {
        if (success) {
          setTemplates(data.data);
          setPaginator(data);
          setIsLoading(false);
        } else {
          alert(errors);
        }
      });
  }, [searchParams]);

  return (
    <>
      <Heading variant='title'>Mis Plantillas</Heading>
      <Divider my={2} />
      <HStack
        justifyContent='space-between'
      >
        <Button variant='primary' onClick={() => navigate('/plantilla')} >Agregar Plantilla</Button>
        <Menu closeOnSelect={false}>
          <HStack>
            <Button variant='secondary'>Exportar</Button>
            <MenuButton 
              hidden={hidden}
              onClick={handleCleanFilters}
            >
              <Box>
                <Button>
                  Limpiar Filtros
                </Button>
              </Box>
            </MenuButton>
            <MenuButton as={Button}>
              <p>Filtrar por <i class="bi bi-caret-down-fill"></i></p>
            </MenuButton>
          </HStack>
          <MenuList>
            {/* <MenuGroup title='Tipo de paquete'>
              <SimpleGrid columns={2}>
                <MenuItem><Checkbox defaultChecked>Paquetes</Checkbox></MenuItem>
                <MenuItem><Checkbox defaultChecked>Tarimas</Checkbox></MenuItem>
                <MenuItem><Checkbox defaultChecked>Huacales</Checkbox></MenuItem>
                <MenuItem><Checkbox defaultChecked>Sobre Dimensiones</Checkbox></MenuItem>
                <MenuItem><Checkbox defaultChecked>Sobres</Checkbox></MenuItem>
                <MenuItem><Checkbox defaultChecked>Bultos</Checkbox></MenuItem>
                <MenuItem><Checkbox defaultChecked>Contenedores</Checkbox></MenuItem>
              </SimpleGrid>
            </MenuGroup> */}
            {/* <MenuDivider /> */}
            <MenuGroup title='Fecha'>
              <MenuItem>
                <Box mr='5'>
                  <Text mb='8px'>Inicio </Text>
                  <Input
                    onClick={e => e.stopPropagation()}
                    placeholder="Select Date and Time"
                    value={searchParams?.start_date}
                    name='start_date'
                    onChange={handleTemplatesFilter}
                    size="md"
                    type="date"
                  />
                </Box>
                <Box>
                  <Text mb='8px'>Fin</Text>
                  <Input
                    onClick={e => e.stopPropagation()}
                    placeholder="Select Date and Time"
                    value={searchParams?.end_date}
                    name='end_date'
                    onChange={handleTemplatesFilter}
                    size="md"
                    type="date"
                  />
                </Box>
              </MenuItem>
            <MenuDivider />
            </MenuGroup>
            <MenuGroup title='Búsqueda'>
              <MenuItem>
                <Select
                  onClick={e => e.stopPropagation()}
                  onChange={handleSelectChange}
                  placeholder='Selecciona...'
                >
                  <option value='name'>Nombre</option>
                  <option value='sat_product_code'>Código Producto SAT</option>
                  <option value='content'>Contenido declarado</option>
                </Select>
              </MenuItem>
              <MenuItem>
                <Input
                  disabled={!selectedSupplier}
                  onClick={e => e.stopPropagation()}
                  placeholder="Filtrar..."
                  onChange={handleTemplatesFilter}
                  name={selectedSupplier}
                  mb={4}
                />
              </MenuItem>
            </MenuGroup>
          </MenuList>
        </Menu>
      </HStack>
      {
      !isLoading
        ?
      <Box mt={5}>
        <Table size='sm' variant='simple' overflowX='scroll'>
          <Thead>
            <Tr>
              <Th>Nombre plantilla</Th>
              <Th>Tipo de Paquete</Th>
              <Th>Código de producto SAT</Th>
              <Th>Contenido declarado</Th>
              <Th>Largo(CM)</Th>
              <Th>Alto(CM)</Th>
              <Th>Ancho(CM)</Th>
              <Th>Peso(Kg)</Th>
              <Th>Sellos de seguridad</Th>
              <Th>Estibable</Th>
              <Th>Acciones</Th>
            </Tr>
          </Thead>

          <Tbody>
            {
              !isLoading && templates.map((tp) => (
              <Tr key={tp.id}>
                <Td>{tp.name}</Td>
                <Td>{tp.package_type_data?.type}</Td>
                <Td>{tp.sat_product_code}</Td>
                <Td>{tp.content}</Td>
                <Td>{tp.length}</Td>
                <Td>{tp.height}</Td>
                <Td>{tp.width}</Td>
                <Td>{tp.weight}</Td>
                <Td>
                  {
                    tp.security_seals === true
                      ? 
                      (<i className="bi bi-check-circle" style={{ fontSize: '26px', color: 'green' }} />) 
                      :
                      (<i className="bi bi-x-circle" style={{ fontSize: '26px', color: 'red' }} />)
                  }
                </Td>
                <Td>
                  {
                    tp.is_stackable === true
                      ? 
                      (<i className="bi bi-check-circle" style={{ fontSize: '26px', color: 'green' }} />) 
                      :
                      (<i className="bi bi-x-circle" style={{ fontSize: '26px', color: 'red' }} />)
                  }
                </Td>
                <Td>
                  
                  <SimpleGrid columns={2}>
                    <Text 
                      onClick={() => toggleFavorite(tp.id)}
                      display='flex' 
                      alignItems='center' 
                      fontSize='24px' 
                      as='button'>
                      {
                        tp.is_favorite === true ? <Text color='orange.400'><i class="bi bi-star-fill"></i></Text> : <i class="bi bi-star"></i>
                      }
                    </Text>
                    <Menu>
                      <MenuButton as={Button}>
                        <i className='bi bi-three-dots-vertical'></i>
                      </MenuButton>
                      <MenuList>
                        <MenuItem onClick={() => handleEditTemplate(tp.id)} icon={<i className='bi bi-pencil-square' style={{ fontSize:'18px' }}></i>}>Editar</MenuItem>
                        <MenuItem onClick={() => handleDeleteTemplate(tp.id)} icon={<i className='bi bi-trash-fill' style={{ fontSize:'18px' }}></i>}>Eliminar</MenuItem>
                      </MenuList>
                    </Menu>
                  </SimpleGrid>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        {
          !isLoading && <Paginator setCurrentUrl={setCurrentUrl} paginator={paginator} />
        }
      </Box>
      :
      <Stack mt={10}>
        <Skeleton height='30px' />
        <Skeleton mt={3} height='30px' />
        <Skeleton mt={3} height='30px' />
        <Skeleton mt={3} height='30px' />
        <Skeleton mt={3} height='30px' />
        <Skeleton mt={3} height='30px' />
        <Skeleton mt={3} height='30px' />
      </Stack>
      }
    </>
  )
}

export default TemplatesPage