import React from 'react'
import { Formik, Form } from 'formik';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { Button, Flex, FormControl, FormErrorMessage, FormLabel, HStack, Input, InputGroup, InputLeftElement } from '@chakra-ui/react';

const SignUpForm = ({
    setCompleted
}) => {

    const handleNumericInput = (e, setFieldValue) => {
        const value = e.target.value;
        const numericValue = value.replace(/[^\d]/g, '');
        setFieldValue('telephone', numericValue);
    };

    return (
        <Formik
            initialValues={{
                name: '',
                last_name: '',
                company_name: '',
                vatnum: '',
                email: '',
                telephone: ''
            }}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={Yup.object({
                name: Yup.string().max(120).required('El campo nombre es obligatorio'),
                last_name: Yup.string().max(120).required('El campo apellido es obligatorio'),
                company_name: Yup.string().max(150).required('El campo razón social es obligatorio'),
                vatnum: Yup.string().required('El campo RFC es obligatorio'),
                email: Yup.string().email().required('El campo email es obligatorio'),
                telephone: Yup.string().matches(/^[0-9]+$/).min(10, 'Debe tener 10 caracteres').max(10, 'Debe tener 10 caracteres').required('El campo teléfono es obligatorio')
            })}
            onSubmit={(values, { setErrors }) => {
                fetch(`${process.env.REACT_APP_API_URL}/api/v1/create_user_request`, {
                    method: 'POST',
                    body: JSON.stringify(values),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then((res) => res.json())
                    .then((data) => {
                        console.log(data);
                        if (data.success) {
                            setCompleted(true);
                        } else {
                            if (data.errors) {
                                setErrors({
                                    name: data.errors.name,
                                    last_name: data.errors.last_name,
                                    company_name: data.errors.company_name,
                                    vatnum: data.errors.vatnum,
                                    telephone: data.errors.telephone,
                                    email: data.errors.email
                                });
                            } else {
                                setErrors({
                                    global: data.message
                                });
                            }
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }}
        >
            {({ handleSubmit, errors, touched, getFieldProps, setFieldValue }) => (
                <Form
                    style={{ width: '100%' }}
                    onSubmit={handleSubmit}
                >
                    <HStack>
                        <FormControl isInvalid={(errors.name && touched.name)}>
                            <FormLabel color='white'>Nombre</FormLabel>
                            <InputGroup>
                                <InputLeftElement>
                                    <i className='bi bi-person-fill' fontSize='20px'></i>
                                </InputLeftElement>
                                <Input type="text" bgColor='white' w='100%' {...getFieldProps('name')} />
                            </InputGroup>
                            <FormErrorMessage className='form-error-message'>
                                {errors.name}
                            </FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={(errors.last_name && touched.last_name)}>
                            <FormLabel color='white'>Apellido</FormLabel>
                            <InputGroup>
                                <InputLeftElement>
                                    <i className='bi bi-person-fill' fontSize='20px'></i>
                                </InputLeftElement>
                                <Input type="text" bgColor='white' w='100%' {...getFieldProps('last_name')} />
                            </InputGroup>
                            <FormErrorMessage className='form-error-message'>
                                {errors.last_name}
                            </FormErrorMessage>
                        </FormControl>
                    </HStack>
                    <FormControl mt={2} isInvalid={(errors.company_name && touched.company_name)}>
                        <FormLabel color='white'>Razón social</FormLabel>
                        <InputGroup>
                            <InputLeftElement>
                                <i className='bi bi-briefcase-fill' fontSize='20px'></i>
                            </InputLeftElement>
                            <Input type="text" bgColor='white' w='100%' {...getFieldProps('company_name')} />
                        </InputGroup>
                        <FormErrorMessage className='form-error-message'>
                            {errors.company_name}
                        </FormErrorMessage>
                    </FormControl>
                    <FormControl mt={2} isInvalid={(errors.vatnum && touched.vatnum)}>
                        <FormLabel color='white'>RFC</FormLabel>
                        <InputGroup>
                            <InputLeftElement>
                                <i className='bi bi-person-vcard-fill' fontSize='20px'></i>
                            </InputLeftElement>
                            <Input type="text" bgColor='white' w='100%' {...getFieldProps('vatnum')} maxLength={13} />
                        </InputGroup>
                        <FormErrorMessage className='form-error-message'>
                            {errors.vatnum}
                        </FormErrorMessage>
                    </FormControl>
                    <FormControl mt={2} isInvalid={(errors.email && touched.email)}>
                        <FormLabel color='white'>Correo electrónico</FormLabel>
                        <InputGroup>
                            <InputLeftElement>
                                <i className='bi bi-envelope-fill' fontSize='20px'></i>
                            </InputLeftElement>
                            <Input type="email" bgColor='white' w='100%' {...getFieldProps('email')} />
                        </InputGroup>
                        <FormErrorMessage className='form-error-message'>
                            {errors.email}
                        </FormErrorMessage>
                    </FormControl>
                    <FormControl mt={2} isInvalid={(errors.telephone && touched.telephone)}>
                        <FormLabel color='white'>Teléfono</FormLabel>
                        <InputGroup>
                            <InputLeftElement>
                                <i className='bi bi-telephone-fill' fontSize='20px'></i>
                            </InputLeftElement>
                            <Input type="text" bgColor='white' w='100%' {...getFieldProps('telephone')} maxLength={10} onChange={(e) => handleNumericInput(e, setFieldValue)} />
                        </InputGroup>
                        <FormErrorMessage className='form-error-message'>
                            {errors.telephone}
                        </FormErrorMessage>
                    </FormControl>
                    <Flex flexDir={['column', 'column', 'row', 'row']} w='100%' mt={2}>
                        <Button type='submit' variant='primary' w={['100%', '100%', 'auto', 'auto']} mr={[0, 0, 2, 2]}>Solicitar alta</Button>
                        <Link to='/ingresar'>
                            <Button variant='tertiary' w={['100%', '100%', 'auto', 'auto']} mt={[2, 2, 0, 0]}>Regresar</Button>
                        </Link>
                    </Flex>
                </Form>
            )}
        </Formik>
    )
}

export default SignUpForm