import { Box, Button, Flex, Heading, Spacer, Stack, VStack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import CommercialReferences from '../../../components/credit_requests/third_step/CommercialReferences';
import Executives from '../../../components/credit_requests/third_step/Executives';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setStep, syncCreditRequestInfo } from '../../../redux/slices/creditRequestSlice';


const CRThirdStep = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [executives, setExecutives] = useState([]);
    const [commercialReferences, setCommercialReferences] = useState([]);
    const [errors, setErrors] = useState({});

    const handleNextStep = () => {
        if(executives?.length >= 2 && commercialReferences?.length >= 2) {
            dispatch(syncCreditRequestInfo());
            navigate('/solicitudes-de-credito/documentacion');
        } else {
            setErrors({
                ...(executives?.length < 3) && {executives: 'Necesitas tener minimo 3 ejecutivos.'},
                ...(commercialReferences?.length < 3) && {commercialReferences: 'Necesitas tener minimo 3 referencias comerciales.'}
            });
        }
    }

    const handleBack = () => {
        navigate('/solicitudes-de-credito/procedimiento-de-pago');
        setStep(1);
    }

    useEffect(() => {
        dispatch(setStep(2));
    }, [dispatch]);

    return (
        <div>
            <Heading variant='title' mb={3}>Información del cliente</Heading>
            <Stack direction={['column', 'column', 'row', 'row']} gap={5}>
                <VStack alignItems='left' w='full'>
                    { errors.commercialReferences && <Box className='form-error-message'>{errors.commercialReferences}</Box> }
                    <CommercialReferences commercialReferences={commercialReferences} setCommercialReferences={setCommercialReferences} />
                </VStack>
                <VStack alignItems='left' w='full'>
                    { errors.executives && <Box className='form-error-message'>{errors.executives}</Box> }
                    <Executives setExecutives={setExecutives} executives={executives} />
                </VStack>
            </Stack>
            <Flex mt={5} flexDir={['column-reverse', 'row', 'row', 'row']}>
                <Button onClick={handleBack} variant='secondary'>Regresar</Button>
                <Spacer my={1} />
                <Button type='submit' variant='primary' onClick={handleNextStep}>Continuar</Button>
            </Flex>
        </div>
    )
}

export default CRThirdStep