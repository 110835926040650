import { Button, Divider, Grid, GridItem, Stack, Heading, FormControl, FormErrorMessage, Box, Show } from '@chakra-ui/react'
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ShowOrDeleteFile from '../../../components/credit_requests/fourth_step/ShowOrDeleteFile';
import { setEditMode, setStep, syncCreditRequestInfo } from '../../../redux/slices/creditRequestSlice';
import { useNavigate } from 'react-router-dom';

const CRFourthStep = () => {

    const { token } = useSelector((state) => state.user.data);
    const { documentation } = useSelector((state) => state.credit_request.info);
    const { credit_id } = useSelector((state) => state.credit_request);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleChangeRef = (event, input_name, setFieldValue) => {
        const label = event.target.parentElement;
        const span = label.getElementsByTagName('span')[0];

        if (event.target.files[0] && event.target.files[0].size > 0) {
            const file_name = event.target.files[0].name;
            span.textContent = file_name;

            setFieldValue(input_name, event.target.files[0]);
        } else {
            span.textContent = 'Selecciona tu archivo.';
        }
    }

    const handleBack = () => {
        navigate('/solicitudes-de-credito/informacion-del-cliente');
        setStep(2);
    }

    useEffect(() => {
        dispatch(setStep(3));
    }, []);


    return (
        <div>
            <Heading variant='title'>Documentación</Heading>
            <p>Toda documentación enviada, favor de enviarla en archivos con formato PDF.</p>
            <Divider my={5} />
            <Formik
                initialValues={{
                    csf: null,
                    ine: null,
                    constitutional_act: null,
                    account_status: null,
                    address_comprobant: null,
                    representative_power: null,
                }}
                validationSchema={Yup.object({
                    csf: Yup.mixed().notRequired(),
                    ine: Yup.mixed().notRequired(),
                    constitutional_act: Yup.mixed().notRequired(),
                    account_status: Yup.mixed().notRequired(),
                    address_comprobant: Yup.mixed().notRequired(),
                    representative_power: Yup.mixed().notRequired(),
                })}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={async (values, { setErrors }) => {
                    const body = new FormData();
                    if(values.csf) body.append('csf', values.csf);
                    if(values.ine) body.append('ine', values.ine);
                    if(values.constitutional_act) body.append('constitutional_act', values.constitutional_act);
                    if(values.account_status) body.append('account_status', values.account_status);
                    if(values.address_comprobant) body.append('address_comprobant', values.address_comprobant);
                    if(values.representative_power) body.append('representative_power', values.representative_power);

                    const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/credit_requests/set_documentation/${credit_id}`, {
                        method: 'POST',
                        body,
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });

                    if (res.status === 200) {
                        const data = await res.json();
                        if (data.success) {
                            await dispatch(syncCreditRequestInfo());
                            navigate('/solicitudes-de-credito/resumen');
                        } else {
                            setErrors(data.errors);
                            console.log(data.errors);
                        }
                    } else {
                        // Mostrar error con SweetAlert
                    }
                }}
            >
                {({ setFieldValue, errors }) => (
                    <Form>
                        <Grid templateColumns={['repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(2, 1fr)']} gap={5}>
                            <GridItem>
                                {
                                    (!documentation?.csf_filename)
                                        ?
                                        <FormControl isInvalid={errors.csf} style={{ width: '100%' }}>
                                            <Stack direction={['column', 'column', 'column', 'row']} justifyContent='space-between' alignItems='center'>
                                                <span style={{ fontWeight: 'bold', marginRight: '5px', width: '100%' }}>Constancia de situación fiscal</span>
                                                <label for='csf' className='file-uploader'>
                                                    <i className='bi bi-upload'></i> <span>Selecciona tu archivo.</span>
                                                    <input id='csf' type='file' name='csf' style={{ display: 'none' }} onChange={e => handleChangeRef(e, 'csf', setFieldValue)} />
                                                    <FormErrorMessage className='form-error-message'>
                                                        {errors.csf}
                                                    </FormErrorMessage>
                                                </label>
                                            </Stack>
                                        </FormControl>
                                        :
                                        <ShowOrDeleteFile title='Constancia de situación fiscal' filePath={documentation.csf_filename} typeFile='csf_filename' />
                                }
                            </GridItem>
                            <GridItem>
                                {
                                    (!documentation?.ine_filename)
                                        ?
                                        <FormControl isInvalid={errors.ine}>
                                            <Stack direction={['column', 'column', 'column', 'row']} justifyContent='space-between'>
                                                <span style={{ fontWeight: 'bold', marginRight: '5px', width: '100%' }}>INE (Frontal y reversa)</span>
                                                <label for='ine' className='file-uploader'>
                                                    <i className='bi bi-upload'></i> <span>Selecciona tu archivo.</span>
                                                    <input id='ine' type='file' style={{ display: 'none' }} onChange={e => handleChangeRef(e, 'ine', setFieldValue)} />
                                                    <FormErrorMessage className='form-error-message'>
                                                        {errors.ine}
                                                    </FormErrorMessage>
                                                </label>
                                            </Stack>
                                        </FormControl>
                                        :
                                        <ShowOrDeleteFile title='INE (Frontal y reversa)' filePath={documentation.ine_filename} typeFile='ine_filename' />
                                }
                            </GridItem>
                            <GridItem>
                                {
                                    (!documentation?.constitutional_act_filename)
                                        ?
                                        <FormControl isInvalid={errors.constitutional_act}>
                                            <Stack direction={['column', 'column', 'column', 'row']} justifyContent='space-between'>
                                                <span style={{ fontWeight: 'bold', marginRight: '5px', width: '100%' }}>Acta constitucional</span>
                                                <label for='constitutional_act' className='file-uploader'>
                                                    <i className='bi bi-upload'></i> <span>Selecciona tu archivo.</span>
                                                    <input id='constitutional_act' type='file' style={{ display: 'none' }} onChange={e => handleChangeRef(e, 'constitutional_act', setFieldValue)} />
                                                    <FormErrorMessage className='form-error-message'>
                                                        {errors.constitutional_act}
                                                    </FormErrorMessage>
                                                </label>
                                            </Stack>
                                        </FormControl>
                                        : <ShowOrDeleteFile title='Acta constitucional' filePath={documentation.constitutional_act_filename} typeFile='constitutional_act_filename' />
                                }
                            </GridItem>
                            <GridItem>
                                {
                                    (!documentation?.account_status_filename)
                                        ?
                                        <FormControl isInvalid={errors.account_status}>
                                            <Stack direction={['column', 'column', 'column', 'row']} justifyContent='space-between'>
                                                <span style={{ fontWeight: 'bold', marginRight: '5px', width: '100%' }}>Caratula de estado de cuenta</span>
                                                <label for='account_status' className='file-uploader'>
                                                    <i className='bi bi-upload'></i> <span>Selecciona tu archivo.</span>
                                                    <input id='account_status' type='file' style={{ display: 'none' }} onChange={e => handleChangeRef(e, 'account_status', setFieldValue)} />
                                                    <FormErrorMessage className='form-error-message'>
                                                        {errors.account_status}
                                                    </FormErrorMessage>
                                                </label>
                                            </Stack>
                                        </FormControl>
                                        : <ShowOrDeleteFile title='Caratula de estado de cuenta' filePath={documentation.account_status_filename} typeFile='account_status_filename' />
                                }
                            </GridItem>
                            <GridItem>
                                {
                                    (!documentation?.address_comprobant_filename)
                                        ?
                                        <FormControl isInvalid={errors.address_comprobant}>
                                            <Stack direction={['column', 'column', 'column', 'row']} justifyContent='space-between'>
                                                <span style={{ fontWeight: 'bold', marginRight: '5px', width: '100%' }}>Comprobante de domicilio</span>
                                                <label for='address_comprobant' className='file-uploader'>
                                                    <i className='bi bi-upload'></i> <span>Selecciona tu archivo.</span>
                                                    <input id='address_comprobant' type='file' style={{ display: 'none' }} onChange={e => handleChangeRef(e, 'address_comprobant', setFieldValue)} />
                                                    <FormErrorMessage className='form-error-message'>
                                                        {errors.address_comprobant}
                                                    </FormErrorMessage>
                                                </label>
                                            </Stack>
                                        </FormControl>
                                        : <ShowOrDeleteFile title='Comprobante de domicilio' filePath={documentation.address_comprobant_filename} typeFile='address_comprobant_filename' />
                                }
                            </GridItem>
                            <GridItem>
                                {
                                    (!documentation?.representative_power_filename)
                                        ?
                                        <FormControl isInvalid={errors.representative_power}>
                                            <Stack direction={['column', 'column', 'column', 'row']} justifyContent='space-between'>
                                                <span style={{ fontWeight: 'bold', marginRight: '5px', width: '100%' }}>Poder de representante</span>
                                                <label for='representative_power' className='file-uploader'>
                                                    <i className='bi bi-upload'></i> <span>Selecciona tu archivo.</span>
                                                    <input id='representative_power' type='file' style={{ display: 'none' }} onChange={e => handleChangeRef(e, 'representative_power', setFieldValue)} />
                                                    <FormErrorMessage className='form-error-message'>
                                                        {errors.representative_power}
                                                    </FormErrorMessage>
                                                </label>
                                            </Stack>
                                        </FormControl>
                                        : <ShowOrDeleteFile title='Poder de representante' filePath={documentation.representative_power_filename} typeFile='representative_power_filename' />
                                }
                            </GridItem>
                        </Grid>
                        <Stack direction={['reverse-column', 'reverse-column', 'row', 'row']} mt={5} justifyContent='space-between'>
                            <Button variant='secondary' onClick={handleBack}>Regresar</Button>
                            <Button type='submit' variant='primary'>Continuar</Button>
                        </Stack>

                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default CRFourthStep