import { createSlice } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";

const initialState = {
    step: 0,
    credit_id: null,
    info: {},
    edit: false
}

export const creditRequestSlice = createSlice({
    name: 'credit_request',
    initialState,
    reducers: {
        create: (state, action) => ({
            ...state,
            step: 0,
            credit_id: action.payload.credit_id
        }),
        setStep: (state, action) => ({
            ...state,
            step: action.payload
        }),
        setCreditRequestInfo: (state, action) => ({
            ...state,
            credit_id: action.payload.credit_id,
            info: {
                ...action.payload.info
            },
            edit: true
        }),
        syncInfo: (state, action) => ({
            ...state,
            info: action.payload
        })
    },
    extraReducers: (builder) => {
        builder.addCase(PURGE, () => {
            return initialState;
        });
    }
});

export const setEditMode = (credit_id, step = 0) => {

    return async (dispatch, getState) => {
        const state = getState();
        const { token } = state.user.data;

        const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/credit_requests/${credit_id}`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        const { data } = await res.json();

        dispatch(creditRequestSlice.actions.setCreditRequestInfo({
            step,
            credit_id, 
            info: data 
        }));
    }
}

export const syncCreditRequestInfo = () => {
    return async (dispatch, getState) => {
        const state = getState();
        const { token } = state.user.data;
        const { step, credit_id } = state.credit_request

        const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/credit_requests/${credit_id}`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        const { data } = await res.json();

        dispatch(creditRequestSlice.actions.syncInfo(data));
    }
}


export const deleteDocument = (credit_id, type) => {
    return async (dispatch, getState) => {
        const state = getState();
        const { token } = state.user.data;
        const info = state.credit_request.info;

        const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/credit_requests/delete_documentation/${credit_id}`, {
            method: 'DELETE',
            body: JSON.stringify({
                type
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        const { success } = await res.json();

        dispatch(creditRequestSlice.actions.setCreditRequestInfo({
            credit_id,
            info: {
                ...info,
                documentation: {
                    ...info.documentation,
                    [type]: null
                }
            }
        }));
    }
}

export const { create, setStep, setCreditRequestInfo } = creditRequestSlice.actions;
export const creditRequestReducer = creditRequestSlice.reducer;