import React from 'react'

const useSepomex = () => {

    const getDataFromPostalCode = async (postal_code) => {
        try {
            const res = await fetch(`https://rexgo.mx/api/get_data_postal_code/${postal_code}`);

            if (res.status == 200) {
                const data = await res.json();
                return data;
            } else {
                return 0;
            }
        } catch (err) {
            return 0;
        }

    }

    return {
        getDataFromPostalCode
    }
}

export default useSepomex