import { useSelector } from 'react-redux';

export const useNotification = () => {

    const base_url = `${process.env.REACT_APP_API_URL}/api/v1/notification`;

    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }

    const { token } = useSelector((state) => state.user.data);

    const getNotifications = async () => {
        const res = await fetch(`${base_url}`, {
            method: 'GET',
            headers: {
                ...headers,
                'Authorization': `Bearer ${token}`
            }
        });

        const { data } = await res.json();
        return data;
    }

    const createNotification = async (id) => {
        const res = await fetch(`${base_url}`, {
            method: 'POST',
            body: JSON.stringify(id),
            headers: {
                ...headers,
                'Authorization': `Bearer ${token}`
            }
        });

        if(res.status === 200)  {
            const data = await res.json();
            return data;
        } else { 
            return 0;
        }
    }

    const getNotificationTypes = async () => {
        const res = await fetch(`${base_url}/types`, {
            method: 'GET',
            headers: {
                ...headers,
                'Authorization': `Bearer ${token}`
            }
        });

        const {data} = await res.json();
        return data;
    }

    const getConfig = async () => {
        const res = await fetch(`${base_url}/config`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
            
        const { data } = await res.json();
        return data;
    }

    const modifyConfig = async (values) => {
        const res = await fetch(`${base_url}/config`, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                ...headers,
                'Authorization': `Bearer ${token}`
            }
        });

        if(res.status === 200)  {
            const data = await res.json();
            return data;
        } else { 
            return 0;
        }
    }

    const setAllAsSeen = async () => {
        const res = await fetch(`${base_url}/set_all_as_seen`, {
            method: 'POST',
            headers: {
                ...headers,
                'Authorization': `Bearer ${token}`
            }
        })

        return await res.json();
    }
  
  return {
    createNotification,
    getNotifications,
    getNotificationTypes,
    getConfig,
    modifyConfig,
    setAllAsSeen
  };
}
