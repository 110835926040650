import { Badge, Box, Button, Divider, HStack, Heading, Menu, MenuButton, MenuDivider, MenuGroup, MenuItem, MenuList, SimpleGrid, Skeleton, Stack, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const orders = [
    {
      id: 1,
      guia: 'G000023',
      date: '07/02/2001',
      origen: 'Rex',
      destino: 'alex',
      total: 2,
      volumen: {
        declarado: 0.53,
        real: 'NA'
      },
      peso: {
        declarado: 32.0,
        real: 'NA'
      },
      status: 'pendiente'
    },
    {
      id: 2,
      guia: 'G000025',
      date: '23/06/2006',
      origen: 'RED',
      destino: 'Juan',
      total: 6,
      volumen: {
        declarado: 13,
        real: 'NA'
      },
      peso: {
        declarado: 40,
        real: 'NA'
      },
      status: 'aceptado'
    },
]

const OrdersPage = () => {

  const [ isLoading, setIsLoading ] = useState(false);
  const user = useSelector((state) => state.user.data);
  const navigate = useNavigate();

  const handleEditOrders = () => {
    console.log('Hola');
  }

  const handleDeleteOrders = () => {
    console.log('Adios');
  }
  
  return (
    <>
      <Heading variant='title'>Bienvenido, {user.name} {user.last_name}</Heading>
      <Divider my={2} />
      <HStack
        justifyContent='space-between'
      >
        <Button onClick={() => navigate('/orden')} variant='primary'>Generar guía</Button>
        <HStack>
          <Button variant='secondary'>Exportar</Button>
          <Menu closeOnSelect={false}>
            <MenuButton as={Button}>
              <p>Filtrar por <i class="bi bi-caret-down-fill"></i></p>
            </MenuButton>
            <MenuList>
              {/* <MenuGroup title='Tipo de paquete'>
                <SimpleGrid columns={2}>
                  <MenuItem><Checkbox defaultChecked>Paquetes</Checkbox></MenuItem>
                  <MenuItem><Checkbox defaultChecked>Tarimas</Checkbox></MenuItem>
                  <MenuItem><Checkbox defaultChecked>Huacales</Checkbox></MenuItem>
                  <MenuItem><Checkbox defaultChecked>Sobre Dimensiones</Checkbox></MenuItem>
                  <MenuItem><Checkbox defaultChecked>Sobres</Checkbox></MenuItem>
                  <MenuItem><Checkbox defaultChecked>Bultos</Checkbox></MenuItem>
                  <MenuItem><Checkbox defaultChecked>Contenedores</Checkbox></MenuItem>
                </SimpleGrid>
              </MenuGroup> */}
              {/* <MenuDivider /> */}
              <MenuGroup title='Fecha'>
                {/* <MenuItem>
                  <Box mr='5'>
                    <Text mb='8px'>Inicio </Text>
                    <Input
                      onClick={e => e.stopPropagation()}
                      placeholder="Select Date and Time"
                      value={filters?.start_date}
                      name='start_date'
                      onChange={handleTemplatesFilter}
                      size="md"
                      type="date"
                    />
                  </Box>
                  <Box>
                    <Text mb='8px'>Fin</Text>
                    <Input
                      onClick={e => e.stopPropagation()}
                      placeholder="Select Date and Time"
                      value={filters?.end_date}
                      name='end_date'
                      onChange={handleTemplatesFilter}
                      size="md"
                      type="date"
                    />
                  </Box>
                </MenuItem> */}
              <MenuDivider />
              </MenuGroup>
              <MenuGroup title='Búsqueda'>
                {/* <MenuItem>
                  <Select
                    onClick={e => e.stopPropagation()}
                    onChange={handleSelectChange}
                    placeholder='Selecciona...'
                  >
                    <option value='name'>Nombre</option>
                    <option value='sat_product_code'>Código Producto SAT</option>
                    <option value='content'>Contenido declarado</option>
                  </Select>
                </MenuItem>
                <MenuItem>
                  <Input
                    disabled={!selectedSupplier}
                    onClick={e => e.stopPropagation()}
                    placeholder="Filtrar..."
                    onChange={handleTemplatesFilter}
                    name={selectedSupplier}
                    mb={4}
                  />
                </MenuItem> */}
              </MenuGroup>
            </MenuList>
          </Menu>
        </HStack>
      </HStack>
      {
      !isLoading
        ?
      <Box mt={5}>
        <Table size='sm' variant='simple' overflowX='scroll'>
          <Thead>
            <Tr>
              <Th>No. Preguía</Th>
              <Th>Fecha de creación</Th>
              <Th>Origen</Th>
              <Th>Destino</Th>
              <Th>Total de paquetes</Th>
              <Th>Declarado</Th>
              <Th>Real</Th>
              <Th>Declarado</Th>
              <Th>Real</Th>
              <Th>Estatus</Th>
              <Th>Acciones</Th>
            </Tr>
          </Thead>

          <Tbody>
            {
              !isLoading && orders.map((orders) => (
              <Tr key={orders.id}>
                <Td>{orders.guia}</Td>
                <Td>{orders.date}</Td>
                <Td>{orders.origen}</Td>
                <Td>{orders.destino}</Td>
                <Td>{orders.total}</Td>
                <Td>{orders.volumen.declarado}</Td>
                <Td>{orders.volumen.real}</Td>
                <Td>{orders.peso.declarado}</Td>
                <Td>{orders.peso.real}</Td>
                <Td>
                  <Badge rounded='15px' py={1} px={3} colorScheme={(orders.status === 'pendiente') ? 'gray' : 'green'}>{orders.status}</Badge>
                </Td>
                <Td>
                  <SimpleGrid columns={2}>
                    <Menu>
                      <MenuButton as={Button}>
                        <i className='bi bi-three-dots-vertical'></i>
                      </MenuButton>
                      <MenuList>
                        <MenuItem onClick={() => handleEditOrders(orders.id)} icon={<i className='bi bi-pencil-square' style={{ fontSize:'18px' }}></i>}>Editar</MenuItem>
                        <MenuItem onClick={() => handleDeleteOrders(orders.id)} icon={<i className='bi bi-trash-fill' style={{ fontSize:'18px' }}></i>}>Eliminar</MenuItem>
                      </MenuList>
                    </Menu>
                  </SimpleGrid>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
      :
      <Stack mt={10}>
        <Skeleton height='30px' />
        <Skeleton mt={3} height='30px' />
        <Skeleton mt={3} height='30px' />
        <Skeleton mt={3} height='30px' />
        <Skeleton mt={3} height='30px' />
        <Skeleton mt={3} height='30px' />
        <Skeleton mt={3} height='30px' />
      </Stack>
      }
    </>
  )
}

export default OrdersPage