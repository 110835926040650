import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Box, Divider, Heading } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import FiscalAddressForm from '../../../components/credit_requests/first_step/FiscalAddressForm'
import InvoiceDataForm from '../../../components/credit_requests/first_step/InvoiceDataForm';
import CreditInformationForm from '../../../components/credit_requests/first_step/CreditInformationForm';
import { useDispatch } from 'react-redux';
import { setStep } from '../../../redux/slices/creditRequestSlice';
import { useSearchParams } from 'react-router-dom';

const CRFirstStep = () => {

  const [accordionIndex, setAccordionIndex] = useState(0);
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const steps = {
    fiscal_data: 0,
    credit_info: 1,
    invoice_data: 2
  }

  useEffect(() => {
    const step_name = searchParams.get("step") || 'fiscal_data';

    setAccordionIndex(steps[step_name]);
    dispatch(setStep(0));
  }, [dispatch]);

  return (
    <div>
      <Heading variant='title' mb={2}>Datos generales</Heading>
      <Accordion index={[accordionIndex]}>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex='1' textAlign='left'>
                <Heading size='md'>Datos Fiscales</Heading>
              </Box>
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <FiscalAddressForm nextStep={setAccordionIndex} currentStep={accordionIndex} />
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex='1' textAlign='left'>
                <Heading size='md'>Información del crédito</Heading>
              </Box>
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <CreditInformationForm nextStep={setAccordionIndex} currentStep={accordionIndex} />
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex='1' textAlign='left'>
                <Heading size='md'>Datos de facturación</Heading>
              </Box>
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <InvoiceDataForm nextStep={setAccordionIndex} currentStep={accordionIndex} />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </div>
  )
}

export default CRFirstStep