import React from 'react'
import { useSelector } from 'react-redux';

const usePayment = () => {

    const base_url = `${process.env.REACT_APP_API_URL}/api/v1/payments`;
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
    const { token } = useSelector((state) => state.user.data);

    const cardPayment = async (payment_data) => {
        const res = await fetch(`${base_url}/invoice/card`, {
            body: JSON.stringify(payment_data),
            method: 'POST',
            headers: {
                ...headers,
                'Authorization': `Bearer ${token}`
            }
        });

        const data = await res.json();

        return data;
    }

    const referencePayment = async (payment_data) => {
        const res = await fetch(`${base_url}/invoice/reference`, {
            body: JSON.stringify(payment_data),
            method: 'POST',
            headers: {
                ...headers,
                'Authorization': `Bearer ${token}`
            }
        });

        const data = await res.json();
        return data;
    }

    return {
        cardPayment,
        referencePayment
    }

}

export default usePayment