import { Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay } from '@chakra-ui/react'
import React from 'react'
import NavLink, { NavLinkDropdown } from '../menu/NavLinked'
import LinkList from '../menu/LinkList'

const DrawerMenu = ({
    isOpen,
    onClose,
}) => {
    return (
        <Drawer
            isOpen={isOpen}
            onClose={onClose}
            placement='left'
        >
            <DrawerOverlay />
            <DrawerContent backgroundColor='primary.900'>
                <DrawerCloseButton color='white' />
                <DrawerHeader color='white'>
                    Menú
                </DrawerHeader>
                <DrawerBody>
                    <LinkList mobile />
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    )
}

export default DrawerMenu