import { Container, HStack } from '@chakra-ui/react'
import React from 'react'
import Header from '../../components/partials/Header'
import SideBarMenu from '../../components/menu/SideBarMenu'

const PublicLayout = ({ children }) => {
  return (
    <div>
      <Header />
      <HStack
        alignItems='start'
      >
        <SideBarMenu />
        <Container
          maxWidth='100%'
          maxH='calc(100vh - 75px)'
          marginY='5px'
          mr='5px'
          overflow='scroll'
          borderWidth={1}
          borderColor='gray.200'
          borderRadius={5}
          py={5}
          css={{
            '&::-webkit-scrollbar': {
              width: '2px',
              height: '4px'
            },
            '&::-webkit-scrollbar-track': {
              width: '2px',
              height: '4px'
            },
            '&::-webkit-scrollbar-thumb': {
              background: 'red',
              borderRadius: '24px',
            },
          }}
        >
          {children}
        </Container>
      </HStack>
    </div>
  )
}

export default PublicLayout