import { Button, Card, Checkbox, Divider, Flex, FormControl, FormErrorMessage, FormLabel, Grid, GridItem, Heading, Input, Menu, MenuButton, MenuItem, MenuList, Select, SimpleGrid, Spacer } from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import React from 'react'
import { useNavigate } from 'react-router-dom'

export const FreightForm = () => {

  const navigate = useNavigate();

  return (
    <>
      <Heading variant='title' mb={5}>Cargas</Heading>
      <Formik
        initialValues={{
          cantidad: '',
          flete: '',
          contenido: '',
          descripcion: '',
          largo: '',
          ancho: '',
          alto: '',
          peso: '',
          volumen: '',
          sellos: '',
          estibable: '',
        }}

        onSubmit={(values, {setErrors}) => {
          console.log(values);
          navigate('/orden/resumen')
      }}
      >
        {({values, getFieldProps, errors}) => (
          <Form>
            <Grid templateColumns='repeat(2, 1fr)' alignItems='end'>
              <GridItem colSpan={1} mr={4}>
                <FormControl>
                    <FormLabel>Mis plantillas</FormLabel>
                    <Select>
                        <option value='option1'>Option 1</option>
                        <option value='option2'>Option 2</option>
                        <option value='option3'>Option 3</option>
                    </Select>
                    <FormErrorMessage className='form-error-message'>
                    </FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem colSpan={1}>
                <Button>Agregar</Button>
              </GridItem>
            </Grid>
            <Divider my={4} />
            <Grid templateColumns='repeat(12, 1fr)' gap={4} alignItems='end'>
              <FormControl isRequired>
                  <FormLabel fontSize='14px'>Cantidad</FormLabel>
                    <Input type='text' {...getFieldProps('cantidad')} />
                    <FormErrorMessage className='form-error-message'>
                  </FormErrorMessage>
              </FormControl>
              <FormControl isRequired>
                  <FormLabel fontSize='14px'>Flete</FormLabel>
                    <Input type='text' {...getFieldProps('flete')} />
                    <FormErrorMessage className='form-error-message'>
                  </FormErrorMessage>
              </FormControl>
              <FormControl isRequired>
                  <FormLabel fontSize='14px'>Contenido</FormLabel>
                    <Input type='text' {...getFieldProps('contenido')} />
                    <FormErrorMessage className='form-error-message'>
                  </FormErrorMessage>
              </FormControl>
              <FormControl isRequired>
                  <FormLabel fontSize='14px'>Descripción</FormLabel>
                    <Input type='text' {...getFieldProps('descripcion')} />
                    <FormErrorMessage className='form-error-message'>
                  </FormErrorMessage>
              </FormControl>
              <FormControl isRequired>
                  <FormLabel fontSize='14px'>Largo (cm)</FormLabel>
                    <Input type='text' {...getFieldProps('largo')} />
                    <FormErrorMessage className='form-error-message'>
                  </FormErrorMessage>
              </FormControl>
              <FormControl isRequired>
                  <FormLabel fontSize='14px'>Ancho (cm)</FormLabel>
                    <Input type='text' {...getFieldProps('ancho')} />
                    <FormErrorMessage className='form-error-message'>
                  </FormErrorMessage>
              </FormControl>
              <FormControl isRequired>
                  <FormLabel fontSize='14px'>Alto (cm)</FormLabel>
                    <Input type='text' {...getFieldProps('alto')} />
                    <FormErrorMessage className='form-error-message'>
                  </FormErrorMessage>
              </FormControl>
              <FormControl isRequired>
                  <FormLabel fontSize='14px'>Peso (kg) unitario</FormLabel>
                    <Input type='text' {...getFieldProps('peso')} />
                    <FormErrorMessage className='form-error-message'>
                  </FormErrorMessage>
              </FormControl>
              <FormControl isRequired>
                  <FormLabel fontSize='14px'>Volumen unitario</FormLabel>
                    <Input type='text' {...getFieldProps('volumen')} />
                    <FormErrorMessage className='form-error-message'>
                  </FormErrorMessage>
              </FormControl>
              <FormControl isRequired>
                  <FormLabel fontSize='14px'>Sellos de seguridad</FormLabel>
                    <Input type='text' {...getFieldProps('sellos')} />
                    <FormErrorMessage className='form-error-message'>
                  </FormErrorMessage>
              </FormControl>
              <FormControl isRequired>
                  <FormLabel fontSize='14px'>No estibable</FormLabel>
                    <Input type='text' {...getFieldProps('estibable')} />
                    <FormErrorMessage className='form-error-message'>
                  </FormErrorMessage>
              </FormControl>
              <SimpleGrid columns={2}>
                <Menu>
                  <MenuButton as={Button}>
                    <i className='bi bi-three-dots-vertical'></i>
                  </MenuButton>
                  <MenuList>
                    <MenuItem icon={<i className='bi bi-pencil-square' style={{ fontSize:'18px' }}></i>}>Editar</MenuItem>
                    <MenuItem icon={<i className='bi bi-trash-fill' style={{ fontSize:'18px' }}></i>}>Eliminar</MenuItem>
                  </MenuList>
                </Menu>
              </SimpleGrid>
            </Grid>
            <Grid templateColumns='repeat(4, 1fr)' templateRows='repeat(2, 1fr)' gap={4} mt={5}>
              <GridItem colSpan={1}>
                <Card p={4} h='100%'>
                  <FormControl>
                      <FormLabel>Flete</FormLabel>
                      <Select>
                          <option value='option1'>Option 1</option>
                          <option value='option2'>Option 2</option>
                          <option value='option3'>Option 3</option>
                      </Select>
                      <FormErrorMessage className='form-error-message'>
                      </FormErrorMessage>
                  </FormControl>
                </Card>
              </GridItem>
              <GridItem colSpan={1}>
                <Card p={4} h='100%'>
                  <FormControl>
                      <FormLabel>Tipo de entrega</FormLabel>
                      <Select>
                          <option value='option1'>Option 1</option>
                          <option value='option2'>Option 2</option>
                          <option value='option3'>Option 3</option>
                      </Select>
                      <FormErrorMessage className='form-error-message'>
                      </FormErrorMessage>
                  </FormControl>
                </Card>
              </GridItem>
              <GridItem colSpan={1} rowSpan={2}>
                <Card p={4} h='100%'>
                  <FormControl display='flex' flexDirection='column'>
                      <FormLabel>Aseguramiento</FormLabel>
                      <Checkbox>No utilizar seguro</Checkbox>
                      <Checkbox>Cobertura mínima</Checkbox>
                      <Checkbox>Cobertura de 1%</Checkbox>
                      <Checkbox>Seguro propio</Checkbox>
                      <FormErrorMessage className='form-error-message'>
                      </FormErrorMessage>
                  </FormControl>
                </Card>
              </GridItem>
              <GridItem colSpan={1} rowSpan={2}>
                <Card p={4} h='100%'>
                  <FormControl display='flex' flexDirection='column'>
                      <FormLabel>Cita programada</FormLabel>
                      <Checkbox>Utilizar cita programada</Checkbox>
                      <FormErrorMessage className='form-error-message'>
                      </FormErrorMessage>
                  </FormControl>
                </Card>
              </GridItem>
              <GridItem colSpan={1}>
                <Card p={4} h='100%'>
                  <FormControl display='flex' flexDirection='column'>
                      <FormLabel>Retorno de evidencias</FormLabel>
                      <Checkbox>No requiero el servicio</Checkbox>
                      <Checkbox>Remisión</Checkbox>
                      <Checkbox>Orden de entrada a almacén</Checkbox>
                      <Checkbox>Orden de compra</Checkbox>
                      <Checkbox>Packing List</Checkbox>
                      <Checkbox>Acuse de recibo</Checkbox>
                      <Checkbox>Factura</Checkbox>
                      <Checkbox>Hoja de control</Checkbox>
                      <FormErrorMessage className='form-error-message'>
                      </FormErrorMessage>
                  </FormControl>
                </Card>
              </GridItem>
              <GridItem colSpan={1} >
                <Card p={4} h='100%'>
                  <FormControl display='flex' flexDirection='column'>
                      <FormLabel>Servicios</FormLabel>
                      <Checkbox>No requiero el servicio</Checkbox>
                      <Checkbox>Maniobra de carga</Checkbox>
                      <Checkbox>Maniobra de descarga</Checkbox>
                      <FormErrorMessage className='form-error-message'>
                      </FormErrorMessage>
                  </FormControl>
                </Card>
              </GridItem>
            </Grid>
            <Flex mt={5} flexDir={['column-reverse', 'row', 'row', 'row']} gap={5}>
              <Button onClick={() => console.log('pa atrás')} variant='secondary'>Regresar</Button>
              <Spacer/>
              <Button type='submit' variant='primary'>Continuar</Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </>
  )
}
