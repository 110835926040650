import { AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Badge, Button, IconButton, Menu, MenuButton, MenuItem, MenuList, Td, Text, Tr } from '@chakra-ui/react'
import React, { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import {Roles} from '../../enums/Roles';

export const UserTableRow = ({
    handleUpdateCRStatus,
    user,
    token
}) => {

    const navigate = useNavigate();
    const uri = (!user.parent_user_id) ? '/usuarios/editar' : '/usuarios/editar/secundario';

    const toggleCreditRequest = async (user_id) => { 
        fetch(`${process.env.REACT_APP_API_URL}/api/v1/user/toggle_credit_request/${user_id}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(() => {
            handleUpdateCRStatus(user_id);
        });
    }

    return (
        <AccordionItem as={Fragment} key={user.id}>
            <Tr>
                <Td></Td>
                <Td>{user.name} {user.last_name}</Td>
                <Td>{user.email}</Td>
                <Td>
                    {user.role.role}
                </Td>
                <Td>
                    <Badge rounded='15px' py={1} px={3} colorScheme='green'>Activo</Badge>
                </Td>
                <Td>
                    <Menu>
                        <MenuButton as={Button}><i className='bi bi-three-dots-vertical'></i></MenuButton>
                        <MenuList>
                            <MenuItem onClick={() => navigate(`${uri}/${user.id}`)} icon={<i className='bi bi-pencil'></i>}>
                                Editar
                            </MenuItem>
                            {
                                (user.role_id === Roles.Cliente) && (
                                    <MenuItem icon={(user.has_credit_request) ? <i className='bi bi-ban' style={{ color: 'red' }}></i> : <i className='bi bi-check' style={{ color: 'green' }}></i>} onClick={() => toggleCreditRequest(user.id)}>
                                        { user.has_credit_request ? 'Desactivar':'Activar' }  solicitudes de credito
                                    </MenuItem>
                                )
                            }
                            <MenuItem icon={<i className='bi bi-ban' style={{ color: 'red' }}></i>}>
                                Bloquear
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </Td>
            </Tr>
        </AccordionItem>
    )
}

export const AccordionUserTableRow = ({
    handleUpdateCRStatus,
    user,
    token
}) => {

    const navigate = useNavigate();

    const toggleCreditRequest = async (user_id) => { 
        fetch(`${process.env.REACT_APP_API_URL}/api/v1/user/toggle_credit_request/${user_id}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(() => {
            handleUpdateCRStatus(user_id);
        })
    }

    return (
        <AccordionItem as={Fragment}>
            <Tr key={user.id}>
                <Td>
                    <AccordionButton as={IconButton} icon={<AccordionIcon />} px={0} minW={0} />
                </Td>
                <Td>
                    {user.name} {user.last_name}
                </Td>
                <Td>{user.email}</Td>
                <Td>
                    {user.role.role}
                </Td>
                <Td>
                    <Badge rounded='15px' py={1} px={3} colorScheme='green'>Activo</Badge>
                </Td>
                <Td>
                    <Menu>
                        <MenuButton as={Button}><i className='bi bi-three-dots-vertical'></i></MenuButton>
                        <MenuList>
                            <MenuItem onClick={() => navigate(`/usuarios/editar/${user.id}`)} icon={<i className='bi bi-pencil'></i>}>
                                Editar
                            </MenuItem>
                            {
                                (user.role_id === Roles.Cliente) && (
                                    <MenuItem icon={(user.has_credit_request) ? <i className='bi bi-ban' style={{ color: 'red' }}></i> : <i className='bi bi-check' style={{ color: 'green' }}></i>} onClick={() => toggleCreditRequest(user.id)}>
                                        { user.has_credit_request ? 'Desactivar':'Activar' }  solicitudes de credito
                                    </MenuItem>
                                )
                            }
                            <MenuItem icon={<i className='bi bi-ban' style={{ color: 'red' }}></i>}>
                                Bloquear
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </Td>
            </Tr>
            {
                user.secondary_users.map((user) => (
                    <AccordionPanel as={Tr}>
                        <Td />
                        <Td>
                            <p>{user.name} {user.last_name}</p>
                            <Text color='gray.500' fontSize='xs'>Secundario</Text>
                        </Td>
                        <Td>{user.email}</Td>
                        <Td>
                            {user.secondary_role.role}
                        </Td>
                        <Td>
                            <Badge rounded='15px' py={1} px={3} colorScheme='green'>Activo</Badge>
                        </Td>
                        <Td>
                            <Menu>
                                <MenuButton as={Button}><i className='bi bi-three-dots-vertical'></i></MenuButton>
                                <MenuList>
                                    <MenuItem onClick={() => navigate(`/usuarios/editar/secundario/${user.id}`)} icon={<i className='bi bi-pencil'></i>}>
                                        Editar
                                    </MenuItem>
                                    <MenuItem icon={<i className='bi bi-ban' style={{ color: 'red' }}></i>}>
                                        Bloquear
                                    </MenuItem>
                                </MenuList>
                            </Menu>
                        </Td>
                    </AccordionPanel>
                ))
            }
        </AccordionItem>
    )
};
