import { Box, Button, Flex, FormControl, FormErrorMessage, FormLabel, Grid, Input, Select, Spacer } from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import React, { useState } from 'react'
import useSepomex from '../../../hooks/useSepomex'
import useCreditRequest from '../../../hooks/useCreditRequest'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { syncCreditRequestInfo } from '../../../redux/slices/creditRequestSlice'

const FiscalAddressForm = ({
    nextStep,
    currentStep
}) => {


    const { credit_id } = useSelector((state) => state.credit_request);
    const { company_name, fiscal_address } = useSelector((state) => state.credit_request.info);
    const dispatch = useDispatch();

    const { getDataFromPostalCode } = useSepomex();
    const { createFiscalAddress } = useCreditRequest();
    const [cpData, setCpData] = useState(null);
    const navigate = useNavigate();

    const handlePostalCode = (event, handleChange) => {
        handleChange(event);
        const postal_code = event.target.value;

        loadCitiesAndStates(postal_code);
    }

    const loadCitiesAndStates = async (postal_code) => {
        if (postal_code.toString().length == 5) {
            const data = await getDataFromPostalCode(postal_code);
            setCpData(data);
        } else {
            setCpData(null);
        }
    }

    useEffect(() => {
        if (fiscal_address?.postal_code) {
            loadCitiesAndStates(fiscal_address.postal_code);
        }
    }, [fiscal_address]);

    return (
        <Formik
            initialValues={{
                company_name: company_name || '',
                vatnum: fiscal_address?.vatnum || '',
                telephone: fiscal_address?.telephone || '',
                street: fiscal_address?.street || '',
                num_ext: fiscal_address?.num_ext || '',
                num_int: fiscal_address?.num_int || null,
                postal_code: fiscal_address?.postal_code || '',
                suburb: fiscal_address?.suburb || '',
                city: fiscal_address?.city || '',
                state: fiscal_address?.state || ''
            }}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={Yup.object({
                company_name: Yup.string().required('Este campo es necesario.'),
                vatnum: Yup.string().required('Este campo es necesario.'),
                telephone: Yup.string().matches(/^[0-9]{10}?/, "El número de teléfono debe tener solo 10 dígitos.").required('Este campo es necesario.'),
                street: Yup.string().required('Este campo es necesario.'),
                num_ext: Yup.string().required('Este campo es necesario.'),
                num_int: Yup.string().notRequired(),
                postal_code: Yup.string().required('Este campo es necesario.'),
                suburb: Yup.string().required('Este campo es necesario.'),
                city: Yup.string().required('Este campo es necesario.'),
                state: Yup.string().required('Este campo es necesario.'),
            })}
            onSubmit={async (values, { setErrors }) => {

                const data = await createFiscalAddress(values, credit_id);

                if (data.success) {
                    dispatch(syncCreditRequestInfo());
                    nextStep(currentStep + 1);
                } else {
                    if (data.errors) {
                        setErrors(data.errors);
                    } else {
                        setErrors({
                            global: 'Hubo un error en general.'
                        });
                    }
                }
            }}
        >
            {({ values, getFieldProps, errors, handleChange }) => (
                <Form>
                    {
                        (errors.global) && <Box className='form-error-message' mb={2}>{errors.global}</Box>
                    }
                    <Grid templateColumns={['repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(2, 1fr)']} gap={2}>
                        <FormControl isInvalid={(errors.company_name)}>
                            <FormLabel><span style={{ color: 'red' }}>*</span> Razón social</FormLabel>
                            <Input type='text' {...getFieldProps('company_name')} />
                            <FormErrorMessage className='form-error-message'>
                                {errors.company_name}
                            </FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={(errors.vatnum)}>
                            <FormLabel><span style={{ color: 'red' }}>*</span> RFC</FormLabel>
                            <Input type='text' {...getFieldProps('vatnum')} />
                            <FormErrorMessage className='form-error-message'>
                                {errors.vatnum}
                            </FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={(errors.telephone)}>
                            <FormLabel><span style={{ color: 'red' }}>*</span> Teléfono</FormLabel>
                            <Input type='number' {...getFieldProps('telephone')} />
                            <FormErrorMessage className='form-error-message'>
                                {errors.telephone}
                            </FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={(errors.street)}>
                            <FormLabel><span style={{ color: 'red' }}>*</span> Calle</FormLabel>
                            <Input type='text' {...getFieldProps('street')} />
                            <FormErrorMessage className='form-error-message'>
                                {errors.street}
                            </FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={(errors.num_ext)}>
                            <FormLabel><span style={{ color: 'red' }}>*</span> Número exterior</FormLabel>
                            <Input type='text' {...getFieldProps('num_ext')} />
                            <FormErrorMessage className='form-error-message'>
                                {errors.num_ext}
                            </FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={(errors.num_int)}>
                            <FormLabel>Número interior</FormLabel>
                            <Input type='text' {...getFieldProps('num_int')} />
                            <FormErrorMessage className='form-error-message'>
                                {errors.num_int}
                            </FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={(errors.postal_code)}>
                            <FormLabel><span style={{ color: 'red' }}>*</span> Código postal</FormLabel>
                            <Input type='text' {...getFieldProps('postal_code')} onChange={(e) => handlePostalCode(e, handleChange)} />
                            <FormErrorMessage className='form-error-message'>
                                {errors.postal_code}
                            </FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={(errors.suburb)}>
                            <FormLabel><span style={{ color: 'red' }}>*</span> Colonia</FormLabel>
                            <Select {...getFieldProps('suburb')}>
                                <option>Selecciona una colonia</option>
                                {
                                    (cpData && cpData.colonias) && cpData.colonias.map((col) => <option value={col.colonia} key={col.id}>{col.colonia}</option>)
                                }
                            </Select>
                            <FormErrorMessage className='form-error-message'>
                                {errors.suburb}
                            </FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={(errors.city)}>
                            <FormLabel><span style={{ color: 'red' }}>*</span> Municipio/Ciudad</FormLabel>
                            <Select {...getFieldProps('city')}>
                                <option>Selecciona un Municipio/Ciudad</option>
                                {
                                    cpData && cpData.municipio && <option value={cpData.municipio}>{cpData.municipio}</option>
                                }
                            </Select>
                            <FormErrorMessage className='form-error-message'>
                                {errors.city}
                            </FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={(errors.state)}>
                            <FormLabel><span style={{ color: 'red' }}>*</span> Estado</FormLabel>
                            <Select {...getFieldProps('state')}>
                                <option>Selecciona un estado</option>
                                {
                                    cpData && cpData.estado && <option value={cpData.estado}>{cpData.estado}</option>
                                }
                            </Select>
                            <FormErrorMessage className='form-error-message'>
                                {errors.state}
                            </FormErrorMessage>
                        </FormControl>
                    </Grid>
                    <Flex mt={2} flexDir={['column-reverse', 'row', 'row', 'row']}>
                        <Button onClick={() => navigate('/solicitudes-de-credito')} variant='secondary'>Regresar</Button>
                        <Spacer my={1} />
                        <Button type='submit' variant='primary'>Continuar</Button>
                    </Flex>
                </Form>
            )}
        </Formik>
    )
}

export default FiscalAddressForm