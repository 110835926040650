import { Box, Button, Divider, Grid, GridItem, HStack, Heading, Icon, IconButton, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Stack, Text, Textarea, VStack, useDisclosure, useStatStyles } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import InfoBox from '../../../components/credit_requests/summary/InfoBox'
import { useDispatch, useSelector } from 'react-redux'
import useDeparments from '../../../hooks/useDeparments'
import { setStep, syncCreditRequestInfo } from '../../../redux/slices/creditRequestSlice'
import { useNavigate, useParams } from 'react-router-dom'
import { indexes } from '../../layouts/credit_request/NewCRLayout'
import ShowOrDeleteFile from '../../../components/credit_requests/fourth_step/ShowOrDeleteFile'
import useCreditRequest from '../../../hooks/useCreditRequest'
import Swal from 'sweetalert2'
import { colors } from '../../../themes/colors'
import { CRStatus } from '../../../enums/CRStatus'
import { useNavigateSearch } from '../../../hooks/useNavigateSearch'

const CRSummary = () => {

  const { credit_id, info } = useSelector((state) => state.credit_request);
  const { token, role } = useSelector((state) => state.user.data);
  const MXNFormat = new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN'
  });

  const { isOpen, onOpen, onClose } = useDisclosure();

  const navigate = useNavigate();
  const searchNavigate = useNavigateSearch();
  const [departments, setDepartments] = useState();
  const [commentModal, setCommentModal] = useState();
  const [errors, setErrors] = useState([]);
  const dispatch = useDispatch();
  const { changeStatusToCR } = useCreditRequest();
  const { getDepartments } = useDeparments();

  const handleEditSection = (step, params = null) => {
    dispatch(setStep(step));

    (!params) ? navigate(indexes[step]) : searchNavigate(indexes[step], params);
  }

  const handleBack = () => {
    dispatch(setStep(3));
    navigate('/solicitudes-de-credito/documentacion');
  }

  const handleFinishCR = async () => {
    const { success, errors } = await changeStatusToCR(CRStatus.Creada, credit_id);

    if (success) {
      dispatch(setStep(5));
      navigate('/solicitudes-de-credito/gracias');
    } else {
      Swal.fire({
        title: 'Error',
        text: errors,
        icon: 'error',
        confirmButtonColor: colors['primary']['900']
      });
    }
  }

  const wasReviewed = () => {
    let reviewed = false;
    
    if(info.status_id !== CRStatus.Pendiente) {
      reviewed = (info.documentation.csf_comments ||
        info.documentation.ine_comments ||
        info.documentation.constitutional_act_comments ||
        info.documentation.account_status_comments ||
        info.documentation.address_comprobant_comments ||
        info.documentation.representative_power_comments ||
        info.documentation.sign_comments);

      if(!reviewed) {
        for(let com_ref of info.commercial_references) {
          if(com_ref.comments) {
            reviewed = true;
            break;
          }
        }
      }
    }

    return reviewed;
  }

  const handleCommentModal = (document) => {
    const titles = {
      csf_comments: 'Constancia de situación fiscal',
      ine_comments: 'INE',
      constitutional_act_comments: 'Acta constitucional',
      account_status_comments: 'Estado de cuenta',
      address_comprobant_comments: 'Comprobante de domicilio',
      representative_power_comments: 'Poder de representante',
      sign_comments: 'Firma del solicitante',
    }

    setCommentModal({
      title: titles[document],
      comment: info.documentation[document]
    });
    onOpen();
  }

  const handleCommentReference = (title, comment) => {
    setCommentModal({
      title,
      comment
    });
    onOpen();
  }

  const handlePrint = () => {
    // let pdf = document.getElementById("printerDiv");
    // pdf.innerHTML = `<iframe src="/" onload="this.contentWindow.print();"></iframe>`;

    var printWindow = window.open(`${process.env.REACT_APP_API_URL}/generate_docs/credit_request/${credit_id}`, 'Print', 'left=200, top=200, width=950, height=500, toolbar=0, resizable=0');

    printWindow.addEventListener('load', function () {
      if (Boolean(printWindow.chrome)) {
        printWindow.print();
        setTimeout(function () {
          printWindow.close();
        }, 500);
      } else {
        printWindow.print();
        printWindow.close();
      }
    }, true);
  }

  const handleUploadFile = async (event) => {
    const label = event.target.parentElement;
    const value = event.target.files[0];
    const span = label.getElementsByTagName('span')[0];

    if (event.target.files[0] && event.target.files[0].size > 0) {
      const file_name = event.target.files[0].name;
      span.textContent = file_name;

      const sign = new FormData();
      sign.append('sign', value);

      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/credit_requests/upload_sign/${credit_id}`, {
        method: 'POST',
        body: sign,
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (res.ok) {
        const { success, errors: apiErrors } = await res.json();
        if (success) {
          dispatch(syncCreditRequestInfo());
          setErrors(null);
        } else {
          setErrors(apiErrors);
        }
      } else {
        span.textContent = 'Selecciona tu archivo.';
        event.target.files[0] = null;

        setErrors({
          sign: ['No se ha podido subir su archivo correctamente.']
        });
      }

    } else {
      span.textContent = 'Selecciona tu archivo.';
    }
  }

  useEffect(() => {
    (async () => {
      const { data } = await getDepartments();

      setDepartments(data);
    })();
  }, []);

  useEffect(() => {
    dispatch(setStep(4));
    dispatch(syncCreditRequestInfo());
  }, [dispatch]);

  return (
    <div>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{commentModal?.title}</ModalHeader>
          <ModalCloseButton />

          <ModalBody pb={5}>
            <Text fontWeight='bold' mb={2}>Comentarios</Text>
            <Text borderWidth={1} borderColor='gray.400' p={2} borderRadius={3}>{commentModal?.comment}</Text>
          </ModalBody>
        </ModalContent>
      </Modal>
      <VStack w='100%'>
        <Heading variant='title'></Heading>
        <Grid templateColumns={['repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(3, 1fr)']} w='100%' gap={2}>
          <GridItem>
            <InfoBox title='Datos fiscales' handleEditSection={() => handleEditSection(0)}>
              <p><b>{info.company_name}</b></p>
              <p>{info.fiscal_address.vatnum}</p>
              <p>{info.fiscal_address.street} #{info.fiscal_address.num_ext} {info.fiscal_address.num_int}, Col. {info.fiscal_address.suburb}, C.P. {info.fiscal_address.postal_code}, {info.fiscal_address.city}, {info.fiscal_address.state}</p>
              <p><b>Teléfono:</b> {info.fiscal_address.telephone}</p>
            </InfoBox>
          </GridItem>
          <GridItem>
            <InfoBox title='Información del crédito' handleEditSection={() => handleEditSection(0, {step: 'credit_info'})}>
              <p><b>Monto solicitado:</b> {MXNFormat.format(info.amount_requested)}</p>
              <p><b>Plazo solicitado:</b> {info.term_requested} días</p>
              <p><b>Consumo mensual:</b> {MXNFormat.format(info.mensual_consume)}</p>
            </InfoBox>
          </GridItem>
          <GridItem>
            <InfoBox title='Datos de facturación' handleEditSection={() => handleEditSection(0, {step: 'invoice_data'})}>
              <p><b>Método de pago:</b> {info.fiscal_data.payment_method_id}</p>
              <p><b>Forma de pago:</b> {info.fiscal_data.payment_way_id}</p>
              <p><b>Uso de CFDI:</b> {info.fiscal_data.uso_cfdi_id}</p>
              <p><b>Régimen fiscal:</b> {info.fiscal_data.tax_regime_id}</p>
            </InfoBox>
          </GridItem>
          <GridItem>
            <InfoBox title='Procedimiento de pago' handleEditSection={() => handleEditSection(1)}>
              <VStack textAlign='left'>
                <p><b>Días de pago</b></p>
                <Grid templateColumns='repeat(7, 1fr)'
                  w='100%'
                  borderWidth={1}
                  borderColor='gray.400'
                  padding={3}
                  borderRadius={5}
                >
                  {[...Array(31)].map((x, i) => (
                    <Box
                      m={1}
                      padding={1}
                      height='40px'
                      borderColor='gray'
                      borderRadius='full'
                      display='flex'
                      justifyContent='center'
                      alignItems='center'
                      backgroundColor={(info.pay_days.indexOf(i + 1) !== -1) ? 'primary.900' : 'white'}
                      color={(info.pay_days.indexOf(i + 1) !== -1) ? 'white' : 'black'}
                      fontWeight='bold'
                      transition='0.8s'
                      key={i + 1}
                    >
                      {i + 1}
                    </Box>
                  ))}
                </Grid>
              </VStack>
              <VStack mt={3}>
                <p><b>Días de revisión</b></p>
                <HStack justifyContent='space-around' w='100%'>
                  <p>Lu</p>
                  <p>Ma</p>
                  <p>Mi</p>
                  <p>Ju</p>
                  <p>Vi</p>
                  <p>Sa</p>
                  <p>Do</p>
                </HStack>
                <HStack justifyContent='space-around' w='100%'>
                  <i className={`bi ${JSON.parse(info.review_days).indexOf(1) !== -1 ? 'bi-check-circle review-day-selected' : 'bi-circle'} review-day`}></i>
                  <i className={`bi ${JSON.parse(info.review_days).indexOf(2) !== -1 ? 'bi-check-circle review-day-selected' : 'bi-circle'} review-day`}></i>
                  <i className={`bi ${JSON.parse(info.review_days).indexOf(3) !== -1 ? 'bi-check-circle review-day-selected' : 'bi-circle'} review-day`}></i>
                  <i className={`bi ${JSON.parse(info.review_days).indexOf(4) !== -1 ? 'bi-check-circle review-day-selected' : 'bi-circle'} review-day`}></i>
                  <i className={`bi ${JSON.parse(info.review_days).indexOf(5) !== -1 ? 'bi-check-circle review-day-selected' : 'bi-circle'} review-day`}></i>
                  <i className={`bi ${JSON.parse(info.review_days).indexOf(6) !== -1 ? 'bi-check-circle review-day-selected' : 'bi-circle'} review-day`}></i>
                  <i className={`bi ${JSON.parse(info.review_days).indexOf(7) !== -1 ? 'bi-check-circle review-day-selected' : 'bi-circle'} review-day`}></i>
                </HStack>
              </VStack>
              <Divider my={2} />
              <p><b>Cuenta bancaria:</b> {info.bank_account}</p>
              <p>{info.payment_address.street} #{info.payment_address.num_ext} {info.payment_address.num_int}, Col. {info.payment_address.suburb}, C.P. {info.payment_address.postal_code}, {info.payment_address.city}, {info.payment_address.state}</p>
            </InfoBox>
          </GridItem>
          <GridItem>
            <InfoBox title='Referencias Comerciales' handleEditSection={() => handleEditSection(2)}>
              {
                info.commercial_references.map((com_ref) => (
                  <HStack alignItems='start'>
                    <Box mb={3} flex={1}>
                      <p><b>{com_ref.name} {com_ref.last_name} - {com_ref.company_name}</b></p>
                      <p><b>Teléfono:</b> {com_ref.telephone}</p>
                      <p><b>Correo contacto:</b> {com_ref.email}</p>
                      <p><b>Monto:</b> {MXNFormat.format(com_ref.limit_credit)}</p>
                      <p><b>Plazo:</b> {MXNFormat.format(com_ref.term_credit)}</p>
                    </Box>
                    {
                      (com_ref.comments) && (
                        <Button onClick={() => handleCommentReference(`Referencia - ${com_ref.name} ${com_ref.last_name}`, com_ref.comments)} colorScheme='red' variant='outline'>
                          <i className='bi bi-exclamation-diamond-fill' style={{ color: 'red', marginRight: 5 }}></i>Revisar
                        </Button>
                      )
                    }
                  </HStack>
                ))
              }
            </InfoBox>
          </GridItem>
          <GridItem>
            <InfoBox title='Principales ejecutivos' handleEditSection={() => handleEditSection(2)}>
              {
                info.executives.map((executive) => (
                  <Box mb={3}>
                    <p><b>{executive.name} {executive.last_name}</b></p>
                    <p><b>Teléfono:</b> {executive.telephone}</p>
                    <p><b>Correo de contacto:</b> {executive.email}</p>
                    {
                      departments && <p><b>Departamento:</b> {departments.filter((dep) => dep.id == executive.department_id)[0].department}</p>
                    }
                  </Box>
                ))
              }
            </InfoBox>
          </GridItem>
        </Grid>
        <Grid templateColumns={'repeat(1, 1fr)'} w='100%' gap={2}>
          <GridItem>
            <InfoBox title='Documentación' handleEditSection={() => handleEditSection(3)}>
              <Grid templateColumns={['repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(2, 1fr)']} gap={5}>
                <GridItem>
                  <HStack>
                    <Box flex={1}>
                      <ShowOrDeleteFile title='Constancia de situación fiscal' filePath={info.documentation.csf_filename} typeFile='csf_filename' onlyShow />
                    </Box>
                    {
                      info.documentation.csf_comments && (
                        <Button onClick={() => handleCommentModal('csf_comments')} colorScheme='red' variant='outline'>
                          <i className='bi bi-exclamation-diamond-fill' style={{ color: 'red', marginRight: 5 }}></i>Revisar
                        </Button>
                      )
                    }
                  </HStack>
                </GridItem>
                <GridItem>
                  <HStack>
                    <Box flex={1}>
                      <ShowOrDeleteFile title='INE (Frontal y reversa)' filePath={info.documentation.ine_filename} typeFile='ine_filename' onlyShow />
                    </Box>
                    {
                      info.documentation.ine_comments && (
                        <Button onClick={() => handleCommentModal('ine_comments')} colorScheme='red' variant='outline'>
                          <i className='bi bi-exclamation-diamond-fill' style={{ color: 'red', marginRight: 5 }}></i>Revisar
                        </Button>
                      )
                    }
                  </HStack>
                </GridItem>
                <GridItem>
                  <HStack>
                    <Box flex={1}>
                      <ShowOrDeleteFile title='Acta constitucional' filePath={info.documentation.constitutional_act_filename} typeFile='constitutional_act_filename' onlyShow />
                    </Box>
                    {
                      info.documentation.constitutional_act_comments && (
                        <Button onClick={() => handleCommentModal('constitutional_act_comments')} colorScheme='red' variant='outline'>
                          <i className='bi bi-exclamation-diamond-fill' style={{ color: 'red', marginRight: 5 }}></i>Revisar
                        </Button>
                      )
                    }
                  </HStack>
                </GridItem>
                <GridItem>
                  <HStack>
                    <Box flex={1}>
                      <ShowOrDeleteFile title='Caratula de estado de cuenta' filePath={info.documentation.account_status_filename} typeFile='account_status_filename' onlyShow />
                    </Box>
                    {
                      info.documentation.account_status_comments && (
                        <Button onClick={() => handleCommentModal('account_status_comments')} colorScheme='red' variant='outline'>
                          <i className='bi bi-exclamation-diamond-fill' style={{ color: 'red', marginRight: 5 }}></i>Revisar
                        </Button>
                      )
                    }
                  </HStack>
                </GridItem>
                <GridItem>
                  <HStack>
                    <Box flex={1}>
                      <ShowOrDeleteFile title='Comprobante de domicilio' filePath={info.documentation.address_comprobant_filename} typeFile='address_comprobant_filename' onlyShow />
                    </Box>
                    {
                      info.documentation.address_comprobant_comments && (
                        <Button onClick={() => handleCommentModal('address_comprobant_comments')} colorScheme='red' variant='outline'>
                          <i className='bi bi-exclamation-diamond-fill' style={{ color: 'red', marginRight: 5 }}></i>Revisar
                        </Button>
                      )
                    }
                  </HStack>
                </GridItem>
                <GridItem>
                  <HStack>
                    <Box flex={1}>
                      <ShowOrDeleteFile title='Poder de representante' filePath={info.documentation.representative_power_filename} typeFile='representative_power_filename' onlyShow />
                    </Box>
                    {
                      info.documentation.representative_power_comments && (
                        <Button onClick={() => handleCommentModal('representative_power_comments')} colorScheme='red' variant='outline'>
                          <i className='bi bi-exclamation-diamond-fill' style={{ color: 'red', marginRight: 5 }}></i>Revisar
                        </Button>
                      )
                    }
                  </HStack>
                </GridItem>
              </Grid>

              <Divider my={2} />

              <p><b>Instrucciones:</b> Para continuar con el proceso será necesario que nos apoye con una firma aceptando la solicitud de crédito, por lo que necesitas imprimir el formato firmarlo y enviarlo de vuelta.</p>


              <Stack direction={['column', 'column', 'column', 'row']} justifyContent='start' alignItems='center'>
                <Button onClick={handlePrint} variant='secondary' mr={10} my={2}>
                  <HStack>
                    <i className='bi bi-download'></i>
                    <span>Imprimir formato de firma</span>
                  </HStack>
                </Button>
                <span style={{ fontWeight: 'bold', marginRight: '5px', textAlign: 'right' }}>Firma de solicitud de crédito</span>
                {
                  (!info.documentation.sign_filename) ? (
                    <label for='csf' className='file-uploader' style={{ flex: 1 }}>
                      <i className='bi bi-upload'></i> <span>Selecciona tu archivo.</span>
                      <input id='csf' type='file' name='csf' style={{ display: 'none' }} onChange={e => handleUploadFile(e)} />
                      {
                        (errors?.sign) && (
                          <Box className='form-error-message'>
                            {errors.sign[0]}
                          </Box>
                        )
                      }
                    </label>
                  ) : (
                    <ShowOrDeleteFile filePath={info.documentation.sign_filename} typeFile='sign_filename' />
                  )
                }
                {
                  info.documentation.sign_comments && (
                    <Button onClick={() => handleCommentModal('sign_comments')} colorScheme='red' variant='outline'>
                      <i className='bi bi-exclamation-diamond-fill' style={{ color: 'red', marginRight: 5 }}></i>Revisar
                    </Button>
                  )
                }
              </Stack>
            </InfoBox>
          </GridItem>
        </Grid>
        <Text hidden={!wasReviewed()} w='full' textAlign='right'>Para poder continuar es necesario actualizar los datos que se mencionaron en tu retroalimentación.</Text>
        <Stack direction='row' justifyContent='space-between' w='100%'>
          <Button variant='secondary' onClick={handleBack}>Regresar</Button>
          <Button variant='primary' onClick={handleFinishCR} isDisabled={!(info.documentation.sign_filename) || wasReviewed()}>Continuar</Button>
        </Stack>
      </VStack>
      <div id="printerDiv"></div>
    </div>
  )
}

export default CRSummary