import { Badge, Box, Button, Center, Divider, Heading, Menu, MenuButton, MenuDivider, MenuGroup, MenuItem, MenuList, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Stack, Text, useDisclosure } from '@chakra-ui/react'
import React from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import { CheckboxesNoti } from './CheckboxesNoti';



const NotificationDropdown = () => {
    
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { token } = useSelector((state) => state.user.data);
    
    const notificationFetcher = (url) => fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    }).then((res) => res.json());

    const handleNavigate = (url, nid) => {
        fetch(`${process.env.REACT_APP_API_URL}/api/v1/notification/set_as/opened/${nid}`, {
            method: 'POST',
            body: JSON.stringify({
                type: 'seen'
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        window.location.href = url;
    }

    const setAsSeen = () => {
        for(let notification of listNotifications.data.notifications) {
            if (!notification.seen) {
                fetch(`${process.env.REACT_APP_API_URL}/api/v1/notification/set_as/seen/${notification.id}`, {
                    method: 'POST',
                    body: JSON.stringify({
                        type: 'seen'
                    }),
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });
            }
        }
    }

    const { data: listNotifications, error } = useSWR(`${process.env.REACT_APP_API_URL}/api/v1/notification?last_five=1`, notificationFetcher, {
        refreshInterval: 1000
    });

    return (
        <Box mx='10px'>
            <Menu
                onOpen={setAsSeen}
            >
                <MenuButton position='relative'>
                    <Text color='white' fontSize='2xl'><i class="bi bi-bell"></i></Text>
                    {
                        listNotifications?.data.total > 0 && <Box bgColor='red.500' color='white' fontSize={10} fontWeight='bold' px='1' py='0' borderRadius='full' position='absolute' top={0} right={-1}>{listNotifications.data.total}</Box>
                    }
                </MenuButton>
                <MenuList maxW='350px'>
                    <MenuGroup>
                        <Center justifyContent='space-between' pt='10px' px='10px'>
                            <Heading fontSize='20px' as='b' variant='title'>
                                Notificaciones
                            </Heading>
                            <Text as='button' onClick={onOpen} fontSize='20px'>
                                <i class="bi bi-three-dots-vertical"></i>
                            </Text>
                        </Center>
                    </MenuGroup>
                    <MenuDivider />
                    <MenuGroup boxSizing='content-box'>
                        {
                            listNotifications && listNotifications.data.notifications.length > 0
                                ? (
                                    <>
                                        {
                                            listNotifications.data.notifications.map((n) => (
                                                <Link onClick={() => handleNavigate(n.url, n.id)}>
                                                    <Box py={1} px={2}>
                                                        <MenuItem borderRadius={3} backgroundColor={(n.opened) ? 'transparent' : 'gray.100' } _hover={{backgroundColor: 'gray.300'}}>
                                                            {(!n.opened) && <Text mr={2} fontSize='10px' color='red'><i class="bi bi-circle-fill"></i></Text>}
                                                            <Text fontWeight={(n.opened) ? 'semibold' : 'bold'} ml={1}>{n.message}</Text>
                                                        </MenuItem>
                                                    </Box>
                                                </Link>
                                            ))
                                        }
                                        <Divider />
                                        <Center mx={5} mt='15px' mb='10px'>
                                            <Button as={Link} variant='primary' to='/notificaciones' w='50%'>
                                                Ver más
                                            </Button>
                                        </Center>
                                    </>
                                )
                                : <Text p={3}>No tienes notificaciones</Text>
                        }
                    </MenuGroup>
                </MenuList>
            </Menu>
            <Modal isOpen={isOpen} onClose={onClose} size='xs'>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Configuración</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Stack>
                            <CheckboxesNoti 
                                onClose={onClose}
                                />
                        </Stack>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Box>
    )
}

export default NotificationDropdown