import { useSelector } from 'react-redux';

export const useCards = () => {

    const base_url = `${process.env.REACT_APP_API_URL}/api/v1/openpay/cards`;

    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }

    const { token } = useSelector((state) => state.user.data);

    const getCards = async () => {
        const res = await fetch(`${base_url}`, {
            method: 'GET',
            headers: {
                ...headers,
                'Authorization': `Bearer ${token}`
            }
        });
        const { data } = await res.json();
        return data;
    }

    const getCard = async (card_id) => {
        const res = await fetch(`${base_url}/${card_id}`, {
            method: 'GET',
            headers: {
                ...headers,
                'Authorization': `Bearer ${token}`
            }
        });
        const { data } = await res.json();
        return data;
    }


    const createCard = async (body) => {

        const res = await fetch(`${base_url}`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                ...headers,
                'Authorization': `Bearer ${token}`
            }
        });

        if(res.status === 200)  {
            const data = await res.json();
            return data;
        } else { 
            return 0;
        }
    }

    const updateCard = async (body, card_id) => {
        const res = await fetch(`${base_url}/${card_id}`, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                ...headers,
                'Authorization': `Bearer ${token}`
            }
        });
        
        if(res.status == 200) {
            const data = await res.json();
            return data;
        } else {
            return {
                success: false,
                errors: {
                    global: 'Hubo un error con la petición o con el servidor, intenta de nuevo.'
                }
            }
        }
    }

    const deleteCard = async (card_id) => {
        const res = await fetch(`${base_url}/${card_id}`, {
            method: 'DELETE',
            headers: {
                ...headers,
                'Authorization': `Bearer ${token}`
            }
        });

        const data = await res.json();
        return data; 
    }

  return {
    getCards,
    getCard,
    createCard,
    updateCard,
    deleteCard,
  };
}
