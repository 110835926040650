import { Box, Button, FormControl, FormErrorMessage, FormLabel, Grid, GridItem, HStack, Heading, Input, Modal, ModalBody, ModalContent, ModalHeader, Stack, useDisclosure } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup';
import useCreditRequest from '../../../hooks/useCreditRequest';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { syncCreditRequestInfo } from '../../../redux/slices/creditRequestSlice';

const CommercialReferences = ({
    setCommercialReferences,
    commercialReferences
}) => {

    const dispatch = useDispatch();
    const {isOpen, onClose, onOpen} = useDisclosure();
    const {commercial_references} = useSelector((state) => state.credit_request.info);
    const { addCommercialReferenceToCR, updateCommercialReferenceToCR, deleteCommercialReferenceToCR } = useCreditRequest();

    const [commercialReference, setCommercialReference] = useState(null);

    const handleDeleteReference = async (id) => {
        const {success, message} = await deleteCommercialReferenceToCR(id);

        if(success) {
            setCommercialReferences((commercialReferences) => commercialReferences.filter((com_ref) => id !== com_ref.id));
            dispatch(syncCreditRequestInfo());
        } else {
            alert(message);
        }
    }

    const handleEditReference = (ref) => {
        onOpen();
        setCommercialReference(ref);
    }

    const handleNewReference = () => {
        onOpen();
        setCommercialReference(null);
    }

    const handleCreateCommercialReference = async (values, setErrors) => {
        const { success, ref_id, errors } = (!commercialReference) ? await addCommercialReferenceToCR(values) : await updateCommercialReferenceToCR(values, commercialReference.id);

        if(success) {
            if(!commercialReference) {
                setCommercialReferences([
                    ...commercialReferences,
                    {
                        id: ref_id,
                        ...values
                    }
                ]);
            } else {
                setCommercialReferences((commercialReferences) => commercialReferences.map((ref) => {
                    if(ref.id == ref_id) {
                        return {
                            id: ref_id,
                            ...values
                        }
                    } else {
                        return ref;
                    }
                }));
            }

            dispatch(syncCreditRequestInfo());
            onClose();
        } else {
            setErrors(errors);
        }
    }

    useEffect(() => {
        setCommercialReferences(commercial_references);
    }, [commercial_references]);

    return (
        <>
            <Heading size='md'>Referencias comerciales <i className='bi bi-info-circle'></i></Heading>
            <Grid
                templateColumns={['repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(2, 1fr)']}
                gap={3}
            >
                {
                    commercialReferences &&
                    commercialReferences.map((com_ref, index) => (
                        <GridItem key={index}>
                            <Box className='client-info-box'>
                                <Box>
                                    <p><b>Razón social:</b> {com_ref.company_name}</p>
                                </Box>
                                <Box>
                                    <p><b>Nombre:</b> {com_ref.name} {com_ref.last_name}</p>
                                </Box>
                                <Box>
                                    <p><b>Correo:</b> {com_ref.email}</p>
                                </Box>
                                <Box>
                                    <p><b>Teléfono:</b> {com_ref.telephone}</p>
                                </Box>
                                <Box>
                                    <p><b>Plazo de crédito:</b> {com_ref.term_credit} días</p>
                                </Box>
                                <Box>
                                    <p><b>Monto de crédito:</b> ${com_ref.limit_credit}</p>
                                </Box>
                                <HStack mt={2} justifyContent='space-between'>
                                    <Button colorScheme='red' onClick={() => handleDeleteReference(com_ref.id)}>
                                        <i className='bi bi-trash'></i>
                                    </Button>
                                    <Button colorScheme='blue' onClick={() => handleEditReference(com_ref)}>
                                        <i className='bi bi-pencil'></i>
                                    </Button>
                                </HStack>
                            </Box>
                        </GridItem>
                    ))
                }
                {
                    commercialReferences.length < 5
                    &&
                    <GridItem>
                        <Box
                            h='100%'
                            fontSize={50}
                            display='flex'
                            justifyContent='center'
                            alignItems='center'
                            borderColor='gray.400'
                            color='gray.400'
                            borderWidth='2px'
                            borderRadius='4px'
                            cursor='pointer'
                            onClick={handleNewReference}
                            transition='0.2s'
                            _hover={{
                                borderColor: 'gray.500',
                                color: 'gray.500',
                            }}
                        >
                            <i className='bi bi-plus'></i>
                        </Box>
                    </GridItem>
                }
            </Grid>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
            >
                <ModalContent py={3}>
                    <ModalHeader>Agregar referencia comercial <i className='bi bi-info-circle'></i></ModalHeader>
                    <ModalBody>
                        <Formik
                            initialValues={{
                                name: commercialReference?.name || '',
                                last_name: commercialReference?.last_name || '',
                                email: commercialReference?.email || '',
                                company_name: commercialReference?.company_name || '',
                                telephone: commercialReference?.telephone || '',
                                term_credit: commercialReference?.term_credit || '',
                                limit_credit: commercialReference?.limit_credit || ''
                            }}
                            validateOnChange={false}
                            validateOnBlur={false}
                            validationSchema={Yup.object({
                                name: Yup.string().required(),
                                last_name: Yup.string().required(),
                                telephone: Yup.number().test('tel', 'El teléfono debe tener solo 10 dígitos.', val => val.toString().length === 10).required(),
                                email: Yup.string().email().required(),
                                company_name: Yup.string().required(),
                                term_credit: Yup.number().required(),
                                limit_credit: Yup.number().required()
                            })}
                            onSubmit={(values, {setErrors}) => {
                                handleCreateCommercialReference(values, setErrors);
                            }}
                        >
                            {({ errors, getFieldProps }) => (
                                <Form>
                                    <FormControl isInvalid={errors.company_name}>
                                        <FormLabel>Razón social</FormLabel>
                                        <Input type='text' {...getFieldProps('company_name')} />
                                        <FormErrorMessage className='form-error-message'>
                                            {errors.company_name}
                                        </FormErrorMessage>
                                    </FormControl>
                                    <HStack>
                                        <FormControl isInvalid={errors.name}>
                                            <FormLabel>Nombre</FormLabel>
                                            <Input type='text' {...getFieldProps('name')} />
                                            <FormErrorMessage className='form-error-message'>
                                                {errors.name}
                                            </FormErrorMessage>
                                        </FormControl>
                                        <FormControl isInvalid={errors.last_name}>
                                            <FormLabel>Apellido</FormLabel>
                                            <Input type='text' {...getFieldProps('last_name')} />
                                            <FormErrorMessage className='form-error-message'>
                                                {errors.last_name}
                                            </FormErrorMessage>
                                        </FormControl>
                                    </HStack>
                                    <FormControl isInvalid={errors.email}>
                                        <FormLabel>Correo de contacto</FormLabel>
                                        <Input type='text' {...getFieldProps('email')} />
                                        <FormErrorMessage className='form-error-message'>
                                            {errors.email}
                                        </FormErrorMessage>
                                    </FormControl>
                                    <FormControl isInvalid={errors.telephone}>
                                        <FormLabel>Teléfono</FormLabel>
                                        <Input type='string' {...getFieldProps('telephone')} maxLength={10} />
                                        <FormErrorMessage className='form-error-message'>
                                            {errors.telephone}
                                        </FormErrorMessage>
                                    </FormControl>
                                    <FormControl isInvalid={errors.term_credit}>
                                        <FormLabel>Plazo de crédito</FormLabel>
                                        <Input type='text' {...getFieldProps('term_credit')} />
                                        <FormErrorMessage className='form-error-message'>
                                            {errors.term_credit}
                                        </FormErrorMessage>
                                    </FormControl>
                                    <FormControl isInvalid={errors.limit_credit}>
                                        <FormLabel>Monto de crédito</FormLabel>
                                        <Input type='text' {...getFieldProps('limit_credit')} />
                                        <FormErrorMessage className='form-error-message'>
                                            {errors.limit_credit}
                                        </FormErrorMessage>
                                    </FormControl>
                                    <Stack direction={['column', 'column', 'row', 'row']} mt={5} justifyContent='space-between'>
                                        <Button variant='secondary' onClick={onClose}>Cancelar</Button>
                                        <Button type='submit' variant='primary'>{(commercialReference) ? 'Modificar' : 'Agregar'}</Button>
                                    </Stack>
                                </Form>
                            )}
                        </Formik>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export default CommercialReferences