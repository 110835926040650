import React from 'react'
import { Box, Text, Stack, Button, Checkbox, Flex, FormControl, FormErrorMessage, FormLabel, Image, Input, InputGroup, InputLeftElement, Spinner, VStack } from '@chakra-ui/react'
import logo from '../../assets/images/commons/logo.png';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useUser } from '../../hooks/useUser';
import { useDispatch } from 'react-redux';
import { login } from '../../redux/slices/userSlice';
import moment from 'moment';

const LoginPage = () => {

    const { loginUser } = useUser();
    const nav = useNavigate();
    const dispatch = useDispatch();

    return (
        <Box
            display='flex'
            justifyContent='center'
        >
            <Box
                flex={1}
                maxW='600px'
            >
                <VStack
                    w='100%'
                >
                    <Image src={logo} w='300px' className='animate__animated animate__backInDown' mb={3} />
                    <VStack alignItems='start'
                        w='100%'
                        className='animate__animated animate__fadeIn'
                    >
                        <Formik
                            initialValues={{
                                email: '',
                                password: '',
                                remember: false,
                                terms: true
                            }}
                            validateOnChange={false}
                            validateOnBlur={false}
                            validationSchema={Yup.object({
                                email: Yup.string().email('El correo no tiene un formato válido.').required('Este campo es necesario.'),
                                password: Yup.string().required('Este campo es necesario.'),
                            })}
                            onSubmit={async ({email, password, remember, terms}, { setErrors }) => {

                                if(terms) {
                                    const { user, errors } = await loginUser({
                                        email,
                                        password
                                    });

                                    if (errors) {
                                        setErrors(errors);
                                    } else {
                                        dispatch(login({
                                            ...user,
                                            expireTime: (!remember) && moment().add(1, 'days')
                                        }));
                                        nav('/');
                                    }
                                } else {
                                    setErrors({global: 'Es necesario leer y aceptar los términos y condiciones.'});
                                }
                            }}
                        >
                            {({ isSubmitting, getFieldProps, errors, touched, values }) => (

                                <Form style={{ width: '100%' }}>
                                    <>
                                        {
                                            errors.global &&
                                            <Box className='form-error-message'>{errors.global}</Box>
                                        }
                                        {
                                            errors.terms &&
                                            <Box className='form-error-message'>{errors.terms}</Box>
                                        }
                                    </>
                                    <FormControl isInvalid={(errors.email && touched.email)}>
                                        <FormLabel color='white'>Correo electrónico</FormLabel>
                                        <InputGroup>
                                            <InputLeftElement>
                                                <i className='bi bi-person-fill' fontSize='20px'></i>
                                            </InputLeftElement>
                                            <Input type="email" bgColor='white' w='100%' {...getFieldProps('email')} />
                                        </InputGroup>
                                        <FormErrorMessage className='form-error-message'>
                                            {errors.email}
                                        </FormErrorMessage>
                                    </FormControl>
                                    <FormControl isInvalid={(errors.password && touched.password)}>
                                        <FormLabel color='white'>Contraseña</FormLabel>
                                        <InputGroup>
                                            <InputLeftElement>
                                                <i className='bi bi-lock-fill' fontSize='20px'></i>
                                            </InputLeftElement>
                                            <Input type="password" bgColor='white' w='100%' {...getFieldProps('password')} />
                                        </InputGroup>
                                        <FormErrorMessage className='form-error-message'>
                                            {errors.password}
                                        </FormErrorMessage>
                                    </FormControl>
                                    <Stack my={3} flexDirection='row' justifyContent='space-between'>
                                        <Box>
                                            <Link to="/solicitud-de-recuperacion" className="links">
                                                ¿Olvidaste tu contraseña?
                                            </Link>
                                        </Box>
                                        <Box>
                                            <Checkbox colorScheme='primary' color='white' {...getFieldProps('remember')}>Mantener sesión</Checkbox>
                                        </Box>
                                    </Stack>
                                    <Box my={3}>
                                        <Checkbox isChecked={values.terms} colorScheme='primary' color='white' {...getFieldProps('terms')}>He leído y acepto los <Link to={`${process.env.REACT_APP_API_URL}/files/terminos-condiciones-2024.pdf`} target='_blank' style={{textDecoration: 'underline'}}>términos y condiciones</Link>.</Checkbox>
                                    </Box>
                                    <Flex flexDir={['column', 'column', 'row', 'row']} w='100%'>
                                        <Button type='submit' variant='primary' w={['100%', '100%', 'auto', 'auto']} mr={[0, 0, 2, 2]}>
                                            {(isSubmitting) && <Spinner size='md' color='white' mr={2} />}
                                            Ingresar
                                        </Button>
                                        <Link to='/solicitud-de-alta'>
                                            <Button variant='tertiary' w={['100%', '100%', 'auto', 'auto']} mt={[2, 2, 0, 0]}>
                                                Solicita tu alta
                                            </Button>
                                        </Link>
                                    </Flex>
                                </Form>
                            )}
                        </Formik>
                    </VStack>
                </VStack>
            </Box>
        </Box>
    )
}

export default LoginPage