import React, { useState } from 'react'
import { Box, Button, Flex, FormControl, FormErrorMessage, FormLabel, Grid, Heading, Input, InputGroup, InputLeftElement, Spinner, Text, VStack } from '@chakra-ui/react'
import { Link, useNavigate } from 'react-router-dom'
import { useUser } from '../../../hooks/useUser'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { Form, Formik } from 'formik'
import * as Yup from 'yup';
import { syncHeaderData } from '../../../redux/slices/userSlice'
import Swal from 'sweetalert2'
import { colors } from '../../../themes/colors'

const UpdateInfoForm = () => {

  const [loading, setLoading] = useState(true);
  const [updated, setUpdated] = useState(false);
  const navigate = useNavigate();
  const { getUserData, updateUserData } = useUser(null);
  const { token } = useSelector((state) => state.user.data);
  const dispatch = useDispatch();

  const [user, setUser] = useState(null);

  const handleNumericInput = (e, setFieldValue) => {
    const value = e.target.value;
    const numericValue = value.replace(/[^\d]/g, '');
    setFieldValue('telephone', numericValue);
  };

  useEffect(() => {
    (async () => {
      const userData = await getUserData(token);
      setUser(userData);

      setLoading(false);
    })()
  }, []);


  return loading
    ?
    <Text>Cargando...</Text>
    :
    <VStack w='full' alignItems='start'>
      <Heading size='md'>Cuenta</Heading>
      <Formik
        style={{ width: '100%' }}
        initialValues={{
          name: user.name,
          last_name: user.last_name,
          telephone: user.telephone,
          vatnum: user.vatnum,
          email: user.email,
          company_name: user.company_name
        }}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={Yup.object({
          name: Yup.string().max(120).required('El campo nombre es obligatorio.'),
          last_name: Yup.string().max(120).required('El campo apellido es obligatorio.'),
          company_name: Yup.string().max(150).required('El campo razón social es obligatorio.'),
          vatnum: Yup.string().required('El campo RFC es obligatorio.'),
          email: Yup.string().email().required('El campo email es obligatorio.'),
          telephone: Yup.string().matches(/^[0-9]+$/).min(10, 'Debe tener 10 caracteres').max(10, 'Debe tener 10 digitos.').required('El campo teléfono es obligatorio.')
        })}
        onSubmit={async (values, { setErrors }) => {
          const { errors } = await updateUserData(token, values);

          if (errors) {
            setErrors(errors);
          } else {
            const {name, last_name} = await getUserData(token);
            dispatch(syncHeaderData({
              name, last_name
            }))

            Swal.fire({
                title: 'Actualizado',
                text: 'Se ha actualizado la información de tu perfil correctamente.',
                icon: 'success',
                showCloseButton: false,
                confirmButtonColor: colors['primary']['900'],
                confirmButtonText: 'Continuar'
            })
          }
        }}
      >
        {({ getFieldProps, errors, touched, isSubmitting, setFieldValue }) => (
          <Form
            style={{ width: '100%' }}
          >
            <Grid w='full' templateColumns={['repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(2, 1fr)']} gap={5} flex={1}>
              <FormControl isInvalid={(errors.name && touched.name)}>
                <FormLabel>Nombre</FormLabel>
                <InputGroup>
                  <InputLeftElement>
                    <i className='bi bi-person-fill' fontSize='20px'></i>
                  </InputLeftElement>
                  <Input type="text" bgColor='white' w='100%' {...getFieldProps('name')} />
                </InputGroup>
                <FormErrorMessage className='form-error-message'>
                  {errors.name}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={(errors.last_name && touched.last_name)}>
                <FormLabel>Apellido</FormLabel>
                <InputGroup>
                  <InputLeftElement>
                    <i className='bi bi-person-fill' fontSize='20px'></i>
                  </InputLeftElement>
                  <Input type="text" bgColor='white' w='100%' {...getFieldProps('last_name')} />
                </InputGroup>
                <FormErrorMessage className='form-error-message'>
                  {errors.last_name}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={(errors.email && touched.email)}>
                <FormLabel>Correo electrónico</FormLabel>
                <InputGroup>
                  <InputLeftElement>
                    <i className='bi bi-envelope-fill' fontSize='20px'></i>
                  </InputLeftElement>
                  <Input type="email" bgColor='gray.200' color='gray.900' w='100%' {...getFieldProps('email')} disabled />
                </InputGroup>
                <FormErrorMessage className='form-error-message'>
                  {errors.email}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={(errors.company_name && touched.company_name)}>
                <FormLabel>Razón social</FormLabel>
                <InputGroup>
                  <InputLeftElement>
                    <i className='bi bi-briefcase-fill' fontSize='20px'></i>
                  </InputLeftElement>
                  <Input type="string" bgColor='white' w='100%' {...getFieldProps('company_name')} />
                </InputGroup>
                <FormErrorMessage className='form-error-message'>
                  {errors.company_name}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={(errors.vatnum && touched.vatnum)}>
                <FormLabel>RFC</FormLabel>
                <InputGroup>
                  <InputLeftElement>
                    <i className='bi bi-person-vcard-fill' fontSize='20px'></i>
                  </InputLeftElement>
                  <Input type="string" bgColor='white' w='100%' {...getFieldProps('vatnum')} maxLength={13} />
                </InputGroup>
                <FormErrorMessage className='form-error-message'>
                  {errors.vatnum}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={(errors.telephone && touched.telephone)}>
                <FormLabel>Teléfono</FormLabel>
                <InputGroup>
                  <InputLeftElement>
                    <i className='bi bi-telephone-fill' fontSize='20px'></i>
                  </InputLeftElement>
                  <Input type="text" bgColor='white' w='100%' {...getFieldProps('telephone')} maxLength={10} onChange={(e) => handleNumericInput(e, setFieldValue)} />
                </InputGroup>
                <FormErrorMessage className='form-error-message'>
                  {errors.telephone}
                </FormErrorMessage>
              </FormControl>
            </Grid>
            <Flex flexDir={['column-reverse', 'column-reverse', 'row', 'row']} justifyContent='space-between' w='100%' mt={3}>
              <Link to='/'>
                <Button variant='secondary' w={['100%', '100%', 'auto', 'auto']} mt={[2, 2, 0, 0]}>
                  Cancelar
                </Button>
              </Link>
              <Button type='submit' variant='primary' w={['100%', '100%', 'auto', 'auto']}>
                {(isSubmitting) && <Spinner size='md' color='white' mr={2} />}
                Guardar información
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </VStack>

}

export default UpdateInfoForm