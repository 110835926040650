import { Button, Checkbox, FormControl, FormErrorMessage, FormLabel, Grid, GridItem, Heading, Input, InputGroup, InputRightElement, Select, Skeleton, Stack, Text } from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useUser } from '../../hooks/useUser';
import { useSelector } from 'react-redux';
import { SecondaryRoles } from '../../enums/Roles';
import Swal from 'sweetalert2';
import { colors } from '../../themes/colors';
import { useParams } from 'react-router-dom';

export default function NSUPage() {

  const { token } = useSelector((state) => state.user.data);
  const [roles, setRoles] = useState();
  const { getUserData } = useUser();
  const { user_id } = useParams();

  const [loading, setLoading] = useState(true);

  const { createUser, updateSecondaryUser, getSecondaryUserRoles } = useUser();
  const [user, setUser] = useState();

  const [invoiceChecks, setInvoiceChecks] = useState([false]);
  const allInvoiceChecks = invoiceChecks.every(Boolean);
  const isIndeterminateInvoice = invoiceChecks.some(Boolean) && !allInvoiceChecks;

  const [basicChecks, setBasicChecks] = useState([false, false, false, false, false, false]);
  const allBasicChecks = basicChecks.every(Boolean);
  const isIndeterminateBasic = basicChecks.some(Boolean) && !allBasicChecks;

  const [registerChecks, setRegisterChecks] = useState([false]);
  const allRegisterChecks = registerChecks.every(Boolean);
  const isIndeterminateRegister = registerChecks.some(Boolean) && !allRegisterChecks;

  const handleNumericInput = (e, setFieldValue) => {
    const value = e.target.value;
    const numericValue = value.replace(/[^\d]/g, '');
    setFieldValue('telephone', numericValue);
  };

  const handleCreateOrUpdateSecondaryUser = async (values, { setErrors, setSubmitting }) => {

    setSubmitting(true);

    const permissions = {
      invoice: invoiceChecks[0],
      quoter: basicChecks[0],
      guides: basicChecks[1],
      recollection: basicChecks[2],
      tracking: basicChecks[3],
      settings: basicChecks[4],
      covery: basicChecks[5],
      user_registration: registerChecks[0],
    }

    const body = {
      name: values.name,
      last_name: values.last_name,
      email: values.email,
      telephone: values.telephone,
      permissions,
      secondary_role: values.secondary_role,
      ...(values.password !== null && values.password !== '' ? { password: values.password } : {})
    };

    const { success, errors, message } =  (!user_id) ? await createUser(token, body, true) : await updateSecondaryUser(token, body, user_id);

    if (success) {
      Swal.fire({
        title: `Usuario ${(user_id) ? 'editado':'creado'}`,
        text: message,
        icon: "success",
        confirmButtonColor: colors['secondary']['900']
      });
    } else {
      console.log(errors);
      setErrors(errors);
    }

    setSubmitting(false);
  }

  useEffect(() => {
    getSecondaryUserRoles(token)
      .then((data) => {
        setRoles(data);
      })
  }, []);

  useEffect(() => {
    if (user_id) {
      getUserData(token, user_id)
        .then((data) => {
          setLoading(false);
          setUser(data);
          setInvoiceChecks([data.permissions.invoice])
          setRegisterChecks([data.permissions.user_registration])
          setBasicChecks([
            data.permissions.quoter,
            data.permissions.guides,
            data.permissions.recollection,
            data.permissions.tracking,
            data.permissions.settings,
            data.permissions.covery
          ])
        });
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <>
      {
        !loading
          ? (
            <Formik
              initialValues={{
                name: user?.name || '',
                last_name: user?.last_name || '',
                email: user?.email || '',
                telephone: user?.telephone || '',
                password: '',
                secondary_role: user?.secondary_role || ''
              }}
              onSubmit={handleCreateOrUpdateSecondaryUser}
            >
              {({ values, getFieldProps, errors, setFieldValue }) => (
                <Form>
                  <Grid templateColumns={['repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(2, 1fr)']} gap={10} >
                    <GridItem>
                      <Heading variant='title' mb='20px'>Crear Usuario Secundario</Heading>
                      <FormControl>
                        <FormLabel>Tipo de usuario</FormLabel>
                        <Select {...getFieldProps('secondary_role')}>
                          <option>Selecciona un tipo de usuario</option>
                          {
                            (roles) && roles.map((role) => <option key={role.id} value={role.id}>{role.role}</option>)
                          }
                        </Select>
                        <FormErrorMessage className='form-error-message'>
                          {errors.secondary_role}
                        </FormErrorMessage>
                      </FormControl>
                      <Stack flexDir={['column', 'column', 'row', 'row']}>
                        <FormControl mt={3}>
                          <FormLabel>Nombre</FormLabel>
                          <Input type='text' {...getFieldProps('name')} />
                          <FormErrorMessage className='form-error-message'>
                            {errors.name}
                          </FormErrorMessage>
                        </FormControl>
                        <FormControl mt={3}>
                          <FormLabel>Apellido</FormLabel>
                          <Input type='text' {...getFieldProps('last_name')} />
                          <FormErrorMessage className='form-error-message'>
                            {errors.last_name}
                          </FormErrorMessage>
                        </FormControl>
                      </Stack>
                      <FormControl mt={3}>
                        <FormLabel>Correo</FormLabel>
                        <Input type='text' {...getFieldProps('email')} />
                        <FormErrorMessage className='form-error-message'>
                            {errors.email}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl mt={3}>
                        <FormLabel>Teléfono</FormLabel>
                        <Input type='text' {...getFieldProps('telephone')} maxLength={10} onChange={(e) => handleNumericInput(e, setFieldValue)} />
                        <FormErrorMessage className='form-error-message'>
                          {errors.telephone}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl mt={3}>
                        <FormLabel>Contraseña</FormLabel>
                        <InputGroup>
                          <Input type='password' {...getFieldProps('password')} />
                          <InputRightElement as='button' type='button'>
                            <i className='bi bi-eye'></i>
                          </InputRightElement>
                        </InputGroup>
                        <FormErrorMessage className='form-error-message'>
                          {errors.password}
                        </FormErrorMessage>
                      </FormControl>

                      <Checkbox iconColor='white'>Generar contraseña automática</Checkbox>
                    </GridItem>
                    <GridItem>
                      {
                        values.secondary_role == SecondaryRoles.Personalizado && (
                          <>
                            <Heading variant='title' mb='20px'>Permisos de usuario</Heading>
                            <Checkbox
                              isChecked={allInvoiceChecks}
                              isIndeterminate={isIndeterminateInvoice}
                              onChange={(e) => setInvoiceChecks([e.target.checked])}
                            >
                              <b>Facturación</b>
                            </Checkbox>
                            <Stack pl={6}>
                              <Checkbox name='invoice' isChecked={invoiceChecks[0]} onChange={(e) => setInvoiceChecks([e.target.checked])}>Gestión de facturas</Checkbox>
                            </Stack>
                            <Checkbox
                              isChecked={allBasicChecks}
                              isIndeterminate={isIndeterminateBasic}
                              onChange={(e) => setBasicChecks([e.target.checked, e.target.checked, e.target.checked, e.target.checked, e.target.checked, e.target.checked])}
                            >
                              <b>Básico</b>
                            </Checkbox>
                            <Stack pl={6}>
                              <Checkbox name='guides' isChecked={basicChecks[0]} onChange={(e) => setBasicChecks([e.target.checked, basicChecks[1], basicChecks[2], basicChecks[3], basicChecks[4], basicChecks[5]])}>Cotizador</Checkbox>
                              <Checkbox name='tracking' isChecked={basicChecks[1]} onChange={(e) => setBasicChecks([basicChecks[0], e.target.checked, basicChecks[2], basicChecks[3], basicChecks[4], basicChecks[5]])}>Realizar guías</Checkbox>
                              <Checkbox name='recollection' isChecked={basicChecks[2]} onChange={(e) => setBasicChecks([basicChecks[0], basicChecks[1], e.target.checked, basicChecks[3], basicChecks[4], basicChecks[5]])}>Programar recolecciones</Checkbox>
                              <Checkbox name='settings' isChecked={basicChecks[3]} onChange={(e) => setBasicChecks([basicChecks[0], basicChecks[1], basicChecks[2], e.target.checked, basicChecks[4], basicChecks[5]])}>Rastreo</Checkbox>
                              <Checkbox name='covery' isChecked={basicChecks[4]} onChange={(e) => setBasicChecks([basicChecks[0], basicChecks[1], basicChecks[2], basicChecks[3], e.target.checked, basicChecks[5]])}>Mi configuración</Checkbox>
                              <Checkbox name='quoter' isChecked={basicChecks[5]} onChange={(e) => setBasicChecks([basicChecks[0], basicChecks[1], basicChecks[2], basicChecks[3], basicChecks[4], e.target.checked])}>Cobertura</Checkbox>
                            </Stack>
                            <Checkbox
                              isChecked={allRegisterChecks}
                              isIndeterminate={isIndeterminateRegister}
                              onChange={(e) => setRegisterChecks([e.target.checked])}
                            >
                              <b>Altas</b>
                            </Checkbox>
                            <Stack pl={6}>
                              <Checkbox name='user_register' isChecked={registerChecks[0]} onChange={(e) => setRegisterChecks([e.target.checked])}>Alta usuarios secundarios</Checkbox>
                            </Stack>
                          </>
                        )
                      }
                    </GridItem>
                  </Grid>
                  <Stack flexDir={['column-reverse', 'column-reverse', 'row', 'row']} mt={3} justifyContent='space-between'>
                    <Button variant='secondary'>Cancelar</Button>
                    <Stack flexDir={['column-reverse', 'column-reverse', 'row', 'row']}>
                      <Button variant='secondary'>Reenviar credenciales</Button>
                      <Button type='submit' variant='primary'>{(user_id) ? 'Editar' : 'Crear'} usuario</Button>
                    </Stack>
                  </Stack>
                </Form>
              )}
            </Formik>
          )
          : 
          <Stack mt={10}>
            <Skeleton height='30px' />
            <Skeleton mt={3} height='30px' />
            <Skeleton mt={3} height='30px' />
            <Skeleton mt={3} height='30px' />
            <Skeleton mt={3} height='30px' />
            <Skeleton mt={3} height='30px' />
            <Skeleton mt={3} height='30px' />
          </Stack>
      }
    </>
  )
}
