import React from 'react'
import { useSelector } from 'react-redux'

const useDeparments = () => {

    const { token } = useSelector((state) => state.user.data);
    const base_url = `${process.env.REACT_APP_API_URL}/api/v1/departments`;
    
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
    
    const getDepartments = async () => {
        const res = await fetch( base_url, { 
            method: 'GET',
            headers: {
                ...headers,
                'Authorization': `Bearer ${token}`
            }
        });

        if(res.status == 200) {
            const data = await res.json();
            return data
        } else {
            return {
                errors: {
                    global: 'Hubo un error de servidor.'
                }
            }
        }
    }

    return {
        getDepartments
    }
}

export default useDeparments;