import React, { useState } from 'react'
import { Box, Button, Flex, Heading, Image, Text, VStack } from '@chakra-ui/react'
import logo from '../../assets/images/commons/logo.png';
import SignUpForm from '../../components/login/SignUpForm';
import { Link } from 'react-router-dom';

const SignUpPage = () => {

    const [completed, setCompleted] = useState(false);

    return (
        <Box
            display='flex'
            justifyContent='center'
        >
            <Box
                flex={1}
                maxW='600px'
            >
                <VStack
                    w='100%'
                >
                    <Image w='300px' src={logo} />
                    <VStack
                        alignItems='start'
                        w='100%'
                    >
                        <Heading size='md' color='white'>Solicitud de alta</Heading>
                        {
                            !completed
                                ? <SignUpForm setCompleted={setCompleted} />
                                :
                                <>
                                    <Text color='white'>Tu solicitud ha sido completada, se te estará notificando por vía correo electrónico si tu solicitud ha sido aceptada.</Text>
                                    <Flex flexDir={['column', 'column', 'row', 'row']} w='100%' mt={2} gap={2}>
                                        <Button variant='primary' w={['100%', '100%', 'auto', 'auto']} mt={[2, 2, 0, 0]} onClick={() => setCompleted(false)}>Alta nueva</Button>
                                        <Link to='/ingresar'>
                                            <Button variant='tertiary' w={['100%', '100%', 'auto', 'auto']} mt={[2, 2, 0, 0]}>Regresar</Button>
                                        </Link>
                                    </Flex>
                                </>
                        }

                    </VStack>
                </VStack>
            </Box>
        </Box>
    )
}

export default SignUpPage