import { Box, Button, Checkbox, Divider, HStack, Heading, Input, Menu, MenuButton, MenuDivider, MenuGroup, MenuItem, MenuList, Select, SimpleGrid, Skeleton, Stack, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useAddresses } from '../../../hooks/useAddresses'
import Paginator from '../../../components/partials/Paginator'
// import Paginator from '../../../components/partials/Paginator'

  const AddressesPage = () => {

  const navigate = useNavigate();

  const { getAddreses, deleteAddress, getAddress, toggleFavoriteAddress } = useAddresses();

  const [addresses, setAddresses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [ paginator, setPaginator ] = useState(null);
  const [ currentUrl, setCurrentUrl ] = useState(null);
  const [ loading, setLoading ] = useState(true);

  const [selectedSupplier, setSelectedSupplier] = useState();
  const [filters, setFilters] = useState();
  const [hidden, setHidden] = useState(true);

  const [ searchParams, setSearchParams ] = useSearchParams();

  const handleAddressFilter = (e) => {
    const { name, type, checked, value } = e.target;

    const inputValue = type === 'checkbox' ? (checked ? value : '') : value;

    const newParams = new URLSearchParams(searchParams)

    if(inputValue !== '') {
      newParams.set(name, inputValue);
      setHidden(false);
    } else {
      newParams.delete(name);
      setHidden(true);
    }

    setSearchParams(newParams);
  }

  const handleCleanFilters = () => {
    setSearchParams({});
    setHidden(true);
  }

  const handleSelectChange = (e) => {
    setSelectedSupplier(e.target.value);
  }

  const toggleFavorite = async (id, { setErrors = () => {} } = {}) => {
    const data = await toggleFavoriteAddress(id);

    if (data.success) {
      setAddresses(prevStateAddress => {
        return prevStateAddress.map(address =>
          address.id === id ? { ...address, is_favorite: !address.is_favorite } : address
        );
      });
    } else {
        if (data.errors) {
            setErrors(data.errors);
        } else {
            setErrors({
            global: 'Hubo un error en general.'
            
            });
        }
    }
  };

  const handleEditAddress = async (id) => {

    try {
      let res = await getAddress(id);
      if (!res) {
        alert("Error getting the information of this address");
        return;
      }
      navigate(`/direccion/${id}`);
    } catch (err) {
      console.error(err);
    }
  };

  const handleDeleteAddress = async (id) => {
    const {success, message} = await deleteAddress(id);
        if(success) {
            setAddresses(() => addresses.filter((address) => id !== address.id));
        } else {
            alert(message);
        }
  }

  useEffect(() => {
    getAddreses(currentUrl, searchParams)
      .then(({ success, data, errors }) => {
        if (success) {
          setAddresses(data.data);
          setPaginator(data);
          setIsLoading(false);
        } else {
          alert(errors);
        }
      });
  }, [currentUrl, searchParams]);

  return (
    <>
      <Heading variant='title'>Mis Direcciones</Heading>
      <Divider my={2} />
      <HStack justifyContent='space-between'>
      <Button variant='primary' onClick={() => navigate('/direccion')}>Agregar dirección</Button>
    <Menu closeOnSelect={false}>
      <HStack>
        <Button variant='secondary'>Exportar</Button>
        <MenuButton 
          hidden={hidden}
          onClick={handleCleanFilters}
        >
          <Box>
            <Button>
              Limpiar Filtros
            </Button>
          </Box>
        </MenuButton>
        <MenuButton as={Button}>
          <p>Filtrar por <i class="bi bi-caret-down-fill"></i></p>
        </MenuButton>
      </HStack>
      <MenuList>
        <MenuGroup title='Tipo de dirección'>
          <MenuItem>
            <Checkbox
                value={'1'}
                name='address_type[]s'
                onChange={handleAddressFilter}
                iconColor={'white'}
            >Fiscal
            </Checkbox>
          </MenuItem>
          <MenuItem>
            <Checkbox
              value={'2'}
              name='address_type[]'
              onChange={handleAddressFilter}
              iconColor={'white'}
            >Origen
            </Checkbox>
          </MenuItem>
          <MenuItem>
            <Checkbox
              value={'3'}
              name='address_type[]'
              onChange={handleAddressFilter}
              iconColor={'white'}
            >Destino
            </Checkbox>
          </MenuItem>
        </MenuGroup>
        <MenuDivider />
        <MenuGroup title='Fecha'>
          <MenuItem>
          <Box mr='5'>
              <Text mb='8px'>Inicio </Text>
              <Input
                onClick={e => e.stopPropagation()}
                placeholder="Select Date and Time"
                value={filters?.start_date}
                name='start_date'
                size="md"
                onChange={handleAddressFilter}
                type="date"
              />
            </Box>
            <Box>
              <Text mb='8px'>Fin</Text>
              <Input
                onClick={e => e.stopPropagation()}
                placeholder="Select Date and Time"
                value={filters?.end_date}
                name='end_date'
                size="md"
                onChange={handleAddressFilter}
                type="date"
              />
            </Box>
          </MenuItem>
        </MenuGroup>
        <MenuDivider />
        <MenuGroup title='Búsqueda'>
          <MenuItem>
            <Select
              onClick={e => e.stopPropagation()}
              onChange={handleSelectChange}
              placeholder='Selecciona...'
            >
              <option value='vatnum'>RFC</option>
              <option value='telephone'>Teléfono</option>
              <option value='address'>Dirección</option>
            </Select>
          </MenuItem>
          <MenuItem>
            <Input
              disabled={!selectedSupplier}
              onClick={e => e.stopPropagation()}
              placeholder="Filtrar..."
              onChange={handleAddressFilter}
              name={selectedSupplier}
              mb={4}
            />
          </MenuItem>
        </MenuGroup>
      </MenuList>
    </Menu>
  </HStack>
      
    {
      !isLoading
        ?
      <>
        
      <Box mt={5}>
        <Table>
          <Thead>
            <Tr>
              <Th>Tipo dirección</Th>
              <Th>Razón Social</Th>
              <Th>RFC</Th>
              <Th>Domicilio</Th>
              <Th>Teléfono</Th>
              <Th>Referencias</Th>
              <Th>Acciones</Th>
            </Tr>
          </Thead>

          <Tbody>
            {
              addresses && addresses.map && addresses.map((ad) => (
              <Tr key={ad.id}>
                <Td>{ad.address_type.address_type}</Td>
                <Td>{ad.company_name}</Td>
                <Td>{ad.vatnum}</Td>
                <Td>{ad.street}, #{ad.num_ext}, {ad.postal_code}, {ad.suburb}, {ad.city}, {ad.state}</Td>
                <Td>{ad.telephone}</Td>
                <Td>{ad.state}</Td>
                  <Td>
                    <SimpleGrid columns={2}>
                      <Text 
                        onClick={() => toggleFavorite(ad.id)}
                        display='flex' 
                        alignItems='center' 
                        fontSize='24px' 
                        as='button'>
                        {
                          ad.is_favorite === true ? <Text color='orange.400'><i class="bi bi-star-fill"></i></Text> : <i class="bi bi-star"></i>
                        }
                      </Text>
                      <Menu>
                        <MenuButton as={Button}>
                          <i className='bi bi-three-dots-vertical'></i>
                        </MenuButton>
                        <MenuList>
                          <MenuItem onClick={() => handleEditAddress(ad.id)} icon={<i className='bi bi-pencil-square' style={{ fontSize:'18px' }}></i>}>Editar</MenuItem>
                          <MenuItem onClick={() => handleDeleteAddress(ad.id)} icon={<i className='bi bi-trash-fill' style={{ fontSize:'18px' }}></i>}>Eliminar</MenuItem>
                        </MenuList>
                      </Menu>
                    </SimpleGrid>
                  </Td>
              </Tr>
            ))}

          </Tbody>
        </Table>
        {
          !isLoading && <Paginator setCurrentUrl={setCurrentUrl} paginator={paginator} />
        }
      </Box>
    </>
    :
    <Stack mt={10}>
      <Skeleton height='30px' />
      <Skeleton mt={3} height='30px' />
      <Skeleton mt={3} height='30px' />
      <Skeleton mt={3} height='30px' />
      <Skeleton mt={3} height='30px' />
      <Skeleton mt={3} height='30px' />
      <Skeleton mt={3} height='30px' />
    </Stack>
    }
    </>
  )
}

export default AddressesPage