import { Form, Formik, getIn } from 'formik'
import * as Yup from 'yup';
import React, { useEffect, useState } from 'react'
import { Button, Flex, FormControl, FormErrorMessage, FormLabel, Grid, Input, Select, Spacer } from '@chakra-ui/react';
import useInvoice from '../../../hooks/useInvoice';
import useCreditRequest from '../../../hooks/useCreditRequest';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { syncCreditRequestInfo } from '../../../redux/slices/creditRequestSlice';

const InvoiceDataForm = ({
    nextStep,
    currentStep
}) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { credit_id, step }  = useSelector((state) => state.credit_request);
    const { fiscal_data } = useSelector((state) => state.credit_request.info);

    const [invoiceFields, setInvoiceFields] = useState(null);
    const { getInvoiceFields } = useInvoice();
    const { addFiscalDataToCR } = useCreditRequest();

    useEffect(() => {
        (async () => {
            const result = await getInvoiceFields();
            setInvoiceFields(result);
        })();
    }, []);

    return (
        (!invoiceFields)
            ?
            <p>Cargando...</p>
            :
            <Formik
                initialValues={{
                    payment_way_id: fiscal_data?.payment_way_id || '',
                    payment_method_id: fiscal_data?.payment_method_id || '',
                    tax_regime_id: fiscal_data?.tax_regime_id || '',
                    uso_cfdi_id: fiscal_data?.uso_cfdi_id || '',
                    complement_email: fiscal_data?.complement_email || ''
                }}
                validationSchema={Yup.object({
                    payment_method_id: Yup.string().required(),
                    payment_way_id: Yup.string().required(),
                    tax_regime_id: Yup.string().required(),
                    uso_cfdi_id: Yup.string().required(),
                    complement_email: Yup.string().email().required()
                })}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={async (values, { setErrors }) => {
                    const {success, errors} = await addFiscalDataToCR(values, credit_id);

                    if(success) {
                        dispatch(syncCreditRequestInfo());
                        navigate('/solicitudes-de-credito/procedimiento-de-pago');
                    } else {
                        setErrors(errors)
                    }
                }}
            >
                {({ getFieldProps, errors }) => (
                    <Form>
                        <Grid templateColumns={['repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(2, 1fr)']} gap={2}>
                            <FormControl isInvalid={errors.payment_method_id}>
                                <FormLabel><span style={{ color: 'red' }}>*</span> Método de pago</FormLabel>
                                <Select {...getFieldProps('payment_method_id')}>
                                    <option>Selecciona un método de pago</option>
                                    {
                                        invoiceFields.payment_methods.map((field) => (<option key={field.id} value={field.id}>{field.id} - {field.description}</option>))
                                    }
                                </Select>
                                <FormErrorMessage className='form-error-message'>
                                    {errors.payment_method_id}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={errors.payment_way_id}>
                                <FormLabel><span style={{ color: 'red' }}>*</span> Forma de pago</FormLabel>
                                <Select {...getFieldProps('payment_way_id')}>
                                    <option>Seleccion forma de pago</option>
                                    {
                                        invoiceFields.payment_ways.map((field) => (<option key={field.id} value={field.id}>{field.id} - {field.description}</option>))
                                    }
                                </Select>
                                <FormErrorMessage className='form-error-message'>
                                    {errors.payment_way_id}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={errors.uso_cfdi_id}>
                                <FormLabel><span style={{ color: 'red' }}>*</span> Uso de CFDI</FormLabel>
                                <Select {...getFieldProps('uso_cfdi_id')}>
                                    <option>Selecciona un uso de CFDI</option>
                                    {
                                        invoiceFields.uso_cfdi.map((field) => (<option key={field.id} value={field.id}>{field.id} - {field.description}</option>))
                                    }
                                </Select>
                                <FormErrorMessage className='form-error-message'>
                                    {errors.uso_cfdi_id}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={errors.tax_regime_id}>
                                <FormLabel><span style={{ color: 'red' }}>*</span> Régimen fiscal</FormLabel>
                                <Select {...getFieldProps('tax_regime_id')}>
                                    <option>Selecciona un régimen</option>
                                    {
                                        invoiceFields.tax_regimes.map((field) => (<option key={field.id} value={field.id}>{field.id} - {field.description}</option>))
                                    }
                                </Select>
                                <FormErrorMessage className='form-error-message'>
                                    {errors.tax_regime_id}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={errors.complement_email}>
                                <FormLabel><span style={{ color: 'red' }}>*</span> Correo para enviar complementos de pago</FormLabel>
                                <Input type='text' {...getFieldProps('complement_email')} />
                                <FormErrorMessage className='form-error-message'>
                                    {errors.complement_email}
                                </FormErrorMessage>
                            </FormControl>
                        </Grid>
                        <Flex mt={2} flexDir={['column-reverse', 'row', 'row', 'row']}>
                            <Button onClick={() => nextStep(currentStep - 1)} variant='secondary'>Regresar</Button>
                            <Spacer my={1} />
                            <Button type='submit' variant='primary'>Continuar</Button>
                        </Flex>
                    </Form>
                )}
            </Formik>
    )
}

export default InvoiceDataForm