import { useSelector } from 'react-redux';

export const useTemplates = () => {

    const base_url = `${process.env.REACT_APP_API_URL}/api/v1/templates`;

    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }

    const { token } = useSelector((state) => state.user.data);

    const getTemplates = async (url, filters = null) => {
        const new_url = `${base_url}/content_template`;

        if(url) {
            url = filters === '' ? url : (!filters ? url : `${url}&` + filters.toString());
        } else {
            url = filters === '' ? new_url : (!filters ? new_url : `${new_url}?` + filters.toString());
        }

        const res = await fetch(url, {
            method: 'GET',
            headers: {
                ...headers,
                'Authorization': `Bearer ${token}`
            }
        });
        const data = await res.json();
        return data;
    }

    const getTemplate = async (id) => {
        const res = await fetch(`${base_url}/content_template/${id}`, {
            method: 'GET',
            headers: {
                ...headers,
                'Authorization': `Bearer ${token}`,
            }
        });
        const { data } = await res.json();
        return data;
    }

    const createTemplate = async (template) => {
        const res = await fetch(`${base_url}/content_template`, {
            method: 'POST',
            body: JSON.stringify(template),
            headers: {
                ...headers,
                'Authorization': `Bearer ${token}`
            }
        });

        if(res.status === 200)  {
            const data = await res.json();
            return data;
        } else { 
            return 0;
        }
    }

    const updateTemplate = async (body, tp_id) => {
        const res = await fetch(`${base_url}/content_template/${tp_id}`, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                ...headers,
                'Authorization': `Bearer ${token}`
            }
        });
        if(res.status == 200) {
            const data = await res.json();
            return data;
        } else {
            return {
                success: false,
                errors: {
                    global: 'Hubo un error con la petición o con el servidor, intenta de nuevo.'
                }
            }
        }
    }

    const deleteTemplate = async (id) => {
        const res = await fetch(`${base_url}/content_template/${id}`, {
            method: 'DELETE',
            headers: {
                ...headers,
                'Authorization': `Bearer ${token}`
            }
        });
        const data = await res.json();
        return data; 
    }

    const getPackagesTypes = async () => {
        const res = await fetch(`${base_url}/packages_types`, {
            method: "GET",
            headers: {
                ...headers,
                'Authorization': `Bearer ${token}`
            }
        });
        const data = await res.json();
        return data; 
    }

    const toggleFavoriteTemplate = async (id) => {
        const res = await fetch(`${base_url}/content_template/toggle_favorite/${id}`, {
            method: 'POST',
            headers: {
                ...headers,
                'Authorization': `Bearer ${token}`
            }
        });

        if(res.status === 200)  {
            const data = await res.json();
            return data;
        } else { 
            return 0;
        }
    }

  return {
    getTemplates,
    getTemplate,
    updateTemplate,
    deleteTemplate,
    createTemplate,
    getPackagesTypes,
    toggleFavoriteTemplate
  };
}
