import { Badge } from '@chakra-ui/react'
import React from 'react'

const BadgeStatusCR = ({
    statusId,
    status
}) => {
    const color = {
        1: 'default',
        2: 'blue',
        3: 'yellow',
        4: 'green',
        5: 'red',
        6: 'green',
        7: 'red',
    }

    return <Badge colorScheme={color[statusId]}>{status}</Badge>
}

export default BadgeStatusCR