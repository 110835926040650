export const colors =
{
    primary: {
        50: "#CFEADC",
        100: "#BBE0D1",
        200: "#A6D6C8",
        300: "#92CCC3",
        400: "#7EC2BF",
        500: "#6AB1B7",
        600: "#569BAD",
        700: "#4282A2",
        800: "#2F6896",
        900: "#1C4B8B",
    },
    secondary: {
        50: "#F8C1C2",
        100: "#F6AFB0",
        200: "#F49D9E",
        300: "#F28C8C",
        400: "#F07A7B",
        500: "#EE6869",
        600: "#EC5757",
        700: "#E94545",
        800: "#E73434",
        900: "#E52322",
        hover: "#c62020"
    }
};