import {
  Box,
  Button,
  Card,
  CardBody,
  Center,
  Checkbox,
  Divider,
  Grid,
  GridItem,
  HStack,
  Heading,
  Input,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";

export const PendingInvoicePage = () => {
  return (
    <>
      <Heading variant={"title"}>Mis facturas pendientes</Heading>
      <Divider my={2} />
      <Text fontSize="24px" fontWeight="bold" my={4}>
        Estadisticas
      </Text>
      <Grid templateColumns={{ xl: "repeat(3, 1fr)", lg: "repeat(3, 1fr)", md: "repeat(2, 1fr)", sm: "repeat(1, 1fr)"}} gap={5}>
        <Card bg="green.100">
          <Heading size={{base: "3xl", md: "xl"}} ms={5} mt={5}>
            Total
          </Heading>
          <CardBody>
            <Grid templateColumns="repeat(4, 1fr)" display={{xl: 'grid', lg: 'flex'}} flexDirection={{md: "column"}}>
              <Text fontSize={{ xl: "60px", lg: "50px", md: "30px", base: "60px" }} hidden={{base: 'true', md: 'false', lg: 'false'}} >
                <i class="bi bi-cash-coin"></i>
              </Text>
              <GridItem>
                <Heading size={{ base: "xl", md: "md" }}>Cantidad</Heading>
                <Text
                  fontSize={{ xl: "40px", lg: "30px", md: "30px", base: "40px" }}
                  fontWeight="bold"
                >
                  30
                </Text>
              </GridItem>
              <Center>
                <Divider orientation="vertical" borderColor="gray.400" />
              </Center>
              <GridItem>
                <Heading  size={{ base: "xl", md: "md" }}>Monto</Heading>
                <Text
                  fontSize={{ xl: "40px", lg: "30px", md: "30px", base: "40px" }}
                  fontWeight="bold"
                  me={5}
                >
                  $1,000,000
                </Text>
              </GridItem>
            </Grid>
          </CardBody>
        </Card>

        <Card bg="orange.100">
          <Heading ms={5} mt={5}>
            Por vencer
          </Heading>
          <CardBody>
            <Grid templateColumns="repeat(4, 1fr)" display={{xl: 'grid', lg: 'flex'}} flexDirection={{md: "column"}}>
              <Text fontSize={{ xl: "60px", lg: "50px", md: "30px" }}>
                <i class="bi bi-exclamation-triangle"></i>
              </Text>
              <GridItem>
                <Heading size="md">Cantidad</Heading>
                <Text
                  fontSize={{ xl: "40px", lg: "30px", md: "30px", sm: "10px" }}
                  fontWeight="bold"
                >
                  15
                </Text>
              </GridItem>
              <Center>
                <Divider orientation="vertical" borderColor="gray.400" />
              </Center>
              <GridItem>
                <Heading size="md">Monto</Heading>
                <Text
                  fontSize={{ xl: "40px", lg: "30px", md: "30px", sm: "10px" }}
                  fontWeight="bold"
                  me={5}
                >
                  $750,000
                </Text>
              </GridItem>
            </Grid>
          </CardBody>
        </Card>

        <Card bg="red.100">
          <Heading ms={5} mt={5}>
            Vencido
          </Heading>
          <CardBody>
            <Grid templateColumns="repeat(4, 1fr)" display={{xl: 'grid', lg: 'flex'}} flexDirection={{md: "column"}}>
              <Text fontSize={{ xl: "60px", lg: "50px", md: "30px" }}>
                <i class="bi bi-calendar-x"></i>
              </Text>
              <GridItem>
                <Heading size="md">Cantidad</Heading>
                <Text
                  fontSize={{ xl: "40px", lg: "30px", md: "30px", sm: "10px" }}
                  fontWeight="bold"
                >
                  10
                </Text>
              </GridItem>
              <Center>
                <Divider orientation="vertical" borderColor="gray.400" />
              </Center>
              <GridItem>
                <Heading size="md">Monto</Heading>
                <Text
                  fontSize={{ xl: "40px", lg: "30px", md: "30px", sm: "10px" }}
                  fontWeight="bold"
                  me={5}
                >
                  $300,000
                </Text>
              </GridItem>
            </Grid>
          </CardBody>
        </Card>
      </Grid>
      <Divider my={4} />
      <HStack justifyContent={"flex-end"}>
        <Menu closeOnSelect={false}>
          <HStack>
            <Button variant='secondary'>Exportar</Button>
            <MenuButton>
              <Box>
                <Button>Limpiar Filtros</Button>
              </Box>
            </MenuButton>
            <MenuButton as={Button}>
              <p>
                Filtrar por <i class="bi bi-caret-down-fill"></i>
              </p>
            </MenuButton>
          </HStack>
          <MenuList>
            <MenuGroup title="Estatus">
              <MenuItem>
                <Checkbox value={"1"} iconColor={"white"}>
                  Pendiente
                </Checkbox>
              </MenuItem>
              <MenuItem>
                <Checkbox value={"2"} iconColor={"white"}>
                  Aceptada
                </Checkbox>
              </MenuItem>
              <MenuItem>
                <Checkbox value={"3"} iconColor={"white"}>
                  Rechazada
                </Checkbox>
              </MenuItem>
            </MenuGroup>
            <MenuDivider />
            <MenuGroup title="Fecha de vencimiento">
              <MenuItem>
                <Box mr="5">
                  <Text mb="8px">Inicio </Text>
                  <Input
                    onClick={(e) => e.stopPropagation()}
                    placeholder="Select Date and Time"
                    size="md"
                    type="date"
                  />
                </Box>
                <Box>
                  <Text mb="8px">Fin</Text>
                  <Input
                    onClick={(e) => e.stopPropagation()}
                    placeholder="Select Date and Time"
                    name="end_date"
                    size="md"
                    type="date"
                  />
                </Box>
              </MenuItem>
            </MenuGroup>
            <MenuDivider />
            <MenuGroup title="Factura">
              <MenuItem>
                <Input
                  onClick={(e) => e.stopPropagation()}
                  placeholder="Filtrar..."
                  mb={4}
                />
              </MenuItem>
            </MenuGroup>
          </MenuList>
        </Menu>
      </HStack>
      <Text fontSize="24px" fontWeight="bold">
        Pendientes
      </Text>
      <Box mt={5}>
        <Table>
          <Thead>
            <Tr>
              <Th></Th>
              <Th>Num. Factura</Th>
              <Th>Total</Th>
              <Th>Abonado</Th>
              <Th>Saldo</Th>
              <Th>Fecha de vencimiento</Th>
              <Th>Acciones</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td textAlign="center">
                <Checkbox size="lg" iconColor="white" />
              </Td>
              <Td>1</Td>
              <Td>1</Td>
              <Td>1</Td>
              <Td>1</Td>
              <Td>1</Td>
              <Td>
                <Button variant="primary">Pagar</Button>
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>
    </>
  );
};
