import { Box, Heading, Step, StepDescription, StepIcon, StepIndicator, StepNumber, StepSeparator, StepStatus, Stepper, useSteps } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'

const steps = [
    { title: 'Datos generales' },
    { title: 'Procedimiento de pago' },
    { title: 'Información del cliente' },
    { title: 'Documentación' },
    { title: 'Resumen' },
];

export const indexes = [
    '/solicitudes-de-credito/datos-fiscales',
    '/solicitudes-de-credito/procedimiento-de-pago',
    '/solicitudes-de-credito/informacion-del-cliente',
    '/solicitudes-de-credito/documentacion',
    '/solicitudes-de-credito/resumen',
    '/solicitudes-de-credito/gracias'
]


const NewCRLayout = () => {

    const { credit_id, step } = useSelector((state) => state.credit_request);
    const navigate = useNavigate();
    const location = useLocation();
    const { cr_id } = useParams();

    useEffect(() => {
        if (!credit_id && !cr_id) {
            navigate('/solicitudes-de-credito');
        }

        if (location.pathname !== '/solicitudes-de-credito' && location.pathname !== indexes[step] && !cr_id) {
            navigate(indexes[step]);
        }
    }, []);

    return (
        <Box>
            {
                (!cr_id) ? (
                    <Box 
                        py={5} w='100%' display='flex' justifyContent={['flex-start', 'flex-start', 'flex-start', 'center']} overflowX='scroll'
                        css={{
                            '&': {
                                direction: 'ltr'
                            },
                            '&::-webkit-scrollbar': {
                            width: '3px',
                            height: '3px'
                            },
                            '&::-webkit-scrollbar-track': {
                            width: '3px',
                            height: '3px'
                            },
                            '&::-webkit-scrollbar-thumb': {
                            background: 'red',
                            borderRadius: '24px',
                            },
                        }}
                    >
                        <Stepper minW={['100%', '100%', '100%', '1000px']} size='sm' index={step}>
                            {steps.map((step, index) => (
                                <Step key={index}>
                                    <StepIndicator>
                                        <StepStatus
                                            complete={<StepIcon />}
                                            incomplete={<StepNumber />}
                                            active={<StepNumber />}
                                        />
                                    </StepIndicator>

                                    <Box display={['none', 'none', 'none', 'block']} flexShrink='0'>
                                        <StepDescription>{step.title}</StepDescription>
                                    </Box>

                                    <StepSeparator />
                                </Step>
                            ))}
                        </Stepper>
                    </Box>
                )
                : <Heading variant='title'>Retroalimentación</Heading>
            }
            <Outlet />
        </Box>
    )
}


export default NewCRLayout