
import { Box, Button, Divider, HStack, Heading, Input, Menu, MenuButton, MenuDivider, MenuGroup, MenuItem, MenuList, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, SimpleGrid, Text, useDisclosure } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { SavedCardBox } from '../../../components/card/SavedCardBox';
import CardForm from '../../../components/invoice/CardForm';
import { useCards } from '../../../hooks/useCards';
import { colors } from '../../../themes/colors'
import Swal from 'sweetalert2';

export const MyCardsPage = () => {

    const { createCard, updateCard, getCards } = useCards();
    const [card, setCard] = useState(null);
    const [cards, setCards] = useState([]);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [ isLoading, setIsLoading ] = useState(true);


    const handleCreateCardWithToken = (values, { setErrors, setSubmitting }) => {
        window.OpenPay.setId(process.env.REACT_APP_OP_MERCHANT_ID);
        window.OpenPay.setApiKey(process.env.REACT_APP_OP_PUBLIC_KEY);
        window.OpenPay.setSandboxMode(true);

        let deviceDataId = window.OpenPay.deviceData.setup();
        let expirations = values.expiry.split("/");

        window.OpenPay.token.create({
            card_number: values.card_number.replaceAll(' ', ''),
            holder_name: values.holder_name,
            expiration_year: expirations[1],
            expiration_month: expirations[0],
            cvv2: values.cvv2
        }, (response) => {
            createCard({
                token_id: response.data.id,
                device_session_id: deviceDataId
            })
                .then( async (data) => {
                    if (data.success) {
                        onClose();
                        const result = await getCards();
                        setCards(result);
                    } else {
                        if (data.errors) {
                            setErrors(data.errors);
                        } else {
                            setErrors({
                                globals: 'Hubo un error en general.'
                            });
                        }
                    }
                })

                setSubmitting(false);
        }, (response) => {
            onClose();
            Swal.fire({
                title: 'Error',
                text: 'Ha habido un error al momento de intentar guardar tu tarjeta.',
                icon: 'error',
                confirmButtonColor: colors['secondary']['900'],
                confirmButtonText: 'Continuar'
            });

            setSubmitting(false);
        });
    }

    const handleUpdateCard = async(values, { setErrors }) => {
        let expirations = values.expiry.split("/");

        const data = await updateCard({
            holder_name: values.holder_name,
            expiration_year: expirations[1],
            expiration_month: expirations[0],
            cvv2: values.cvv2
        }, card);
        if (data.success) {
            onClose();
            const result = await getCards();
            setCards(result);
        } else {
            if (data.errors) {
                setErrors(data.errors);
            } else {
                setErrors({
                    global: 'Hubo un error en general'
                });
            }
        }
    }

    useEffect(() => {
        (async () => {
            const result = await getCards();
            setCards(result);
            setIsLoading(false);
        })();
    }, []);

    return (
        <>
            <Heading variant='title'>Tarjetas Guardadas</Heading>
            <Divider my={2} />
            <HStack justifyContent='space-between'>
                <Button variant='primary' onClick={onOpen}>Agregar nueva tarjeta</Button>
                <Modal isOpen={isOpen} onClose={() => onClose(setCard(null))}>

                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Datos de la tarjeta</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <CardForm
                                onSubmit={(card) ? handleUpdateCard : handleCreateCardWithToken}
                                cancelFunction={() => onClose(setCard(null))}
                                primaryButtonTitle={(card) ? 'Modificar' : 'Guardar'}
                                idCard={card}
                            />
                        </ModalBody>
                    </ModalContent>
                </Modal>
                <HStack>
                    <Button variant='secondary'>Exportar</Button>
                    <Menu closeOnSelect={false}>
                        <MenuButton as={Button}>
                            <p>Filtrar por <i class="bi bi-caret-down-fill"></i></p>
                        </MenuButton>
                        <MenuList>
                            <MenuGroup title='Fecha'>
                                <MenuItem>
                                    <Box mr='5'>
                                        <Text mb='8px'>Inicio </Text>
                                        <Input
                                            placeholder="Select Date and Time"
                                            size="md"
                                            type="datetime-local"
                                        />
                                    </Box>
                                    <Box>
                                        <Text mb='8px'>Fin</Text>
                                        <Input
                                            placeholder="Select Date and Time"
                                            size="md"
                                            type="datetime-local"
                                        />
                                    </Box>
                                </MenuItem>
                                <MenuDivider />
                            </MenuGroup>
                            <MenuGroup title='Búsqueda'>
                                <Input
                                    m='3'
                                    placeholder="Filtrar..."
                                    // value={filter}
                                    // onChange={e => setFilter(e.target.value)}
                                    mb={4}
                                />
                            </MenuGroup>
                        </MenuList>
                    </Menu>
                </HStack>
            </HStack>
            <>
                <SimpleGrid columns={{ xl: "4", lg: "3", md: "2", sm: "1" }} spacing='20px'>
                    <SavedCardBox isLoading={isLoading} onOpen={onOpen} setCard={setCard} cards={cards} setCards={setCards} />
                </SimpleGrid>
            </>
        </>
    )
}
