import React, { useEffect } from 'react'

const TestSocket = () => {

    useEffect(() => {
        const ws = new WebSocket("ws://localhost:8000/notifications/socket");

        ws.onopen = function() {
            console.log("Conectado");
        };
    }, []);

    return (
        <div>TestSocket</div>
    )
}

export default TestSocket