import { useSelector } from "react-redux";

export const useUser = () => {

    const base_url = `${process.env.REACT_APP_API_URL}/api/v1`;
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }

    const getUsers = async (url, token, filters) => {
        const new_url = url || `${base_url}/users`;

        if(url) {
            url = filters === '' ? url : (!filters ? url : `${url}&` + filters.toString());
        } else {
            url = filters === '' ? new_url : (!filters ? new_url : `${new_url}?` + filters.toString());
        }

        const res = await fetch(url, {
            method: 'GET',
            headers: {
                ...headers,
                'Authorization': `Bearer ${token}`
            }
        });

        if (res.ok) {
            const data = await res.json();
            return data;
        } else {
            return {
                errors: {
                    global: 'Hubo un error en la conexión con el sistema.'
                }
            }
        }
    }

    const getUserRoles = async (token) => {
        const res = await fetch(`${base_url}/users/roles`, {
            method: 'GET',
            headers: {
                ...headers,
                'Authorization': `Bearer ${token}`
            }
        });

        if (res.ok) {
            const { data } = await res.json();
            return data;
        } else {
            return {
                errors: {
                    global: 'Hubo un error en la conexión con el sistemel sistema.'
                }
            }
        }
    }

    const getSecondaryUserRoles = async (token) => {
        const res = await fetch(`${base_url}/users/secondary_roles`, {
            method: 'GET',
            headers: {
                ...headers,
                'Authorization': `Bearer ${token}`
            }
        });

        if (res.ok) {
            const { data } = await res.json();
            return data;
        } else {
            return {
                errors: {
                    global: 'Hubo un error en la conexión con el sistema.'
                }
            }
        }
    }

    const getUsersRequests = async (url, token) => {
        url = url || `${base_url}/users/requests`;
        const res = await fetch(url, {
            method: 'GET',
            headers: {
                ...headers,
                'Authorization': `Bearer ${token}`
            }
        });

        if (res.ok) {
            const data = await res.json();
            return data;
        } else {
            return {
                errors: {
                    global: 'Hubo un error en la conexión con el sistema.'
                }
            }
        }
    }

    const changeStatusToUserRequest = async (req_id, status_id, token) => {
        const res = await fetch(`${base_url}/users/requests/change_status/${req_id}`, {
            method: 'POST',
            body: JSON.stringify({
                status_id
            }),
            headers: {
                ...headers,
                'Authorization': `Bearer ${token}`
            }
        });

        if (res.ok) {
            const data = await res.json();
            return data;
        } else {
            return {
                errors: {
                    global: 'Ha habido un error con la conexión al servicio.'
                }
            }
        }
    }

    const loginUser = async (userData) => {
        let user = null;
        let errors = null;

        const res = await fetch(`${base_url}/authenticate`, {
            method: 'POST',
            body: JSON.stringify(userData),
            headers
        });

        const data = await res.json();

        if (data.success) {
            user = data.data;
        } else {
            if (data.errors) {
                errors = {
                    email: data.errors.email,
                    password: data.errors.password
                };
            } else {
                errors = {
                    global: data.message
                };
            }
        }

        return { user, errors };
    }

    const sendResetPasswordMail = async (email) => {
        let errors;

        const res = await fetch(`${base_url}/send_token_reset_password`, {
            method: 'POST',
            body: JSON.stringify({ email }),
            headers
        });

        const data = await res.json();

        if (!data.success) {
            if (data.errors) {
                errors = {
                    email: data.errors.email
                };
            } else {
                errors = {
                    global: data.message
                }
            }
        }

        return { errors }
    }

    const resetPassword = async (token, email, password, password_confirmation) => {
        let errors;

        const res = await fetch(`${base_url}/reset_password`, {
            method: 'POST',
            body: JSON.stringify({
                email,
                token,
                password,
                password_confirmation
            }),
            headers
        });

        const data = await res.json();

        if (!data.success) {
            if (data.errors) {
                errors = { ...data.errors }
            } else {
                errors = {
                    global: data.message
                }
            }
        }

        return { errors }
    }

    const updatePassword = async (token, passwords) => {
        let errors;
        const res = await fetch(`${base_url}/user/update_password`, {
            method: 'POST',
            body: JSON.stringify(passwords),
            headers: {
                ...headers,
                'Authorization': `Bearer ${token}`,
            }
        });

        const data = await res.json();

        if (!data.success) {
            errors = data.errors;
        }

        return { errors }
    }

    const getUserData = async (token, user_id = null) => {
        let user = null;
        let url = (!user_id) ? `${base_url}/user` : `${base_url}/user/${user_id}`;

        const res = await fetch(url, {
            method: 'GET',
            headers: {
                ...headers,
                'Authorization': `Bearer ${token}`
            }
        });

        if (res.status === 200) {
            const data = await res.json();
            user = data;
        }

        return user;
    }

    const updateUserData = async (token, newData, user_id = null) => {

        let url = (!user_id) ? `${base_url}/user` : `${base_url}/user/${user_id}`;

        const res = await fetch(url, {
            method: 'PUT',
            body: JSON.stringify(newData),
            headers: {
                ...headers,
                'Authorization': `Bearer ${token}`
            }
        });

        if (res.ok) {
            const data = res.json();

            return data;
        } else {
            return {
                errors: {
                    global: 'Hubo un error en la comunicación del servidor.'
                }
            }
        }
    }

    const updateSecondaryUser = async (token, body, user_id) => {
        const res = await fetch(`${base_url}/user/secondary/${user_id}`, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                ...headers,
                'Authorization': `Bearer ${token}`
            }
        });

        if (res.ok) {
            const data = res.json();

            return data;
        } else {
            return {
                errors: {
                    global: 'Hubo un error en la comunicación del servidor.'
                }
            }
        }
    }

    const createUser = async (token, userData, secondary = null) => {
        const res = await fetch(`${base_url}/user${!secondary ? '' : '/secondary'}`, {
            method: 'POST',
            body: JSON.stringify(userData),
            headers: {
                ...headers,
                'Authorization': `Bearer ${token}`
            }
        });

        if(res.ok) {
            const data = await res.json();
            return data;
        } else {
            return {
                errors: {
                    global: 'Hubo un error en la comunicación del servidor.'
                }
            }
        }
    }
    
    const updateProfilePic = async (token, body) => {
        const res = await fetch(`${base_url}/user/profile_pic`, {
            method: 'POST',
            body,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        if(res.ok) {
            const data = await res.json();
            return data;
        } else {
            return {
                errors: {
                    global: 'Hubo un error en la comunicación del servidor.'
                }
            }
        }
    }

    const deleteProfilePic = async (token) => {
        const res = await fetch(`${base_url}/user/profile_pic`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        if(res.ok) {
            const data = await res.json();
            return data;
        } else {
            return {
                errors: {
                    global: 'Hubo un error en la comunicación del servidor.'
                }
            }
        }
    }

    return {
        loginUser,
        sendResetPasswordMail,
        resetPassword,
        updatePassword,
        getUserData,
        getUsers,
        getUserRoles,
        getUsersRequests,
        getSecondaryUserRoles,
        changeStatusToUserRequest,
        updateUserData,
        updateSecondaryUser,
        createUser,
        updateProfilePic,
        deleteProfilePic
    };
}