import { Badge, Box, Button, Center, Divider, HStack, Heading, Input, Menu, MenuButton, MenuDivider, MenuGroup, MenuItem, MenuList, Select, Skeleton, Stack, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import useCreditRequest from '../../../hooks/useCreditRequest';
import { useDispatch, useSelector } from 'react-redux';
import { create, setCreditRequestInfo, setEditMode, setStep } from '../../../redux/slices/creditRequestSlice';
import Swal from 'sweetalert2';
import { colors } from '../../../themes/colors';
import moment from 'moment';
import { CRStatus } from '../../../enums/CRStatus';
import { Roles } from '../../../enums/Roles';
import Paginator from '../../../components/partials/Paginator';

const CRListPage = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [currentUrl, setCurrentUrl] = useState(null);
  const [paginator, setPaginator] = useState(null);
  const [creditRequests, setCreditRequests] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { role } = useSelector((state) => state.user.data);
  const [ searchParams, setSearchParams ] = useSearchParams();
  const [ hidden, setHidden ] = useState(true);
  const [ selectedSupplier, setSelectedSupplier ] = useState();

  const { getCreditRequests, createCreditRequest, getCreditRequestInfo } = useCreditRequest();

  const handleCRFilter = (e) => {
    const { name, value } = e.target;

    const newParams = new URLSearchParams(searchParams)

    if(value !== ''){ 
      newParams.set(name, value);
      setHidden(false);
    } else {
      newParams.delete(name);
      setHidden(true);
    }

    setSearchParams(newParams);
  };

  const handleSelectChange = (e) => {
    setSelectedSupplier(e.target.value);
  };

  const handleCleanFilters = () => {
    setSearchParams({});
    setHidden(true);
  };

  const badgeScheme = {
    '1': 'gray',
    '2': 'blue',
    '3': 'orange',
    '4': 'green',
    '5': 'red',
    '6': 'green',
    '7': 'red',
  };

  const handleCreateCreditRequest = async () => {
    const { success, data } = await createCreditRequest();

    if (success) {
      dispatch(create(data.credit_request_id));
      const cr = await getCreditRequestInfo(data.credit_request_id);

      dispatch(setCreditRequestInfo({
        credit_id: cr.id,
        info: cr.info
      }))

      navigate('/solicitudes-de-credito/datos-fiscales');
    } else {
      Swal.fire({
        title: 'Error',
        text: 'Ha habido un error en tu consulta',
        icon: 'error',
        confirmButtonColor: colors['primary']['900']
      });
    }
  }

  const handleEditCreditRequest = async (credit_id, step = 0) => {
    await dispatch(setEditMode(credit_id));
    await dispatch(setStep(step));
    navigate('/solicitudes-de-credito/datos-fiscales');
  }
  

  useEffect(() => {
    getCreditRequests(currentUrl, searchParams)
      .then((result) => {
        setCreditRequests(result.data);
        setPaginator(result);
        setIsLoading(false);
      });
  }, [currentUrl, searchParams]);


  return (
    <>
      <Heading variant='title'>Mis solicitudes de crédito</Heading>
      <Divider my={2} />
      <HStack
        justifyContent='space-between'
      >
        <Button variant='primary' onClick={handleCreateCreditRequest}>Generar nueva solicitud</Button>
        <Menu closeOnSelect={false}>
          <HStack>
            <Button variant='secondary'>Exportar</Button>
            <MenuButton 
              hidden={hidden}
              onClick={handleCleanFilters}
            >
              <Box>
                <Button>
                  Limpiar Filtros
                </Button>
              </Box>
            </MenuButton>
            <MenuButton as={Button}>
              <p>Filtrar por <i class="bi bi-caret-down-fill"></i></p>
            </MenuButton>
          </HStack>
          <MenuList>
            <MenuGroup title='Fecha'>
              <MenuItem>
                <Box mr='5'>
                  <Text mb='8px'>Inicio </Text>
                  <Input
                    onClick={e => e.stopPropagation()}
                    placeholder="Select Date and Time"
                    name='start_date'
                    onChange={handleCRFilter}
                    size="md"
                    type="date"
                    max={moment().format("YYYY-MM-DD")}
                  />
                </Box>
                <Box>
                  <Text mb='8px'>Fin</Text>
                  <Input
                    onClick={e => e.stopPropagation()}
                    placeholder="Select Date and Time"
                    name='end_date'
                    onChange={handleCRFilter}
                    size="md"
                    type="date"
                    // min={dateValue}
                  />
                </Box>
              </MenuItem>
            <MenuDivider />
            </MenuGroup>
            <MenuGroup title='Búsqueda'>
              <MenuItem>
                <Select
                  onClick={e => e.stopPropagation()}
                  onChange={handleSelectChange}
                  placeholder='Selecciona...'
                >
                  <option value='company_name'>Nombre</option>
                  <option value='id'>Numero de solicitud</option>
                </Select>
              </MenuItem>
              <MenuItem>
                <Input
                  disabled={!selectedSupplier}
                  onClick={e => e.stopPropagation()}
                  placeholder="Filtrar..."
                  onChange={handleCRFilter}
                  name={selectedSupplier}
                />
              </MenuItem>
              <MenuDivider />
              <MenuGroup title='Estatus'>
                <MenuItem>
                  <Select
                    onClick={e => e.stopPropagation()}
                    placeholder='Selecciona el tipo de estatus'
                    name='status_id'
                    onChange={handleCRFilter}
                    mb={4}
                  >
                    <option value='1'>Pendiente</option>
                    <option value='2'>Creada</option>
                    <option value='3'>Autorizada</option>
                    <option value='4'>Autorizada</option>
                    <option value='5'>Denegada</option>
                  </Select>
                </MenuItem>
              </MenuGroup>
            </MenuGroup>
          </MenuList>
        </Menu>
      </HStack>
      {
        !isLoading
          ?
          <>
            <Box mt={5}>
              <Table>
                <Thead>
                  <Tr>
                    <Th>No. Solicitud</Th>
                    <Th>Cliente</Th>
                    <Th>Empresa</Th>
                    <Th>Fecha de solicitud</Th>
                    <Th>Estatus</Th>
                    <Th>Acciones</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {creditRequests.map((cr) => (
                    <Tr key={cr.id}>
                      <Td>{cr.id}</Td>
                      <Td>{cr.user.name} {cr.user.last_name}</Td>
                      <Td>{cr.user.company_name}</Td>
                      <Td>{ moment(cr.created_at).format('D/MM/YYYY')}</Td>
                      <Td>
                        <Badge w='100px' rounded='15px' py={1} px={3} colorScheme={badgeScheme[cr.status.id]}><Center>{cr.status.status}</Center></Badge>
                      </Td>
                      <Td>
                        <Menu>
                          <MenuButton as={Button} hidden={(cr.status.id === CRStatus.Denegada)}>
                            <i className='bi bi-three-dots-vertical'></i>
                          </MenuButton>
                          <MenuList>
                            <MenuItem hidden={(cr.status.id !== 1)} onClick={() => handleEditCreditRequest(cr.id)} icon={<i className='bi bi-chevron-right'></i>}>Continuar</MenuItem>
                            <MenuItem hidden={(cr.status.id !== CRStatus.Creada || role.id !== Roles.SuperAdmin)} onClick={() => navigate(`/solicitudes-de-credito/retroalimentacion/${cr.id}`)} icon={<i className='bi bi-eye'></i>}>Revisar</MenuItem>
                            <MenuItem hidden={(cr.status.id !== CRStatus.Autorizada)} onClick={() => navigate(`/solicitudes-de-credito/previsualizacion/${cr.id}`)} icon={<i className='bi bi-check'></i>}>Ver propuesta</MenuItem>
                            <MenuItem hidden={(cr.status.id !== CRStatus.Creada && cr.status.id !== CRStatus.EnValidacion || role.id !== Roles.Cliente)} onClick={() => handleEditCreditRequest(cr.id, 4)} icon={<i className='bi bi-eye'></i>}>Previsualizar</MenuItem>
                          </MenuList>
                        </Menu>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
              {
                  !isLoading && <Paginator setCurrentUrl={setCurrentUrl} paginator={paginator} />
              }
            </Box>
          </>
          :
          <Stack mt={10}>
            <Skeleton height='30px' />
            <Skeleton mt={3} height='30px' />
            <Skeleton mt={3} height='30px' />
            <Skeleton mt={3} height='30px' />
            <Skeleton mt={3} height='30px' />
            <Skeleton mt={3} height='30px' />
            <Skeleton mt={3} height='30px' />
          </Stack>
      }
    </>
  )
}

export default CRListPage;