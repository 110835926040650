import { Box, Button, Center, Checkbox, Divider, FormControl, FormErrorMessage, FormLabel, Grid, GridItem, HStack, Heading, Image, Input, Select } from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTemplates } from '../../hooks/useTemplates'

export const TemplatesForm = () => {

    const { tp_id } = useParams();

    const { createTemplate, updateTemplate, getTemplate, getPackagesTypes } = useTemplates();
    const [ selectedPackageType, setSelectedPackageType ] = useState(null);


    const [ templateFields, setTemplateFields ] = useState(null);
    const [ packageType, setPackageType ] = useState([]);
    const [ animateClass, setAnimateClass ] = useState('');
    const [ selectImage, setSelectImage ] = useState();

    const navigate = useNavigate();

    const handleCreateTemplate = async (values, { setErrors }) => {
        const data = await createTemplate(values);

        if (data.success) {
            navigate('/mis-plantillas');
        } else {
            if (data.errors) {
                setErrors(data.errors);
            } else {
                setErrors({
                global: 'Hubo un error en general.'
                });
            }
        }
    };

    const handleUpdateTemplate = async (values, { setErrors }) => {
        const data = await updateTemplate(values, tp_id);
        if (data.success) {
            navigate('/mis-plantillas');
        } else {
            if (data.errors) {
                setErrors(data.errors);
            } else {
                setErrors({
                global: 'Hubo un error en general.'
                
                });
            }
        }
    };

    const handlePackageTypeChange = async (e, handleChange) => {
        handleChange(e);

        if (e.target.value === '') {
            setSelectedPackageType(null);
        } else {
            const selectedType = packageType.find(tp => tp.id.toString() === e.target.value);
            setSelectedPackageType(selectedType);
            setSelectImage(null);
            setAnimateClass('animate__animated animate__bounceOut');
            setTimeout(() => {
                setSelectedPackageType(selectedType);
                setAnimateClass('animate__animated animate__bounceIn');
            }); 
        }
        
        

    };

    const handleSelectImage = (name) => {

        if (selectedPackageType !== null ) {
            const newUrl = (selectedPackageType.full_path_image).replace('/general', `/${name}`);
            setSelectImage(newUrl);
            console.log(newUrl);
        } else {
            console.log('no hay nada')
        }
    }

    useEffect(() => {
        (async () => {
            
            if (tp_id) {
                const result = await getTemplate(tp_id);
                setTemplateFields(result);
            }
            
        })();
    }, []);

    useEffect(() => {
        (async () => {
            const result = await getPackagesTypes();
            setPackageType(result.data);
    
            if (tp_id && templateFields) {
                const selectedType = result.data.find(tp => tp.id.toString() === templateFields.package_type);
                setSelectedPackageType(selectedType);
            }
        })();
    }, [tp_id, templateFields]);

  return (
    (!templateFields && tp_id)
        ?
        <p>Cargando...</p>
        :
    <Formik
        initialValues={{
            package_type: templateFields?.package_type_data.id || '',
            name: templateFields?.name || '',
            sat_product_code: templateFields?.sat_product_code || '',
            content: templateFields?.content || '',
            length: templateFields?.length || '',
            height: templateFields?.height || '',
            width: templateFields?.width || '',
            weight: templateFields?.weight || '',
            security_seals: templateFields?.security_seals,
            is_stackable: templateFields?.is_stackable
        }}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={Yup.object({
            name: Yup.string().required('Este campo es necesario'),
        })}

        onSubmit={(tp_id) ? handleUpdateTemplate : handleCreateTemplate}
    >
        {({ values, getFieldProps, errors, handleChange }) => (
        <Form>
            {
                (errors.global) && <Box className='form-error-message' mb={2}>{errors.global}</Box>
            }
            <Heading variant='title'>
                {
                    (tp_id) ? 'Editar plantilla' : 'Crear Plantilla'
                }
                </Heading>
            <Divider my={2} />
            <HStack
                justifyContent='space-between'
            >
            </HStack>
            <Grid templateColumns='repeat(2, 1fr)' gap={4}>
                <GridItem colSpan={1}>
                    <FormControl isRequired mb={5} isInvalid={(errors.name)}>
                        <FormLabel>Nombre paquete</FormLabel>
                        <Input placeholder='' {...getFieldProps('name')} />
                        <FormErrorMessage className='form-error-message'>
                            {errors.name}
                        </FormErrorMessage>
                    </FormControl>
        
                    <FormControl mb={5} isInvalid={(errors.package_type)}>
                        <FormLabel>Tipo paquete</FormLabel>
                        <Select placeholder='Selecciona el tipo de paquete' {...getFieldProps('package_type')} value={values.package_type} onChange={(e) => handlePackageTypeChange(e, handleChange)}>
                            { 
                                packageType && packageType.map((tp) => <option value={tp.id} key={tp.id}>{tp.type}</option>) 
                            }
                        </Select>
                        <FormErrorMessage className='form-error-message'>
                            {errors.package_type}
                        </FormErrorMessage>
                    </FormControl>
        
                    <FormControl mb={5} isInvalid={(errors.sat_product_code)}>
                        <FormLabel>Código de producto SAT</FormLabel>
                        <Input placeholder='' {...getFieldProps('sat_product_code')}/>
                    </FormControl>

                    <FormControl mb={5} isInvalid={(errors.content)}>
                        <FormLabel>Contenido declarado</FormLabel>
                        <Input placeholder='' {...getFieldProps('content')}/>
                    </FormControl>
                    
                    <FormControl>
                    <FormLabel>Medidas del paquete</FormLabel>
                        <Grid templateColumns='repeat(3, 1fr)' gap={4}>
                            <GridItem colSpan={1}>
                                <FormControl mb={5} isInvalid={(errors.length)}>
                                    <FormLabel>Largo (cm)</FormLabel>
                                    <Input type='number' placeholder='' onClick={() => handleSelectImage('largo')} {...getFieldProps('length')}/>
                                    <FormErrorMessage className='form-error-message'>
                                        {errors.length}
                                    </FormErrorMessage>
                                </FormControl>
                            </GridItem>
                            <GridItem colSpan={1}>
                                <FormControl mb={5} isInvalid={(errors.width)}>
                                    <FormLabel>Ancho (cm)</FormLabel>
                                    <Input type='number' placeholder='' onClick={() => handleSelectImage('ancho')} {...getFieldProps('width')}/>
                                    <FormErrorMessage className='form-error-message'>
                                        {errors.width}
                                    </FormErrorMessage>
                                </FormControl>
                            </GridItem>
                            <GridItem colSpan={1}>
                                <FormControl mb={5} isInvalid={(errors.height)}>
                                    <FormLabel>Alto (cm)</FormLabel>
                                    <Input type='number' placeholder='' onClick={() => handleSelectImage('alto')} {...getFieldProps('height')}/>
                                    <FormErrorMessage className='form-error-message'>
                                        {errors.height}
                                    </FormErrorMessage>
                                </FormControl>
                            </GridItem>
                            <GridItem colSpan={1}>
                                <FormControl mb={5} isInvalid={(errors.weight)}>
                                    <FormLabel>Peso (kg)</FormLabel>
                                    <Input type='number' placeholder='' {...getFieldProps('weight')}/>
                                    <FormErrorMessage className='form-error-message'>
                                        {errors.weight}
                                    </FormErrorMessage>
                                </FormControl>
                            </GridItem>
                        </Grid>
                        <GridItem colSpan={1}>
                        <FormControl mb={5} isInvalid={errors.security_seals}>
                            <Checkbox
                                iconColor='white'
                                {...getFieldProps('security_seals')}
                                defaultChecked={values.security_seals}
                            >
                                ¿El paquete tiene sellos de seguridad?
                            </Checkbox>
                            <FormErrorMessage className='form-error-message'>
                                {errors.security_seals}
                            </FormErrorMessage>
                        </FormControl>
                        </GridItem>
                        <GridItem colSpan={1}>
                            <FormControl mb={5} isInvalid={(errors.is_stackable)}>
                                <Checkbox
                                    iconColor='white'
                                    {...getFieldProps('is_stackable')}
                                    defaultChecked={values.is_stackable}
                                >
                                    ¿El paquete es estibable?
                                </Checkbox>
                                <FormErrorMessage className='form-error-message'>
                                    {errors.is_stackable}
                                </FormErrorMessage>
                            </FormControl>
                        </GridItem>
                    </FormControl>
                </GridItem>
                <Center>
                    <GridItem colSpan={1} className={`${animateClass}`}>
                        {selectedPackageType && (
                            <Image 
                                className='animate__animated animate__pulse' 
                                boxSize='lg' 
                                src={((selectImage === null) || (selectImage === undefined)) ? selectedPackageType.full_path_image : selectImage}
                                alt={`Image for package type ${selectedPackageType.type}`} 
                            />
                        )}
                    </GridItem>
                </Center>
            </Grid>
            <Grid templateColumns='repeat(2, 1fr)' gap={4} mt={5}>
                <GridItem colSpan={1} display='flex' justifyContent='space-between'>
                    <Button variant='secondary' onClick={() => navigate('/mis-plantillas')}>Cancelar</Button>
                    <Button type='submit' variant='primary'>{(tp_id) ? 'Modificar' : 'Agregar'}</Button>
                </GridItem>
            </Grid>
        </Form>
        )}

    </Formik>
  )
}
