import { Box, Divider, VStack } from '@chakra-ui/react'
import React, { useState } from 'react'
import NavLinked, { NavLinkDropdown } from './NavLinked';
import LinkList from './LinkList';

const SideBarMenu = () => {

    const [open, setOpen] = useState(false);

    const toggleMenu = () => {
        setOpen(!open);
    }

    return (
        <Box display={['none', 'none', 'block', 'block']}>
            <nav>
                <VStack w={open ? '200px' : '50px'} h="calc(100vh - 70px)" transition='0.3s' borderWidth={1} borderColor='gray.200' alignItems={(open) ? 'start' : 'center'} justifyContent='start'>
                    <Box px='4px' pt='5px'>
                        <Box
                            alignItems='center'
                            borderColor='gray.300'
                            borderRadius='5px'
                            borderWidth={1}
                            cursor='pointer'
                            display='flex'
                            justifyContent='center'
                            h='40px'
                            w='40px'
                            onClick={toggleMenu}
                        >
                            <i className="bi bi-list" style={{ fontSize: '25px' }}></i>
                        </Box>
                    </Box>
                <Box display='flex' alignItems={(!open) && 'center'} flexDir='column' flex={1} py='2px' w='100%' overflow='scroll'
                    css={{
                        '&': {
                            direction: 'ltr'
                        },
                        '&::-webkit-scrollbar': {
                          width: '3px',
                        },
                        '&::-webkit-scrollbar-track': {
                          width: '3px'
                        },
                        '&::-webkit-scrollbar-thumb': {
                          background: 'red',
                          borderRadius: '24px',
                        },
                      }}
                >
                    <LinkList open={open} />
                </Box>
                <Divider opacity={1} />
                <Box px='10px' pb='20px'>
                    {
                        (open) && (
                            <>
                                <a href="#" className="nav-links-footer">Términos y condiciones</a><br />
                                <a href="#" className="nav-links-footer">Aviso de privacidad</a><br />
                            </>
                        )
                    }
                    <Box
                        my='5px'
                        w='40px'
                        h='40px'
                        borderRadius='40px'
                        bgColor='#24D366'
                        display='flex'
                        justifyContent='center'
                        alignItems='center'
                    >
                        <i className="bi bi-whatsapp" style={{ fontSize: '26px', color: 'white' }}></i>
                    </Box>
                    </Box>
                </VStack>
            </nav>
        </Box>
    )
}

export default SideBarMenu