import React, { useContext, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, HStack, Menu, MenuButton, MenuItem, MenuList, Select, SelectField, Stack, StatDownArrow, Text } from '@chakra-ui/react'
import { HeaderContext } from '../partials/Header';

const NavLinked = ({ href, icon, title, hide, id }) => {

  const {closeDrawer} = useContext(HeaderContext);

  const [isSelect, setIsSelect] = useState(null);

  const handleClick = (id) => {
    setIsSelect(id === isSelect ? null : id);
  };

  return (
    <NavLink 
      to={href} 
      onClick={closeDrawer} 
      className='menu-icon-box' 
      style={({ isActive }) =>
        isActive 
        ? 
        {
          fontWeight: "bold",
          color: "#1C4B8B",
        }
        :
        {
          fontWeight: "",
          color: "black",
        }
      }

    >
      {({isActive}) => (
        <HStack
          py={2}
          px='10px'
          onClick={() => handleClick(id)}
          style={{ backgroundColor: isActive ? '#DEEAF7' : ''}}
        >
          <i className={`${icon} menu-icon`}></i>
          {
            !hide && <Text fontSize='14px'>{title}</Text>
          }
        </HStack>
      )}
    </NavLink>
  )
}

export const NavLinkDropdown = ({ icon, title, hide, dropdownItems, menuIcon, color, fontSize, py, px , my, mx }) => {
  return (
    <>
      <Accordion allowToggle w='100%'>
        <AccordionItem border='none'>
          <AccordionButton 
            p={0}
            onClick={(e) => e.stopPropagation()}
          >
            <HStack 
              py={`${py}`} 
              px={`${px}`} 
              my={`${my}`} 
              mx={`${mx}`}
            >
              <Text fontSize={`${fontSize}`} color={`${color}`}><i className={`${icon} ${menuIcon}`}></i></Text>
              {
                !hide && <Text fontSize='14px'>{title}</Text>
              }
            </HStack>
            {
              !hide && <AccordionIcon />
            }
          </AccordionButton>
          {dropdownItems.map((item) => (
            <AccordionPanel 
              m={0}
              p={0}
              bg='gray.100'
            >
              <NavLink 
                to={item.href} 
                className='menu-icon-box'
                style={({ isActive }) =>
                  isActive 
                  ? 
                  {
                    fontWeight: "bold",
                    color: "#1C4B8B",
                  }
                  :
                  {
                    fontWeight: "",
                    color: "black",
                  }
                }
                
              >
              {({isActive}) => (
                <HStack
                  py={2}
                  px='10px'
                  style={{ backgroundColor: isActive ? '#DEEAF7' : ''}}
                >
                  <Text color={`${color}`}><i className={`${item.icon} ${item.menuIcon}`}></i></Text>
                  {
                    !hide && <Text fontSize='14px'>{item.title}</Text>
                  }
                </HStack>
              )}
              </NavLink>
            </AccordionPanel>
          ))}
        </AccordionItem>
      </Accordion>
    </>
  )
}

export default NavLinked