import React, { useEffect, useState } from "react";
import {
  Heading,
  Divider,
  SimpleGrid,
  Grid,
  GridItem,
  FormControl,
  FormLabel,
  Input,
  Flex,
  Card,
  CardBody,
  CardHeader,
  Button,
  TableContainer,
  Stack,
  Skeleton,
  Table,
  Alert,
  AlertIcon,
  Th,
  Tr,
  Tbody,
  Td,
  Tfoot,
  Select,
  FormErrorMessage,
  Center,
  Image,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { useTemplates } from "../../hooks/useTemplates";

export const QuoterPage = () => {
  const navigate = useNavigate();
  const { getPackagesTypes } = useTemplates();
  const [isValues, setIsValues] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [templateFields, setTemplateFields] = useState(null);
  const [packageType, setPackageType] = useState([]);
  const [animateClass, setAnimateClass] = useState("");
  const [selectImage, setSelectImage] = useState();
  const [selectedPackageType, setSelectedPackageType] = useState(null);

  const handlePackageTypeChange = async (e, handleChange) => {
    handleChange(e);

    if (e.target.value === "") {
      setSelectedPackageType(null);
    } else {
      const selectedType = packageType.find(
        (tp) => tp.id.toString() === e.target.value
      );
      setSelectedPackageType(selectedType);
      setSelectImage(null);
      setAnimateClass("animate__animated animate__bounceOut");
      setTimeout(() => {
        setSelectedPackageType(selectedType);
        setAnimateClass("animate__animated animate__bounceIn");
      });
    }
  };

  const handleSelectImage = (name) => {
    if (selectedPackageType !== null) {
      const newUrl = selectedPackageType.full_path_image.replace(
        "/general",
        `/${name}`
      );
      setSelectImage(newUrl);
      console.log(newUrl);
    } else {
      console.log("no hay nada");
    }
  };

  useEffect(() => {
    (async () => {
      const result = await getPackagesTypes();
      setPackageType(result.data);

      if (templateFields) {
        const selectedType = result.data.find(
          (tp) => tp.id.toString() === templateFields.package_type
        );
        setSelectedPackageType(selectedType);
      }
    })();
  }, [templateFields]);

  return (
    <>
      <Heading variant="title">Cotizador rápido</Heading>
      <Divider mt={2} />
      <Formik
        initialValues={{
          origin: "",
          package_type: "",
          destination: "",
          cant: "",
          length: "",
          width: "",
          height: "",
          peso: "",
          volumen: "",
        }}
        onSubmit={(values) => {
          console.log(values);
          setIsValues(values);
          console.log(isValues);
        }}
      >
        {({ values, getFieldProps, dirty, isValid, errors, handleChange }) => (
          <Form>
            <Grid templateColumns="repeat(3, 1fr)" mt={3}>
              <GridItem colSpan={1} me={"20px"}>
                <SimpleGrid columns={2} spacing={5}>
                  <FormControl isRequired mt={2}>
                    <FormLabel>CP Origen</FormLabel>
                    <Input type="text" {...getFieldProps("origin")} maxLength={5} />
                  </FormControl>
                  <FormControl isRequired mt={2}>
                    <FormLabel>CP Destino</FormLabel>
                    <Input type="text" {...getFieldProps("destination")} maxLength={5} />
                  </FormControl>
                <FormControl isRequired mb={5} isInvalid={errors.package_type}>
                  <FormLabel>Empaque</FormLabel>
                  <Select
                    placeholder="Selecciona el tipo de paquete"
                    {...getFieldProps("package_type")}
                    value={values.package_type}
                    onChange={(e) => handlePackageTypeChange(e, handleChange)}
                  >
                    {packageType &&
                      packageType.map((tp) => (
                        <option value={tp.id} key={tp.id}>
                          {tp.type}
                        </option>
                      ))}
                  </Select>
                  <FormErrorMessage className="form-error-message">
                    {errors.package_type}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>Cantidad</FormLabel>
                  <Input type="text" {...getFieldProps("cant")} />
                </FormControl>
                </SimpleGrid>
                <FormControl mt={2}>
                  <FormLabel>Medidas</FormLabel>
                  <Grid templateColumns="repeat(3, 1fr)" gap={4}>
                    <GridItem colSpan={1}>
                      <FormControl isRequired mb={5} isInvalid={errors.length}>
                        <FormLabel>Largo (cm)</FormLabel>
                        <Input
                          type="number"
                          placeholder=""
                          onClick={() => handleSelectImage("largo")}
                          {...getFieldProps("length")}
                        />
                        <FormErrorMessage className="form-error-message">
                          {errors.length}
                        </FormErrorMessage>
                      </FormControl>
                    </GridItem>
                    <GridItem colSpan={1}>
                      <FormControl isRequired mb={5} isInvalid={errors.width}>
                        <FormLabel>Ancho (cm)</FormLabel>
                        <Input
                          type="number"
                          placeholder=""
                          onClick={() => handleSelectImage("ancho")}
                          {...getFieldProps("width")}
                        />
                        <FormErrorMessage className="form-error-message">
                          {errors.width}
                        </FormErrorMessage>
                      </FormControl>
                    </GridItem>
                    <GridItem colSpan={1}>
                      <FormControl isRequired mb={5} isInvalid={errors.height}>
                        <FormLabel>Alto (cm)</FormLabel>
                        <Input
                          type="number"
                          placeholder=""
                          onClick={() => handleSelectImage("alto")}
                          {...getFieldProps("height")}
                        />
                        <FormErrorMessage className="form-error-message">
                          {errors.height}
                        </FormErrorMessage>
                      </FormControl>
                    </GridItem>
                  </Grid>
                </FormControl>
                <SimpleGrid columns={2} gap={5}>
                  <FormControl isRequired mt={2}>
                    <FormLabel>Peso (kg)</FormLabel>
                    <Input
                      type="number"
                      placeholder=""
                      {...getFieldProps("peso")}
                    />
                  </FormControl>
                  <FormControl isRequired mt={2}>
                    <FormLabel>Volumen</FormLabel>
                    <Input type="text" {...getFieldProps("volumen")} />
                  </FormControl>
                </SimpleGrid>
                <Flex mt={3} justifyContent="end">
                  {Object.values(values).every((value) => value) &&
                    dirty &&
                    isValid && (
                      <Button
                        variant="secondary"
                        type="submit"
                        className="animate__animated animate__rubberBand"
                      >
                        Cotizar
                      </Button>
                    )}
                </Flex>
              </GridItem>

              <GridItem colSpan={1} mt={3}>
                <Center>
                  <GridItem colSpan={1} className={`${animateClass}`}>
                    {selectedPackageType && (
                      <Image
                        className="animate__animated animate__pulse"
                        boxSize="lg"
                        src={
                          selectImage === null || selectImage === undefined
                            ? selectedPackageType.full_path_image
                            : selectImage
                        }
                        alt={`Image for package type ${selectedPackageType.type}`}
                      />
                    )}
                  </GridItem>
                </Center>
              </GridItem>

              <GridItem colSpan={1} mt={3} ms={4}>
                {!isLoading ? (
                  <Card
                    hidden={isValues ? false : true}
                    className="animate__animated animate__flash"
                  >
                    <CardHeader>
                      <Heading size="md">Resultado</Heading>
                    </CardHeader>
                    <CardBody>
                      <TableContainer>
                        <Table variant="simple" size="lg">
                          <Tbody>
                            <Tr>
                              <Td fontSize="lg" fontWeight="bold">
                                Tipo de servicio
                              </Td>
                              <Td fontSize="lg">Express</Td>
                            </Tr>
                            <Tr>
                              <Td fontSize="lg" fontWeight="bold">
                                Tiempo de entrega
                              </Td>
                              <Td fontSize="lg">48 horas</Td>
                            </Tr>
                            <Tr>
                              <Td fontSize="lg" fontWeight="bold">
                                Flete
                              </Td>
                              <Td fontSize="lg">$ 50</Td>
                            </Tr>
                            <Tr>
                              <Td fontSize="lg" fontWeight="bold">
                                Fuera de ruta
                              </Td>
                              <Td fontSize="lg">$ 50</Td>
                            </Tr>
                            <Tr>
                              <Td fontSize="lg" fontWeight="bold">
                                Seguro de Cobertura Mínima
                              </Td>
                              <Td fontSize="lg">$ 50</Td>
                            </Tr>
                          </Tbody>
                          <Tfoot>
                            <Tr>
                              <Th fontSize="lg" fontWeight="bold">
                                Total
                              </Th>
                              <Th fontSize="lg">$ 100 (antes de impuestos)</Th>
                            </Tr>
                          </Tfoot>
                        </Table>
                        <Alert status="warning" mt={4} rounded={5}>
                          <AlertIcon />
                          El resultado es una cotizacion estimada sujeta a
                          cambios.
                        </Alert>
                      </TableContainer>
                    </CardBody>
                  </Card>
                ) : (
                  <Stack>
                    <Skeleton height="35px" mt={3} />
                    <Skeleton height="35px" mt={3} />
                    <Skeleton height="35px" mt={3} />
                    <Skeleton height="35px" mt={3} />
                    <Skeleton height="35px" mt={3} />
                    <Skeleton height="35px" mt={3} />
                    <Skeleton height="35px" mt={3} />
                    <Skeleton height="35px" mt={3} />
                    <Skeleton height="35px" mt={3} />
                  </Stack>
                )}
              </GridItem>
            </Grid>
            <Flex mt={5} justifyContent="end">
              <Button
                hidden={isValues ? false : true}
                variant="primary"
                onClick={() => navigate("/mis-ordenes")}
                className="animate__animated animate__backInRight"
              >
                Generación de Guía
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </>
  );
};
