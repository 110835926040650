import { Box, Button, Checkbox, FormControl, FormErrorMessage, Skeleton, Stack} from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useNotification } from '../../hooks/useNotification'

export const CheckboxesNoti = ({ 
    onClose,
}) => {

    const { getConfig, modifyConfig } = useNotification();
    const [ config, setConfig ] = useState();
    const [ checkedItems, setCheckedItems ] = useState([false, false, false, false, false, false, false]);
    const [isLoading, setIsLoading] = useState(true);
    const allChecked = checkedItems.every(Boolean)
    const isIndeterminate = checkedItems.some(Boolean) && !allChecked

    const handleModify = async (values, { setErrors }) => {

        const body = {
            noticia: values?.noticia,
            nueva_funcionalidad: values?.nueva_funcionalidad,
            rastreo: values?.rastreo,
            sc: allChecked || isIndeterminate,
            sc_aceptada: checkedItems[0],
            sc_autorizada: checkedItems[1],
            sc_creada: checkedItems[2],
            sc_denegada: checkedItems[3],
            sc_pendiente: checkedItems[4],
            sc_rechazada: checkedItems[5],
            sc_validacion: checkedItems[6],
        }

        const data = await modifyConfig(body);
        if (data.success) {
            onClose();
        } else {
            if (data.errors) {
                setErrors(data.errors);
            } else {
                setErrors({
                global: 'Hubo un error en general.'
                
                });
            }
        }
    }

    useEffect(() => {
        (async () => {
            const result = await getConfig();
            setConfig(result);
            setCheckedItems([
                result?.sc_aceptada,
                result?.sc_autorizada,
                result?.sc_creada,
                result?.sc_denegada,
                result?.sc_pendiente,
                result?.sc_rechazada,
                result?.sc_validacion,
            ])

            setIsLoading(false);
        })();
    }, []);

  return (
    <>  
     {
        !isLoading
            ? (
            <Formik
                initialValues={{
                    noticia: config?.noticia,
                    nueva_funcionalidad: config?.nueva_funcionalidad,
                    rastreo: config?.rastreo,
                    sc: config?.sc || false,
                    sc_aceptada: config?.sc_aceptada || false,
                    sc_autorizada: config?.sc_autorizada || false,
                    sc_creada: config?.sc_creada || false,
                    sc_denegada: config?.sc_denegada || false,
                    sc_pendiente: config?.sc_pendiente || false,
                    sc_rechazada: config?.sc_rechazada || false,
                    sc_validacion: config?.sc_validacion || false,
                }}
                onSubmit={handleModify}
            >
            {({values, getFieldProps, errors }) => (
            <Form>
                {
                    (errors.global) && <Box className='form-error-message' mb={2}>{errors.global}</Box>
                }
                {/* <FormControl mb={2} isInvalid={(errors.noticia)}>
                    <Checkbox
                        {...getFieldProps('noticia')}
                        iconColor='white'
                        defaultChecked={values.noticia}
                    >
                        Noticias {config.noticia}
                    </Checkbox>
                    <FormErrorMessage className='form-error-message'>
                        {errors.noticia}
                    </FormErrorMessage>
                </FormControl>
                <FormControl mb={2} isInvalid={(errors.nueva_funcionalidad)}>
                    <Checkbox
                        {...getFieldProps('nueva_funcionalidad')}
                        iconColor='white'
                        defaultChecked={values.nueva_funcionalidad}
                    >
                        Nueva funcionalidad {config.nueva_funcionalidad}
                    </Checkbox>
                    <FormErrorMessage className='form-error-message'>
                        {errors.nueva_funcionalidad}
                    </FormErrorMessage>
                </FormControl> */}
                <FormControl mb={2} isInvalid={(errors.rastreo)}>
                    <Checkbox
                        {...getFieldProps('rastreo')}
                        iconColor='white'
                        defaultChecked={values.rastreo}
                    >
                        Rastreo {config.rastreo}
                    </Checkbox>
                    <FormErrorMessage className='form-error-message'>
                        {errors.rastreo}
                    </FormErrorMessage>
                </FormControl>
                <FormControl mb={2} isInvalid={(errors.sc)}>
                    <Checkbox
                        {...getFieldProps('sc')}
                        iconColor='white'
                        isChecked={allChecked}
                        isIndeterminate={isIndeterminate}
                        onChange={(e) => setCheckedItems([e.target.checked, e.target.checked, e.target.checked, e.target.checked, e.target.checked, e.target.checked, e.target.checked])}
                    >
                        Solicitudes de crédito
                    </Checkbox>
                    <FormErrorMessage className='form-error-message'>
                        {errors.sc}
                    </FormErrorMessage>
                    <Stack pl={6} mt={1} spacing={1}>
                        <Checkbox name='sc_aceptada' isChecked={checkedItems[0]} onChange={(e) => setCheckedItems([e.target.checked, checkedItems[1], checkedItems[2], checkedItems[3], checkedItems[4], checkedItems[5], checkedItems[6]])}>Aceptada</Checkbox>
                        <Checkbox name='sc_autorizada' isChecked={checkedItems[1]} onChange={(e) => setCheckedItems([checkedItems[0], e.target.checked, checkedItems[2], checkedItems[3], checkedItems[4], checkedItems[5], checkedItems[6]])}>Autorizada</Checkbox>
                        <Checkbox name='sc_creada' isChecked={checkedItems[2]} onChange={(e) => setCheckedItems([checkedItems[0], checkedItems[1], e.target.checked, checkedItems[3], checkedItems[4], checkedItems[5], checkedItems[6]])}>Creada</Checkbox>
                        <Checkbox name='sc_denegada' isChecked={checkedItems[3]} onChange={(e) => setCheckedItems([checkedItems[0], checkedItems[1], checkedItems[2], e.target.checked, checkedItems[4], checkedItems[5], checkedItems[6]])}>Denegada</Checkbox>
                        <Checkbox name='sc_pendiente' isChecked={checkedItems[4]} onChange={(e) => setCheckedItems([checkedItems[0], checkedItems[1], checkedItems[2], checkedItems[3], e.target.checked, checkedItems[5], checkedItems[6]])}>Pendiente</Checkbox>
                        <Checkbox name='sc_rechazada' isChecked={checkedItems[5]} onChange={(e) => setCheckedItems([checkedItems[0], checkedItems[1], checkedItems[2], checkedItems[3], checkedItems[4], e.target.checked, checkedItems[6]])}>Rechazada</Checkbox>
                        <Checkbox name='sc_validacion' isChecked={checkedItems[6]} onChange={(e) => setCheckedItems([checkedItems[0], checkedItems[1], checkedItems[2], checkedItems[3], checkedItems[4], checkedItems[5], e.target.checked])}>Validacion</Checkbox>
                    </Stack>
                    <FormErrorMessage className='form-error-message'>
                        {errors.name}
                    </FormErrorMessage>
                </FormControl>
                <Stack w='100%' justifyContent='space-between' flexDir={['column-reverse', 'column-reverse', 'row', 'row']}>
                    <Button mt={5} mb={5} onClick={onClose} variant='secondary'>Cancelar</Button>
                    <Button mt={5} mb={5} type='submit' variant='primary'>Guardar</Button>
                </Stack>
            </Form>
            )}
    
            </Formik>
            
            ) : 
            <Stack mt={10}>
                <Skeleton height='30px' />
                <Skeleton mt={3} height='30px' />
                <Skeleton mt={3} height='30px' />
                <Skeleton mt={3} height='30px' />
                <Skeleton mt={3} height='30px' />
                <Skeleton mt={3} height='30px' />
                <Skeleton mt={3} height='30px' />
            </Stack>
     }

    </>
  )
}
