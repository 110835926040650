import { Button, Flex, FormControl, FormErrorMessage, FormLabel, Grid, Input, Spacer } from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import React from 'react'
import useCreditRequest from '../../../hooks/useCreditRequest'
import { useDispatch, useSelector } from 'react-redux'
import { syncCreditRequestInfo } from '../../../redux/slices/creditRequestSlice'

const CreditInformationForm = ({
    nextStep,
    currentStep
}) => {

    const { amount_requested, term_requested, mensual_consume } = useSelector((state) => state.credit_request.info);
    const { modifyCreditInformation } = useCreditRequest();
    const dispatch = useDispatch();
    const { credit_id, step } = useSelector((state) => state.credit_request);

    return (
        <Formik
            initialValues={{
                term_requested: term_requested || '',
                amount_requested: amount_requested || '',
                mensual_consume: mensual_consume || ''
            }}
            validationSchema={Yup.object({
                amount_requested: Yup.number().min(20000, 'El mínimo para poder solicitar un credito es de $20,000').required(),
                term_requested: Yup.number().when('amount_requested', (amount_request, schema) => {
                    if(amount_request >= 20000 && amount_request < 50000) return schema.min(7, 'No puedes seleccionar un plazo menor a 7 días.').max(7, 'El plazo no puede ser mayor a 7 días, para tu monto solicitado.');
                    if(amount_request >= 50000 && amount_request < 100000) return schema.min(7, 'No puedes seleccionar un plazo menor a 7 días.').max(15, 'El plazo no puede ser mayor a 15 días, para tu monto solicitado.');

                    return schema;
                }).required(),
                mensual_consume: Yup.number().required()
            })}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={async (values, { setErrors }) => {
                const { success, errors } = await modifyCreditInformation(values, credit_id);

                if(success) {
                    dispatch(syncCreditRequestInfo());
                    nextStep(currentStep + 1);
                } else {
                    setErrors(errors);
                }
            }}
        >
            {({getFieldProps, errors}) => (
                <Form>
                    <Grid templateColumns={['repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(3, 1fr)']} gap={2}>
                        <FormControl isInvalid={(errors.amount_requested)}>
                            <FormLabel><span style={{ color: 'red' }}>*</span> Monto a solicitar</FormLabel>
                            <Input type='number' {...getFieldProps('amount_requested')} />
                            <FormErrorMessage className='form-error-message'>
                                {errors.amount_requested}
                            </FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={(errors.term_requested)}>
                            <FormLabel><span style={{ color: 'red' }}>*</span>  Plazo (en días)</FormLabel>
                            <Input type='number'  {...getFieldProps('term_requested')} />
                            <FormErrorMessage className='form-error-message'>
                                {errors.term_requested}
                            </FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={(errors.mensual_consume)}>
                            <FormLabel><span style={{ color: 'red' }}>*</span> Consumo mensual proyectado</FormLabel>
                            <Input type='number' {...getFieldProps('mensual_consume')} />
                            <FormErrorMessage className='form-error-message'>
                                {errors.mensual_consume}
                            </FormErrorMessage>
                        </FormControl>
                    </Grid>
                    <Flex mt={2} flexDir={['column-reverse', 'row', 'row', 'row']}>
                        <Button onClick={() => nextStep(currentStep - 1)} variant='secondary'>Regresar</Button>
                        <Spacer my={1} />
                        <Button type='submit' variant='primary'>Continuar</Button>
                    </Flex>
                </Form>
            )}
        </Formik>
    )
}

export default CreditInformationForm