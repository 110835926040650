import { Box, Button, Divider, Flex, FormControl, FormErrorMessage, FormLabel, Grid, GridItem, Heading, Input, Select, Spacer, Stack, VStack } from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import useSepomex from '../../../hooks/useSepomex';
import { useNavigate } from 'react-router-dom';

import * as Yup from 'yup';
import useCreditRequest from '../../../hooks/useCreditRequest';
import { useDispatch, useSelector } from 'react-redux';
import { setEditMode, setStep, syncCreditRequestInfo } from '../../../redux/slices/creditRequestSlice';

const CRSecondStep = () => {

  const { getDataFromPostalCode } = useSepomex();
  const { addPaymentProccess } = useCreditRequest();
  const { credit_id, info } = useSelector((state) => state.credit_request);
  const { bank_account, pay_days, review_days, payment_address } = useSelector((state) => state.credit_request.info);

  const dispatch = useDispatch();

  const [cpData, setCpData] = useState();
  const [payDays, setPayDays] = useState([]);
  const [reviewDays, setReviewDays] = useState([]);
  const navigate = useNavigate();

  const handlePostalCode = async (event, handleChange) => {
    handleChange(event);
    const postal_code = event.target.value;

    loadCitiesAndStates(postal_code);
  }

  const loadCitiesAndStates = async (postal_code) => {
    if (postal_code.toString().length === 5) {
      const data = await getDataFromPostalCode(postal_code);
      setCpData(data);
    } else {
      setCpData(null);
    }
  }

  const handlePayDays = (value) => {
    if (payDays.indexOf(value) !== -1) {
      setPayDays((payDays) => payDays.filter(payDay => payDay !== value));
    } else {
      setPayDays([
        ...payDays,
        value
      ]);
    }
  }

  const handleReviewDays = (e, value) => {
    if (e.target.checked) {
      setReviewDays([
        ...reviewDays,
        value
      ]);
    } else {
      setReviewDays((reviewDays) => reviewDays.filter(reviewDay => reviewDay !== value));
    }

  }

  useEffect(() => {
    if (pay_days) {
      setPayDays(JSON.parse(pay_days));
    }

    if (review_days) {
      setReviewDays(JSON.parse(review_days));
    }
  }, [pay_days, review_days]);

  useEffect(() => {
    if (payment_address?.postal_code) {
      loadCitiesAndStates(payment_address.postal_code);
    }
  }, [payment_address]);

  useEffect(() => {
    dispatch(setStep(1));
  }, []);

  return (
    <div>
      <Heading variant='title'>Procedimiento de pago</Heading>
      <Divider my={2} />
      <Heading size='md' mb={1}>Domicilio de pago</Heading>
      <Formik
        initialValues={{
          street: payment_address?.street || '',
          num_ext: payment_address?.num_ext || '',
          num_int: payment_address?.num_int || '',
          postal_code: payment_address?.postal_code || '',
          suburb: payment_address?.suburb || '',
          city: payment_address?.city || '',
          state: payment_address?.state || '',
          bank_account: bank_account || ''
        }}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={Yup.object({
          street: Yup.string().required('Éste campo es necesario.'),
          num_ext: Yup.string().required('Éste campo es necesario.'),
          num_int: Yup.string().notRequired(),
          postal_code: Yup.number().required('Éste campo es necesario.'),
          suburb: Yup.string().required('Éste campo es necesario.'),
          city: Yup.string().required('Éste campo es necesario.'),
          state: Yup.string().required('Éste campo es necesario.'),
          bank_account: Yup.string().required('Éste campo es necesario.')
        })}
        onSubmit={async (values, { setErrors }) => {
          if ((info.amount_requested >= 50000 && payDays.length) || (reviewDays.length)) {
            const { success, errors } = await addPaymentProccess({
              ...values,
              pay_days: payDays,
              review_days: reviewDays
            }, credit_id);

            if (success) {
              dispatch(setEditMode(credit_id));
              dispatch(syncCreditRequestInfo());
              navigate('/solicitudes-de-credito/informacion-del-cliente');
            } else {
              setErrors(errors);
              console.log(errors);
            }
          } else {
            let message = (info.amount_requested >= 50000) ? 'días de paga' : 'días de revisión';
            setErrors({
              days: `Es necesario seleccionar ${message}.`
            });
          }
        }}
      >
        {({ handleChange, getFieldProps, errors }) => (
          <Form>
            <Grid templateColumns={['repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(2, 1fr)']} gap={2}>
              <GridItem>
                <FormControl mb={2} isInvalid={errors.street}>
                  <FormLabel><span style={{ color: 'red' }}>*</span> Calle</FormLabel>
                  <Input type='text' {...getFieldProps('street')} />
                  <FormErrorMessage className='form-error-message'>
                    {errors.street}
                  </FormErrorMessage>
                </FormControl>
                <FormControl mb={2} isInvalid={errors.num_ext}>
                  <FormLabel><span style={{ color: 'red' }}>*</span> Número exterior</FormLabel>
                  <Input type='text' {...getFieldProps('num_ext')} />
                  <FormErrorMessage className='form-error-message'>
                    {errors.num_ext}
                  </FormErrorMessage>
                </FormControl>
                <FormControl mb={2} isInvalid={errors.num_int}>
                  <FormLabel>Número interior</FormLabel>
                  <Input type='text' {...getFieldProps('num_int')} />
                  <FormErrorMessage className='form-error-message'>
                    {errors.num_int}
                  </FormErrorMessage>
                </FormControl>
                <FormControl mb={2} isInvalid={errors.postal_code}>
                  <FormLabel><span style={{ color: 'red' }}>*</span> Código postal</FormLabel>
                  <Input type='text' {...getFieldProps('postal_code')} onChange={(e) => handlePostalCode(e, handleChange)} />
                  <FormErrorMessage className='form-error-message'>
                    {errors.postal_code}
                  </FormErrorMessage>
                </FormControl>
                <FormControl mb={2} isInvalid={errors.suburb}>
                  <FormLabel><span style={{ color: 'red' }}>*</span> Colonia</FormLabel>
                  <Select {...getFieldProps('suburb')}>
                    <option>Selecciona una colonia</option>
                    {
                      (cpData && cpData.colonias) && cpData.colonias.map((col) => <option value={col.colonia} key={col.id}>{col.colonia}</option>)
                    }
                  </Select>
                  <FormErrorMessage className='form-error-message'>
                    {errors.suburb}
                  </FormErrorMessage>
                </FormControl>
                <FormControl mb={2} isInvalid={errors.city}>
                  <FormLabel><span style={{ color: 'red' }}>*</span> Municipio/Ciudad</FormLabel>
                  <Select {...getFieldProps('city')}>
                    <option>Selecciona un Municipio/Ciudad</option>
                    {
                      cpData && cpData.municipio && <option value={cpData.municipio}>{cpData.municipio}</option>
                    }
                  </Select>
                  <FormErrorMessage className='form-error-message'>
                    {errors.city}
                  </FormErrorMessage>
                </FormControl>
                <FormControl mb={2} isInvalid={(errors.state)}>
                  <FormLabel><span style={{ color: 'red' }}>*</span> Estado</FormLabel>
                  <Select {...getFieldProps('state')}>
                    <option>Selecciona un estado</option>
                    {
                      cpData && cpData.estado && <option value={cpData.estado}>{cpData.estado}</option>
                    }
                  </Select>
                  <FormErrorMessage className='form-error-message'>
                    {errors.state}
                  </FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl mb={2} isInvalid={errors.bank_account}>
                  <FormLabel><span style={{ color: 'red' }}>*</span> Cuenta bancaria</FormLabel>
                  <Input type='text' {...getFieldProps('bank_account')} />
                  <FormErrorMessage className='form-error-message'>
                    {errors.bank_account}
                  </FormErrorMessage>
                </FormControl>
                <Divider my={2} />
                <Stack alignItems='start' w='100%'>
                  {
                    (info.amount_requested < 500000) ? (
                      <VStack width='100%'>
                        <Heading size='md'>Días de revisión</Heading>
                        <Input type='checkbox' className='checkbox-custom-input' id='monday' onChange={(event) => handleReviewDays(event, 1)} checked={(reviewDays.indexOf(1) !== -1) ? true : false} />
                        <label for='monday' className='checkbox-custom'>Lunes</label>

                        <Input type='checkbox' className='checkbox-custom-input' id='tuesday' onChange={(event) => handleReviewDays(event, 2)} checked={(reviewDays.indexOf(2) !== -1) ? true : false} />
                        <label for='tuesday' className='checkbox-custom'>Martes</label>

                        <Input type='checkbox' className='checkbox-custom-input' id='wednesday' onChange={(event) => handleReviewDays(event, 3)} checked={(reviewDays.indexOf(3) !== -1) ? true : false} />
                        <label for='wednesday' className='checkbox-custom'>Miercoles</label>

                        <Input type='checkbox' className='checkbox-custom-input' id='thursday' onChange={(event) => handleReviewDays(event, 4)} checked={(reviewDays.indexOf(4) !== -1) ? true : false} />
                        <label for='thursday' className='checkbox-custom'>Jueves</label>

                        <Input type='checkbox' className='checkbox-custom-input' id='friday' onChange={(event) => handleReviewDays(event, 5)} checked={(reviewDays.indexOf(5) !== -1) ? true : false} />
                        <label for='friday' className='checkbox-custom'>Viernes</label>

                        <Input type='checkbox' className='checkbox-custom-input' id='saturday' onChange={(event) => handleReviewDays(event, 6)} checked={(reviewDays.indexOf(6) !== -1) ? true : false} />
                        <label for='saturday' className='checkbox-custom'>Sabado</label>

                        <Input type='checkbox' className='checkbox-custom-input' id='sunday' onChange={(event) => handleReviewDays(event, 7)} checked={(reviewDays.indexOf(7) !== -1) ? true : false} />
                        <label for='sunday' className='checkbox-custom'>Domingo</label>

                      </VStack>
                    ) : (
                      <VStack width='100%'>
                        <Heading size='md'>Días de pago</Heading>
                        <Grid templateColumns='repeat(7, 1fr)'
                          w='100%'
                          borderWidth={1}
                          borderColor='gray.400'
                          padding={3}
                          borderRadius={5}
                        >
                          {[...Array(31)].map((x, i) => (
                            <Box
                              m={1}
                              padding={1}
                              height='40px'
                              cursor='pointer'
                              borderColor='gray'
                              borderRadius='full'
                              display='flex'
                              justifyContent='center'
                              alignItems='center'
                              backgroundColor={(payDays.indexOf(i + 1) !== -1) ? 'primary.900' : 'white'}
                              color={(payDays.indexOf(i + 1) !== -1) ? 'white' : 'black'}
                              fontWeight='bold'
                              transition='0.8s'
                              _hover={{
                                backgroundColor: 'secondary.400',
                              }}
                              key={i + 1}
                              onClick={() => handlePayDays(i + 1)}
                            >
                              {i + 1}
                            </Box>
                          ))}
                        </Grid>
                      </VStack>
                    )
                  }
                  {
                    (errors.days) && <Box className='form-error-message' w='100%'>{errors.days}</Box>
                  }
                </Stack>
              </GridItem>
            </Grid>
            <Flex mt={5} flexDir={['column-reverse', 'row', 'row', 'row']}>
              <Button onClick={() => navigate('/solicitudes-de-credito/datos-fiscales')} variant='secondary'>Regresar</Button>
              <Spacer my={1} />
              <Button type='submit' variant='primary'>Continuar</Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default CRSecondStep