import { Box, HStack, Image, Menu, MenuButton, MenuItem, MenuList, Text, VStack } from '@chakra-ui/react'
import chip from '../../assets/images/credit_card/chip.png';
import React from 'react'

const CreditCard = ({
    card,
    handleEditCard,
    handleDeleteCard
}) => {
    return (
        <Box
            background='radial-gradient(ellipse farthest-corner at 0 140%, #000 0%, #000 70%, #2F2F2F 70%)'
            w={'100%'}
            h='220px'
            px={6}
            color='white'
            rounded={10}
        >
            <VStack alignItems='start' py={5} justifyContent='space-between' h='full' gap={1}>
                <HStack w='full' justifyContent='space-between'>
                    <Text fontSize={'28'}>{card.bank_name}</Text>
                    <Text fontSize={'14'}>{card.brand}</Text>
                    <Menu>
                        <MenuButton flexDirection='row' justifyContent='flex-end' p='0px' color='white' variant='link' >
                            <i className='bi bi-three-dots-vertical'></i>
                        </MenuButton>
                        <MenuList color='Black'>
                            <MenuItem onClick={() => handleEditCard(card.id)} icon={<i className='bi bi-pencil-square' style={{ fontSize: '18px' }}></i>}>Editar</MenuItem>
                            <MenuItem onClick={() => handleDeleteCard(card.id)} icon={<i className='bi bi-trash-fill' style={{ fontSize: '18px' }}></i>}>Eliminar</MenuItem>
                        </MenuList>
                    </Menu>
                </HStack>
                <Image src={chip} 
                    w='40px'
                />
                <Box>
                    <Text w='full' textAlign='left' fontWeight='light' letterSpacing={6} fontSize={'18px'} justifyContent='center'>**** **** **** {card.last_4_digits}</Text>
                    <HStack justifyContent='space-between'>
                        <VStack gap={0} alignItems='start'>
                            <Text fontSize={18}>{card.holder_name}</Text>
                            <Text fontSize={10}>{card.type}</Text>
                        </VStack>
                        <Box mt={5} textAlign='right'>
                            <Text fontSize={10}>Expiración</Text>
                            <Text fontSize={14}>{card.expiration_month} / {card.expiration_year}</Text>
                        </Box>
                    </HStack>
                </Box>
            </VStack>
        </Box>
    )
}

export default CreditCard