import { Box, Button, HStack, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Stack, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import BadgeStatusCR from './BadgeStatusCR'
import { Form, Formik } from 'formik'
import useCreditRequest from '../../hooks/useCreditRequest'
import { CRStatus } from '../../enums/CRStatus'
import { useNavigate } from 'react-router-dom'
import { colors } from '../../themes/colors'
import Swal from 'sweetalert2'
import { MXNFormat } from '../../helpers/general_functions';

const ModalProposalForm = ({
    creditRequest: cr,
    onOpen,
    isOpen,
    onClose
}) => {

    const { changeStatusToCR } = useCreditRequest();
    const navigate = useNavigate();

    const handleChangeStatus = async (status_id) => {
        await changeStatusToCR(status_id, cr.id)
            .then(({ success, message }) => {
                if (success) {
                    onClose();
                    Swal.fire({
                        title: `Solicitud de crédito ${(status_id == CRStatus.Aceptada) ? 'aceptada' : 'rechazada'}`,
                        text: 'Se ha cambiado el estatus de la solicitud de crédito y se le ha notificado al usuario.',
                        icon: 'success',
                        didClose: () => {
                            navigate('/solicitudes-de-credito');
                        },
                        confirmButtonColor: colors['primary']['900']
                    });
                }
            });
    }

    return (
        <Modal size='lg' isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalContent>
                    <ModalHeader>Información del crédito</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <BadgeStatusCR status={cr.status.status} statusId={cr.status.id} mb={3} />

                        <VStack w='100%' spacing={5} alignItems='start'>
                            <Text><i className='bi bi-person-fill'></i> <b>Nombre</b>: {cr.fiscal_address.company_name}</Text>

                            {/* Inicio Credito */}
                            <Box w='100%'>
                                <Text fontWeight='bold'><i className='bi bi-currency-dollar'></i> Crédito</Text>
                                <HStack flex={1} alignItems='start'>
                                    <VStack alignItems='start' flex={1}>
                                        <Text fontWeight='semibold' fontSize='sm'>Monto solicitado</Text>
                                        <Text>{MXNFormat.format(cr.amount_requested)}</Text>
                                    </VStack>
                                    <VStack alignItems='start' flex={1}>
                                        <Text fontWeight='semibold' fontSize='sm'>Monto autorizado</Text>
                                        <Text>{MXNFormat.format(cr.amount_autorized)}</Text>
                                    </VStack>
                                </HStack>
                            </Box>
                            {/* Fin Credito */}

                            {/* Inicio Plazo */}
                            <Box width='100%'>
                                <Text fontWeight='bold'><i className='bi bi-calendar'></i> Plazo (días)</Text>
                                <HStack w='full' flex={1}>
                                    <HStack flex={1} alignItems='start'>
                                        <VStack alignItems='start' flex={1}>
                                            <Text fontWeight='semibold' fontSize='sm'>Plazo solicitado</Text>
                                            <Text>{cr.term_requested}</Text>
                                        </VStack>
                                        <VStack alignItems='start' flex={1}>
                                            <Text fontWeight='semibold' fontSize='sm'>Plazo propuesta</Text>
                                            <Text>{cr.term_autorized}</Text>
                                        </VStack>
                                    </HStack>
                                </HStack>
                            </Box>
                            {/* Fin Plazo */}

                            <Stack flexDirection='row' w='100%' mb={3}>
                                <Button onClick={() => handleChangeStatus(CRStatus.Rechazada)} flex={1} variant='secondary'>Rechazar</Button>
                                <Button onClick={() => handleChangeStatus(CRStatus.Aceptada)} flex={1} variant='primary'>Aceptar</Button>
                            </Stack>
                        </VStack>
                    </ModalBody>
                </ModalContent>
            </ModalContent>
        </Modal>
    )
}

export default ModalProposalForm