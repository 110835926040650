import { Badge, Box, Button, Checkbox, Divider, HStack, Heading, Input, Menu, MenuButton, MenuDivider, MenuGroup, MenuItem, MenuList, Select, Skeleton, Stack, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const picking = [
    {
        id: 1,
        origin: 'Origen',
        num_reco: 2,
        date: '10/12/20',
        cant: 23,
        vol: 23,
        weigth: 23,
        status: 'Pendiente',
    },
    {
        id: 2,
        origin: 'Origen',
        num_reco: 4,
        date: '10/12/20',
        cant: 23,
        vol: 23,
        weigth: 23,
        status: 'Denegado',
    },
    {
        id: 3,
        origin: 'Origen',
        num_reco: 6,
        date: '10/12/20',
        cant: 23,
        vol: 23,
        weigth: 23,
        status: 'Pendiente',
    },
]

export const PickingListPage = () => {

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const handleDeletePicking = () => {
        console.log('Eliminar');
    }

    const handleEditPicking = () => {
        console.log('Editar');
    }
    
  return (
    <>
        <Heading variant='title'>Mis Recolecciones</Heading>
        <Divider my={2} />
        <HStack justifyContent='space-between'>
        <Heading fontSize='24px' >Historial</Heading>
        <Menu closeOnSelect={false}>
        <HStack>
            <Button variant='secondary'>Exportar</Button>
            <MenuButton 
            // hidden={hidden}
            // onClick={handleCleanFilters}
            >
            <Box>
                <Button>
                Limpiar Filtros
                </Button>
            </Box>
            </MenuButton>
            <MenuButton as={Button}>
            <p>Filtrar por <i class="bi bi-caret-down-fill"></i></p>
            </MenuButton>
        </HStack>
        <MenuList>
            <MenuGroup title='Tipo de dirección'>
            <MenuItem>
                <Checkbox
                    value={'1'}
                    name='address_type[]s'
                    iconColor={'white'}
                >Fiscal
                </Checkbox>
            </MenuItem>
            <MenuItem>
                <Checkbox
                value={'2'}
                name='address_type[]'
                iconColor={'white'}
                >Origen
                </Checkbox>
            </MenuItem>
            <MenuItem>
                <Checkbox
                value={'3'}
                name='address_type[]'
                iconColor={'white'}
                >Destino
                </Checkbox>
            </MenuItem>
            </MenuGroup>
            <MenuDivider />
            <MenuGroup title='Fecha'>
            <MenuItem>
            <Box mr='5'>
                <Text mb='8px'>Inicio </Text>
                <Input
                    onClick={e => e.stopPropagation()}
                    placeholder="Select Date and Time"
                    name='start_date'
                    size="md"
                    type="date"
                />
                </Box>
                <Box>
                <Text mb='8px'>Fin</Text>
                <Input
                    onClick={e => e.stopPropagation()}
                    placeholder="Select Date and Time"
                    name='end_date'
                    size="md"
                    type="date"
                />
                </Box>
            </MenuItem>
            </MenuGroup>
            <MenuDivider />
            <MenuGroup title='Búsqueda'>
            <MenuItem>
                <Select
                onClick={e => e.stopPropagation()}
                placeholder='Selecciona...'
                >
                <option value='vatnum'>RFC</option>
                <option value='telephone'>Teléfono</option>
                <option value='address'>Dirección</option>
                </Select>
            </MenuItem>
            <MenuItem>
                <Input
                onClick={e => e.stopPropagation()}
                placeholder="Filtrar..."
                mb={4}
                />
            </MenuItem>
            </MenuGroup>
        </MenuList>
        </Menu>
    </HStack>
        
        {
        !isLoading
            ?
        <>
            
        <Box mt={5}>
            <Table>
            <Thead>
                <Tr>
                    <Th>Num Recoleccion</Th>
                    <Th>Origen</Th>
                    <Th>Fecha</Th>
                    <Th>Cantidad</Th>
                    <Th>Volumen</Th>
                    <Th>Peso</Th>
                    <Th>Estatus</Th>
                    <Th>Acciones</Th>
                </Tr>
            </Thead>

            <Tbody>
                {
                picking.map((pick) => (
                <Tr key={pick.id}>
                    <Td>{pick.num_reco}</Td>
                    <Td>{pick.origin}</Td>
                    <Td>{pick.date}</Td>
                    <Td>{pick.cant}</Td>
                    <Td>{pick.vol}</Td>
                    <Td>{pick.weigth}</Td>
                    <Td>
                        <Badge colorScheme='orange' rounded='15px' py={1} px={3}>
                            {pick.status}
                        </Badge>
                    </Td>
                    <Td>
                        <Menu>
                            <MenuButton as={Button}>
                            <i className='bi bi-three-dots-vertical'></i>
                            </MenuButton>
                            <MenuList>
                            <MenuItem onClick={() => handleEditPicking(pick.id)} icon={<i className='bi bi-pencil-square' style={{ fontSize:'18px' }}></i>}>Editar</MenuItem>
                            <MenuItem onClick={() => handleDeletePicking(pick.id)} icon={<i className='bi bi-trash-fill' style={{ fontSize:'18px' }}></i>}>Eliminar</MenuItem>
                            </MenuList>
                        </Menu>
                    </Td>
                </Tr>
                ))}

            </Tbody>
            </Table>
            {/* {
            !loading && <Paginator setCurrentUrl={setCurrentUrl} paginator={paginator} />
            } */}
        </Box>
        </>
        :
        <Stack mt={10}>
            <Skeleton height='30px' />
            <Skeleton mt={3} height='30px' />
            <Skeleton mt={3} height='30px' />
            <Skeleton mt={3} height='30px' />
            <Skeleton mt={3} height='30px' />
            <Skeleton mt={3} height='30px' />
            <Skeleton mt={3} height='30px' />
        </Stack>
        }
    </>
  )
}
