import React from 'react'
import { useSelector } from 'react-redux'
import LoginRouter from './LoginRouter';
import InitRouter from './InitRouter';

const LoggedInRouter = () => {
    const { loggedIn } = useSelector((state) => state.user);
    return !loggedIn ? <LoginRouter /> : <InitRouter />
}

export default LoggedInRouter;