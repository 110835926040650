import { Box, Button, Divider, HStack, Image, Menu, MenuButton, MenuItem, MenuList, Text, VStack, useDisclosure } from '@chakra-ui/react'
import logo from '../../assets/images/commons/logo.png'
import React, { createContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../redux/slices/userSlice'
import NotificationDropdown from '../notification/NotificationDropdown'
import DrawerMenu from './DrawerMenu'
import { NavLinkDropdown } from '../menu/NavLinked'

export const HeaderContext = createContext({
    closeDrawer: null
});

const Header = () => {

    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.data);

    const handleLogout = () => {
        dispatch(logout());
        return navigate('/ingresar');
    }

    return (
        <HeaderContext.Provider
            value={{
                closeDrawer: onClose
            }}
        >
            <header>
                <Box
                    w="100%"
                    h="70px"
                    bgColor='primary.900'
                    display='flex'
                    alignItems='center'
                    paddingX={3}
                >
                    <DrawerMenu isOpen={isOpen} onClose={onClose} />
                    <HStack w="100%">
                        <HStack alignItems='center'>
                            <Button display={['block', 'block', 'none', 'none']} backgroundColor='transparent' _hover={{ backgroundColor: 'transparent' }} onClick={onOpen} m='0' p='0'>
                                <i style={{ color: 'white', fontSize: '30px' }} className='bi bi-list'></i>
                            </Button>
                            <Link to="/">
                                <Image src={logo} w='150px' />
                            </Link>
                        </HStack>
                        <Box flex={1} />
                        <NotificationDropdown />
                        <Menu>
                            <MenuButton>
                                <HStack
                                    padding={1}
                                    borderColor='white'
                                    borderWidth={1}
                                    paddingRight={5}
                                    borderRadius='full'
                                    alignItems='center'
                                >
                                    <Box w={['30px', '30px', '40px', '40px']} h={['30px', '30px', '40px', '40px']} borderRadius='40px' display='flex' bgColor='gray.p600' justifyContent='center' alignItems='center' overflow='hidden'>
                                        <Image src={user.profile_pic} w='100%' h='100%' />
                                    </Box>
                                    <VStack spacing={0} alignItems='start' display={['none', 'none', 'block', 'block']}>
                                        <Text color='white' fontWeight='bold'>{user.name} {user.last_name}</Text>
                                        <Text color='white' fontSize='sm'>{user.role.role}</Text>
                                    </VStack>
                                    <Text color='white' fontWeight='bold'><i className='bi bi-chevron-down'></i></Text>
                                </HStack>
                            </MenuButton>
                            <MenuList className='header-list'>
                                <MenuItem
                                    as={Link}
                                    to='/mi-perfil'
                                    icon={<i style={{ fontSize: 20 }} className="bi bi-person-circle"></i>}
                                >
                                    Mi perfil
                                </MenuItem>
                                <li>
                                    <MenuItem
                                        px={0}
                                        py={0}
                                    >
                                        <NavLinkDropdown
                                            onClick={(e) => e.stopPropagation()}
                                            title="Mi Configuración"
                                            icon="bi bi-gear"
                                            color='black'
                                            fontSize='20px'
                                            py='0'
                                            px='0'
                                            my='6px'
                                            mx='12px'
                                            dropdownItems={[
                                                { href: '/general', title: 'General', icon: 'bi bi-info-square', color: 'black', fontSize: '20px' },
                                                { href: '/mis-plantillas', title: 'Mis Plantillas', icon: 'bi bi-gear', color: 'black', fontSize: '20px' },
                                                { href: '/mis-direcciones', title: 'Mis Direcciones', icon: 'bi bi-geo-alt', color: 'black', fontSize: '20px' },
                                                { href: '/usuarios', title: 'Usuarios Secundarios', icon: 'bi bi-person', color: 'black', fontSize: '20px'  },
                                            ]}
                                        />
                                    </MenuItem>
                                </li>
                                <MenuItem
                                    icon={<i style={{ fontSize: 20 }} className="bi bi-question-circle"></i>}
                                >
                                    Ayuda
                                </MenuItem>
                                <Divider />
                                <MenuItem
                                    icon={<i style={{ fontSize: 20 }} className="bi bi-box-arrow-right"></i>}
                                    onClick={handleLogout}
                                >
                                    Cerrar sesión
                                </MenuItem>
                            </MenuList>
                        </Menu>
                    </HStack>
                </Box>
            </header>
        </HeaderContext.Provider>
    )
}

export default Header