import {
  Badge,
  Box,
  Button,
  Checkbox,
  Divider,
  HStack,
  Heading,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import useInvoice from "../../hooks/useInvoice";
import { MXNFormat } from "../../helpers/general_functions";
import { useDispatch } from "react-redux";
import {
  addMultipleToCart,
  addToCart,
  cleanCart,
} from "../../redux/slices/cartSlice";
import { useNavigate } from "react-router-dom";

const InvoicesListPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [invoices, setInvoices] = useState(null);
  const [selection, setSelection] = useState([]);
  const { getInvoices } = useInvoice();

  const handlePayInvoice = async (invoice) => {
    let cart_item = {
      item_id: invoice.id,
      name: invoice.id,
      total: invoice.total,
    };

    dispatch(cleanCart());

    await dispatch(
      addToCart({
        item: cart_item,
      })
    );

    navigate("/facturas/pagar");
  };

  const handlePayMultipleInvoice = async () => {
    const total = selection.reduce(
      (total, invoice) => total + invoice.total,
      0
    );

    dispatch(cleanCart());
    await dispatch(
      addMultipleToCart({
        items: selection,
        subtotal: total,
      })
    );

    navigate("/facturas/pagar");
  };

  const toggleInvoices = (event, invoice) => {
    if (event.target.checked) {
      setSelection([
        ...selection,
        {
          item_id: invoice.id,
          name: invoice.id,
          total: invoice.total,
        },
      ]);
    } else {
      setSelection((selection) =>
        selection.filter((_invoice) => _invoice.item_id !== invoice.id)
      );
    }
  };

  useEffect(() => {
    getInvoices().then(({ errors, data, success }) => {
      if (success) {
        setInvoices(data);
        setLoading(false);
      }
    });
  }, []);

  return (
    <>
      <Heading variant="title">Mis facturas</Heading>
      <Divider my={2} />
      <HStack justifyContent={"flex-end"}>
        <Menu closeOnSelect={false}>
          <HStack>
            <Button variant='secondary'>Exportar</Button>
            <MenuButton>
              <Box>
                <Button>Limpiar Filtros</Button>
              </Box>
            </MenuButton>
            <MenuButton as={Button}>
              <p>
                Filtrar por <i class="bi bi-caret-down-fill"></i>
              </p>
            </MenuButton>
          </HStack>
          <MenuList>
            <MenuGroup title="Estatus">
              <MenuItem>
                <Checkbox value={"1"} iconColor={"white"}>
                  Pendiente
                </Checkbox>
              </MenuItem>
              <MenuItem>
                <Checkbox value={"2"} iconColor={"white"}>
                  Aceptada
                </Checkbox>
              </MenuItem>
              <MenuItem>
                <Checkbox value={"3"} iconColor={"white"}>
                  Rechazada
                </Checkbox>
              </MenuItem>
            </MenuGroup>
            <MenuDivider />
            <MenuGroup title="Fecha de vencimiento">
              <MenuItem>
                <Box mr="5">
                  <Text mb="8px">Inicio </Text>
                  <Input
                    onClick={(e) => e.stopPropagation()}
                    placeholder="Select Date and Time"
                    size="md"
                    type="date"
                  />
                </Box>
                <Box>
                  <Text mb="8px">Fin</Text>
                  <Input
                    onClick={(e) => e.stopPropagation()}
                    placeholder="Select Date and Time"
                    name="end_date"
                    size="md"
                    type="date"
                  />
                </Box>
              </MenuItem>
            </MenuGroup>
            <MenuDivider />
            <MenuGroup title="Factura">
              <MenuItem>
                <Input
                  onClick={(e) => e.stopPropagation()}
                  placeholder="Filtrar..."
                  mb={4}
                />
              </MenuItem>
            </MenuGroup>
          </MenuList>
        </Menu>
      </HStack>
      <Box mt={5}>
        <Table>
          <Thead>
            <Tr>
              <Th textAlign="center">
                {selection.length ? (
                  <Button variant="primary" onClick={handlePayMultipleInvoice}>
                    Pagar
                  </Button>
                ) : (
                  ""
                )}
              </Th>
              <Th>Id Factura</Th>
              <Th>Total</Th>
              <Th>Abonado</Th>
              <Th>Fecha de vencimiento</Th>
              <Th>Estatus</Th>
              <Th>Descargar</Th>
              <Th>Acciones</Th>
            </Tr>
          </Thead>
          <Tbody>
            {!loading &&
              invoices.map((invoice) => (
                <Tr>
                  <Td textAlign="center">
                    <Checkbox
                      onChange={(e) => toggleInvoices(e, invoice)}
                      size="lg"
                      iconColor="white"
                    />
                  </Td>
                  <Td>{invoice.id}</Td>
                  <Td>{MXNFormat.format(invoice.total)}</Td>
                  <Td>{MXNFormat.format(invoice.payment)}</Td>
                  <Td>{invoice.due_date}</Td>
                  <Td>
                    <Badge rounded='15px' py={1} px={3}>Pendiente</Badge>
                  </Td>
                  <Td>
                    <HStack>
                      <IconButton
                        icon={
                          <i
                            class="bi bi-filetype-pdf"
                            style={{ fontSize: 26 }}
                          ></i>
                        }
                      />
                      <IconButton
                        icon={
                          <i
                            class="bi bi-filetype-xml"
                            style={{ fontSize: 26 }}
                          ></i>
                        }
                      />
                    </HStack>
                  </Td>
                  <Td>
                    <Button
                      onClick={() => handlePayInvoice(invoice)}
                      variant="primary"
                    >
                      Pagar
                    </Button>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </Box>
    </>
  );
};

export default InvoicesListPage;
