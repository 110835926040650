import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Button, Checkbox, CheckboxGroup, Divider, FormControl, FormErrorMessage, FormLabel, Grid, GridItem, HStack, Heading, Input, InputGroup, InputRightElement, Select, Skeleton, Stack } from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useUser } from '../../hooks/useUser'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import * as Yup from 'yup';
import { colors } from '../../themes/colors';

const NewUserPage = () => {

  const { token } = useSelector((state) => state.user.data);
  const { user_id } = useParams();
  const { getUserRoles, getUserData, updateUserData, createUser } = useUser();
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  const [viewPassword, setViewPassword] = useState(false);
  const [roles, setRoles] = useState(null);

  const handleNumericInput = (e, setFieldValue) => {
    const value = e.target.value;
    const numericValue = value.replace(/[^\d]/g, '');
    setFieldValue('telephone', numericValue);
  };

  useEffect(() => {
    getUserRoles(token)
      .then((data) => {
        console.log(data);
        setRoles(data);
      })
  }, []);

  useEffect(() => {
    if (user_id) {
      getUserData(token, user_id)
        .then((data) => {
          setLoading(false);
          setUser(data);
        });
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <>
      {
        !loading
          ? (
            <Grid templateColumns={['repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(2, 1fr)']} gap={5} >
              <GridItem>
                <Heading variant='title'>{(user_id) ? 'Editar' : 'Crear'} usuario</Heading>
                <Formik
                  initialValues={{
                    role_id: user?.role_id || '',
                    name: user?.name || '',
                    last_name: user?.last_name || '',
                    vatnum: user?.vatnum || '',
                    company_name: user?.company_name || '',
                    email: user?.email || '',
                    telephone: user?.telephone || '',
                    password: null,
                    generate_password: false
                  }}
                  validateOnBlur={false}
                  validateOnChange={false}
                  validationSchema={Yup.object({
                    role_id: Yup.number().required(),
                    name: Yup.string().required(),
                    last_name: Yup.string().required(),
                    vatnum: Yup.string().min(12).max(13).required(),
                    company_name: Yup.string().required(),
                    email: Yup.string().email().required(),
                    telephone: Yup.number().required(),
                    role_id: Yup.number().required(),
                    generate_password: Yup.boolean(),
                    password: Yup.string().when([], {
                      is: () => user_id,
                      then: (schema) => schema.notRequired(),
                      otherwise: (schema) => schema.when('generate_password', {
                        is: false,
                        then: (schema) => schema.required('Es necesaria una contraseña.'),
                        otherwise: (schema) => schema.notRequired()
                      })
                    })
                  })}
                  onSubmit={async (values, { setErrors }) => {
                    const body = {
                      name: values.name,
                      last_name: values.last_name,
                      vatnum: values.vatnum,
                      company_name: values.company_name,
                      email: values.email,
                      telephone: values.telephone,
                      role_id: values.role_id,
                      ...(values.password !== null ? { password: values.password } : {})
                    };

                    const { success, errors, message } = (user_id) ? await updateUserData(token, body, user_id) : await createUser(token, body);

                    if (success) {
                      Swal.fire({
                        title: "Usuario editado",
                        text: message,
                        icon: "success",
                        confirmButtonColor: colors['secondary']['900']
                      });
                    } else {
                      setErrors(errors);
                      console.log(errors);
                    }
                  }}
                >
                  {({ getFieldProps, values, errors, touched, setFieldValue }) => (
                    <Form>
                      <FormControl isInvalid={(errors.role_id && touched.role_id)} isRequired>
                        <FormLabel>Tipo de usuario</FormLabel>
                        <Select {...getFieldProps('role_id')}>
                          <option>Selecciona un tipo de usuario</option>
                          {
                            (roles) && roles.map((role) => <option key={role.id} value={role.id}>{role.role}</option>)
                          }
                        </Select>
                        <FormErrorMessage className='form-error-message'>
                          {errors.role_id}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl mt={3} isInvalid={(errors.company_name && touched.company_name)} isRequired>
                        <FormLabel>Razón social</FormLabel>
                        <Input type='text' {...getFieldProps('company_name')} />
                        <FormErrorMessage className='form-error-message'>
                          {errors.company_name}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl mt={3} isInvalid={(errors.vatnum && touched.vatnum)} isRequired>
                        <FormLabel>RFC</FormLabel>
                        <Input type='text' {...getFieldProps('vatnum')} maxLength={13} />
                        <FormErrorMessage className='form-error-message'>
                          {errors.vatnum}
                        </FormErrorMessage>
                      </FormControl>
                      <Stack flexDir={['column', 'column', 'row', 'row']}>
                        <FormControl mt={3} isInvalid={(errors.name && touched.name)} isRequired>
                          <FormLabel>Nombre</FormLabel>
                          <Input type='text' {...getFieldProps('name')} />
                          <FormErrorMessage className='form-error-message'>
                            {errors.name}
                          </FormErrorMessage>
                        </FormControl>
                        <FormControl mt={3} isInvalid={(errors.last_name && touched.last_name)} isRequired>
                          <FormLabel>Apellido</FormLabel>
                          <Input type='text' {...getFieldProps('last_name')} />
                          <FormErrorMessage className='form-error-message'>
                            {errors.last_name}
                          </FormErrorMessage>
                        </FormControl>
                      </Stack>
                      <FormControl mt={3} isInvalid={(errors.email && touched.email)} isRequired>
                        <FormLabel>Correo</FormLabel>
                        <Input type='text' {...getFieldProps('email')} />
                        <FormErrorMessage className='form-error-message'>
                          {errors.email}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl mt={3} isInvalid={(errors.telephone && touched.telephone)} isRequired>
                        <FormLabel>Teléfono</FormLabel>
                        <Input type='text' {...getFieldProps('telephone')} maxLength={10} onChange={(e) => handleNumericInput(e, setFieldValue)} />
                        <FormErrorMessage className='form-error-message'>
                          {errors.telephone}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl hidden={values.generate_password} isInvalid={(errors.password && touched.password)} mt={3}>
                        <FormLabel>Contraseña</FormLabel>
                        <InputGroup>
                          <Input type={(viewPassword) ? 'text' : 'password'} {...getFieldProps('password')} />
                          <InputRightElement as='button' type='button' onClick={() => setViewPassword(!viewPassword)}>
                            <i className='bi bi-eye'></i>
                          </InputRightElement>
                        </InputGroup>
                        <FormErrorMessage className='form-error-message'>
                          {errors.password}
                        </FormErrorMessage>
                      </FormControl>
                      {
                        (user_id) && <small style={{color: 'gray'}}>Dejar vació y no generar contraseña, para no aplicar cambios en la contraseña.</small>
                      }
                      <Checkbox iconColor='white' {...getFieldProps('generate_password')}>Generar contraseña automática</Checkbox>
                      <Stack flexDir={['column-reverse', 'column-reverse', 'row', 'row']} mt={3} justifyContent='space-between'>
                        <Button variant='secondary'>Cancelar</Button>
                        <Stack flexDir={['column-reverse', 'column-reverse', 'row', 'row']}>
                          <Button variant='secondary'>Reenviar credenciales</Button>
                          <Button type='submit' variant='primary'>{(user_id) ? 'Editar' : 'Crear'} usuario</Button>
                        </Stack>
                      </Stack>
                    </Form>
                  )}
                </Formik>
              </GridItem>
              <GridItem>
                <Heading variant='title'>Tipos de usuario</Heading>
                <Accordion
                  border='1px solid #e0e0e0'
                  borderRadius={4}
                  mt={3}
                  allowMultiple
                  defaultIndex={[0]}
                >
                  <AccordionItem>
                    <AccordionButton>
                      <HStack w='100%' justifyContent='space-between'>
                        <Heading fontSize='lg'>Cliente</Heading>
                        <AccordionIcon />
                      </HStack>
                    </AccordionButton>
                    <AccordionPanel>
                      Funcionalidad completa de parte del cliente, para todo el servicio digital.
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionButton>
                      <HStack w='100%' justifyContent='space-between'>
                        <Heading fontSize='lg'>Altas</Heading>
                        <AccordionIcon />
                      </HStack>
                    </AccordionButton>
                    <AccordionPanel>
                      Tiene la funcionalidad de dar de alta a los nuevos usuarios.
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionButton>
                      <HStack w='100%' justifyContent='space-between'>
                        <Heading fontSize='lg'>Cobranza</Heading>
                        <AccordionIcon />
                      </HStack>
                    </AccordionButton>
                    <AccordionPanel>
                      Tiene la funcionalidad de bloquear el acceso a un usuario por falta de pago.
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionButton>
                      <HStack w='100%' justifyContent='space-between'>
                        <Heading fontSize='lg'>Comercial</Heading>
                        <AccordionIcon />
                      </HStack>
                    </AccordionButton>
                    <AccordionPanel>
                      Tiene la funcionalidad únicamente de visualizar la información de los clientes.
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionButton>
                      <HStack w='100%' justifyContent='space-between'>
                        <Heading fontSize='lg'>SuperAdmin</Heading>
                        <AccordionIcon />
                      </HStack>
                    </AccordionButton>
                    <AccordionPanel>
                      Es quien tiene todos los permisos
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </GridItem>
            </Grid >
          )
          : (
            <Stack mt={10}>
              <Skeleton height='30px' />
              <Skeleton mt={3} height='30px' />
              <Skeleton mt={3} height='30px' />
              <Skeleton mt={3} height='30px' />
              <Skeleton mt={3} height='30px' />
              <Skeleton mt={3} height='30px' />
              <Skeleton mt={3} height='30px' />
            </Stack>
          )
      }
    </>
  )
}

export default NewUserPage