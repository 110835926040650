import { Badge, Box, Button, Card, CardBody, Divider, HStack, Heading, Input, ListItem, Menu, MenuButton, MenuDivider, MenuGroup, MenuItem, MenuList, Select, Skeleton, Stack, Text, UnorderedList } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { useNews } from '../../../hooks/useNews'
import Paginator from '../../../components/partials/Paginator'

export const ListNewsPage = () => {

  const navigate = useNavigate();
  const { getNews, deleteNews, getNewsTypes } = useNews();
  const [news, setNews] = useState([]);
  const [ paginator, setPaginator ] = useState(null);
  const [ currentUrl, setCurrentUrl ] = useState(null);
  const [newsTypes, setNewsTypes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [ searchParams, setSearchParams ] = useSearchParams();
  const [ hidden, setHidden ] = useState(true);

  const handleNewsFilter = (e) => {
    const { name, value } = e.target;

    console.log(name, value);

    const newParams = new URLSearchParams(searchParams)

    if(value !== ''){ 
      newParams.set(name, value);
      setHidden(false);
    } else {
      newParams.delete(name);
      setHidden(true);
    }

    setSearchParams(newParams);
  };

  const handleCleanFilters = () => {
    setSearchParams({});
    setHidden(true);
  };

  const handleEditNews = async (id) => {
    try {
      let res = await getNews(id);
      if (!res) {
        alert("Error getting the information of this address");
        return;
      }
      navigate(`/noticia/edit/${id}`);
    } catch (err) {
      console.error(err);
    }
  };

  const handleDeleteNews = async (id) => {
    const { success, message } = await deleteNews(id);
    if (success) {
      setNews(() => news.filter((news) => id !== news.id));
    } else {
      alert(message);
    }
  }

  useEffect(() => {
      getNews(null, currentUrl, searchParams)
        .then((result) => {
          setNews(result.data);
          setPaginator(result);
          setIsLoading(false);
        });
  }, [currentUrl, searchParams])

  useEffect(() => {
    (async () => {
      const result = await getNewsTypes();
      setNewsTypes(result);
    })();
  }, []);

  return (
    <>
      <Heading variant='title'>Creador de anuncios</Heading>
      <Divider my={2} />
      <HStack justifyContent='space-between'>
        <Menu closeOnSelect={false}>
          <Button variant='primary' onClick={() => navigate('/noticia')}>Generar anuncio</Button> 
          <HStack>
            <MenuButton 
              hidden={hidden}
              onClick={handleCleanFilters}
            >
              <Box>
                <Button>
                  Limpiar Filtros
                </Button>
              </Box>
            </MenuButton>
            <MenuButton as={Button}>
              <p>Filtrar por <i class="bi bi-caret-down-fill"></i></p>
            </MenuButton>
          </HStack>
          <MenuList>
            <MenuGroup title='Fecha'>
              <MenuItem>
                <Box mr='5'>
                  <Text mb='8px'>Inicio </Text>
                  <Input
                    onClick={e => e.stopPropagation()}
                    placeholder="Select Date and Time"
                    value={searchParams?.start_date}
                    name='start_date'
                    onChange={handleNewsFilter}
                    size="md"
                    type="date"
                  />
                </Box>
                <Box>
                  <Text mb='8px'>Fin</Text>
                  <Input
                    onClick={e => e.stopPropagation()}
                    placeholder="Select Date and Time"
                    value={searchParams?.end_date}
                    name='end_date'
                    onChange={handleNewsFilter}
                    size="md"
                    type="date"
                  />
                </Box>
              </MenuItem>
            <MenuDivider />
            </MenuGroup>
            <MenuGroup title='Búsqueda por titulo'>
              <MenuItem>
                <Input
                  onClick={e => e.stopPropagation()}
                  placeholder="Filtrar..."
                  onChange={handleNewsFilter}
                  name='title'
                />
              </MenuItem>
              <MenuDivider />
              <MenuGroup title='Estatus'>
                <MenuItem>
                  <Select
                    onClick={e => e.stopPropagation()}
                    placeholder='Selecciona el tipo de anuncio'
                    name='notification_type'
                    onChange={handleNewsFilter}
                    mb={4}
                  >
                    <option value='1'>Nueva funcionalidad</option>
                    <option value='2'>Noticia</option>
                  </Select>
                </MenuItem>
              </MenuGroup>
            </MenuGroup>
          </MenuList>
        </Menu>
      </HStack>
      <Divider my={2} />
      {
        !isLoading
          ?
          <Box mt='10px'>
            <Box>
              {
                news.map((noti) => (
                  <UnorderedList key={noti.id}>
                    <ListItem my='10px'>
                      <Card w='100%'>
                        <CardBody display='flex' justifyContent='space-between' w='100%' paddingY={0}>
                          <Link to={`/noticia/${noti.id}`} style={{flex: 1}}>
                            <Stack h='100%' justifyContent='center' paddingY={5}>
                              <Box display='flex' alignItems='center' gap='10px' >
                                <Heading size='md'>{noti.title}</Heading>
                                {
                                  newsTypes && newsTypes.filter(news => news.id === Number(noti.notification_type)).map((filteredNews) => (
                                    <Badge rounded='15px' py={1} px={3} colorScheme={(filteredNews.id === 1) ? 'purple' : 'blue'} key={filteredNews.id}>
                                      {filteredNews.type}
                                    </Badge>
                                  ))
                                }
                              </Box>
                            </Stack>
                          </Link>
                          <Stack flexDirection='row' alignItems='center'>
                            <Stack>
                              <Text mx='10px'>
                                {
                                  noti.created_at = noti.created_at.slice(0, 19).replace("T", " ")
                                }
                              </Text>
                            </Stack>
                            <Menu>
                              <MenuButton as={Button}>
                                <i className='bi bi-three-dots-vertical'></i>
                              </MenuButton>
                              <MenuList>
                                <MenuItem hidden={noti.launched || !noti.launch_date} onClick={() => handleEditNews(noti.id)} icon={<i className='bi bi-pencil-square' style={{ fontSize: '18px' }}></i>}>Editar</MenuItem>
                                <MenuItem onClick={() => handleDeleteNews(noti.id)} icon={<i className='bi bi-trash-fill' style={{ fontSize: '18px' }}></i>}>Eliminar</MenuItem>
                              </MenuList>
                            </Menu>
                          </Stack>
                        </CardBody>
                      </Card>
                    </ListItem>
                    <Divider />
                  </UnorderedList>
                ))}
            </Box>
            {
              !isLoading && <Paginator setCurrentUrl={setCurrentUrl} paginator={paginator} />
            }
          </Box>
          :
          <Stack mt={10}>
            <Skeleton height='30px' />
            <Skeleton mt={3} height='30px' />
            <Skeleton mt={3} height='30px' />
            <Skeleton mt={3} height='30px' />
            <Skeleton mt={3} height='30px' />
            <Skeleton mt={3} height='30px' />
            <Skeleton mt={3} height='30px' />
          </Stack>
      }
    </>
  )
}
