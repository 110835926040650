import React, { useState } from 'react'
import { useSelector } from 'react-redux';

const useCreditRequest = () => {

    const base_url = `${process.env.REACT_APP_API_URL}/api/v1/credit_requests`;
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }

    const { token } = useSelector((state) => state.user.data);
    const { credit_id } = useSelector((state) => state.credit_request);

    const getCreditRequests = async (url, filters) => {
        // url = url || `${base_url}`;

        if(url) {
            url = filters === '' ? url : (!filters ? url : `${url}&` + filters.toString());
        } else {
            url = filters === '' ? base_url : (!filters ? base_url : `${base_url}?` + filters.toString());
        }

        console.log(url);
        const res = await fetch(url, {
            method: 'GET',
            headers: {
                ...headers,
                'Authorization': `Bearer ${token}`
            }
        });
        const { data } = await res.json();

        return data;
    }

    const createCreditRequest = async () => {
        const res = await fetch(`${base_url}/new`, {
            method: 'GET',
            headers: {
                ...headers,
                'Authorization': `Bearer ${token}`
            }
        });

        if(res.status == 200) {
            return await res.json();
        } else {
            return 0;
        }
    }

    const createFiscalAddress = async (fiscalAddress, credit_id) => {
        const res = await fetch(`${base_url}/add_fiscal_address/${credit_id}`, {
            method: 'POST',
            body: JSON.stringify(fiscalAddress),
            headers: {
                ...headers,
                'Authorization': `Bearer ${token}`
            }
        });

        if(res.status == 200)  {
            const data = await res.json();
            return data;
        } else { 
            return 0;
        }
    }

    const addFiscalDataToCR = async (fiscalData, credit_id) => {
        const res = await fetch(`${base_url}/add_invoice_data/${credit_id}`, {
            method: 'POST',
            body: JSON.stringify(fiscalData),
            headers: {
                ...headers,
                'Authorization': `Bearer ${token}`
            }
        });

        if(res.status == 200) {
            const data = await res.json();
            return data;
        } else {
            return 0;
        }
    }

    const modifyCreditInformation = async (values, credit_id) => {
        const res = await fetch(`${base_url}/${credit_id}`, {
            method: 'PUT',
            body: JSON.stringify(values),
            headers: {
                ...headers,
                'Authorization': `Bearer ${token}`
            }
        });

        if(res.status == 200) {
            const data = await res.json();
            return data;
        } else {
            return 0;
        }
    }

    const addPaymentProccess = async (values, credit_id) => {
        console.log(values);
        const res = await fetch(`${base_url}/add_payment_proccess/${credit_id}`, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                ...headers,
                'Authorization': `Bearer ${token}`
            }
        });

        if(res.status == 200) {
            const data = await res.json();
            return data;
        } else {
            const data = await res.json();
            console.log(data);
            return 0;
        }
    }

    const addExecutiveToCR = async (body) => {
        const res = await fetch(`${base_url}/add_executive/${credit_id}`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                ...headers,
                'Authorization': `Bearer ${token}`
            }
        });

        if(res.status == 200) {
            const data = await res.json();

            return data;
        } else {
            return {
                success: false,
                errors: {
                    global: 'Hubo un error con la petición o con el servidor, intenta de nuevo.'
                }
            }
        }
    }

    const updateExecutiveToCR = async (body, executive_id) => {
        const res = await fetch(`${base_url}/update_executive/${executive_id}`, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                ...headers,
                'Authorization': `Bearer ${token}`
            }
        });

        if(res.status == 200) {
            const data = await res.json();

            return data;
        } else {
            return {
                success: false,
                errors: {
                    global: 'Hubo un error con la petición o con el servidor, intenta de nuevo.'
                }
            }
        }
    }

    const deleteExecutiveToCR = async (id) => {
        const res = await fetch(`${base_url}/delete_executive/${id}`, {
            method: 'DELETE',
            headers: {
                ...headers,
                'Authorization': `Bearer ${token}`
            }
        });
        const data = await res.json();
        return data; 
    }

    const addCommercialReferenceToCR = async (body) => {
        console.log(body);
        const res = await fetch(`${base_url}/add_commercial_reference/${credit_id}`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                ...headers,
                'Authorization': `Bearer ${token}`
            }
        });

        if(res.status == 200) {
            const data = await res.json();

            return data;
        } else {
            return {
                success: false,
                errors: {
                    global: 'Hubo un error con la petición o con el servidor, intenta de nuevo.'
                }
            }
        }
    }

    const updateCommercialReferenceToCR = async (body, ref_id) => {
        const res = await fetch(`${base_url}/update_commercial_reference/${ref_id}`, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                ...headers,
                'Authorization': `Bearer ${token}`
            }
        });

        if(res.status == 200) {
            const data = await res.json();

            return data;
        } else {
            return {
                success: false,
                errors: {
                    global: 'Hubo un error con la petición o con el servidor, intenta de nuevo.'
                }
            }
        }
    }

    const deleteCommercialReferenceToCR = async (id) => {
        const res = await fetch(`${base_url}/delete_commercial_reference/${id}`, {
            method: 'DELETE',
            headers: {
                ...headers,
                'Authorization': `Bearer ${token}`
            }
        });
        const data = await res.json();
        return data; 
    }

    const getCreditRequestInfo = async (credit_id) => {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/credit_requests/${credit_id}`, {
            headers: {
                ...headers,
                'Authorization': `Bearer ${token}`
            }
        });
        const { data } = await res.json();

        return data;
    }

    const notifyReviewToUser = async (credit_id) => {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/credit_requests/notify_review/${credit_id}`, {
            headers: {
                ...headers,
                'Authorization': `Bearer ${token}`
            }
        });
        const data = await res.json();

        return data;
    }

    const changeStatusToCR = async (status_id, credit_id) => {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/credit_requests/change_status/${credit_id}`, {
            method: 'POST',
            body: JSON.stringify({
                status_id
            }),
            headers: {
                ...headers,
                'Authorization': `Bearer ${token}`
            }
        });

        if(res.ok) {
            const data = await res.json();
            return data;
        } else {
            return {
                errors: 'Hubo un error con tu consulta.'
            }
        }
    }

    const authorizeCR = async (values, credit_id) => {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/credit_requests/authorize/${credit_id}`, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                ...headers,
                'Authorization': `Bearer ${token}`
            }
        });

        if(res.ok) {
            const data = await res.json();
            return data;
        } else {
            return {
                errors: 'Hubo un error con tu consulta.'
            }
        }
    }

    const addCommentToDocumentationCR = async (document, comment, cr_id) => {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/credit_requests/comments/${cr_id}`, {
            method: 'POST',
            body: JSON.stringify({
                document,
                comment
            }),
            headers: {
                ...headers,
                'Authorization': `Bearer ${token}`
            }
        });

        if(res.ok) {
            const data = await res.json();
            return data;
        } else {
            return {
                errors: 'Hubo un error con tu consulta.'
            }
        }
    }

    const addCommentToComRef = async (comment, com_ref_id) => {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/credit_requests/comments_to_commercial_reference/${com_ref_id}`, {
            method: 'POST',
            body: JSON.stringify({
                comment
            }),
            headers: {
                ...headers,
                'Authorization': `Bearer ${token}`
            }
        });

        if(res.ok) {
            const data = await res.json();
            return data;
        } else {
            return {
                errors: 'Hubo un error con tu consulta.'
            }
        }
    }

    return {
        getCreditRequests,
        getCreditRequestInfo,
        createCreditRequest,
        createFiscalAddress,
        addFiscalDataToCR,
        modifyCreditInformation,
        addPaymentProccess,
        addExecutiveToCR,
        updateExecutiveToCR,
        deleteExecutiveToCR,
        addCommercialReferenceToCR,
        updateCommercialReferenceToCR,
        deleteCommercialReferenceToCR,
        changeStatusToCR,
        addCommentToDocumentationCR,
        addCommentToComRef,
        notifyReviewToUser,
        authorizeCR
    };
}

export default useCreditRequest