import { calcLength } from 'framer-motion';
import { useSelector } from 'react-redux';

export const useNews = () => {
    
    const base_url = `${process.env.REACT_APP_API_URL}/api/v1/news`;
    
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
    
    const { token } = useSelector((state) => state.user.data);
    
    const getNews = async (id = null, url, filters) => {
        const new_url = (id) ? `${base_url}/${id}` : `${base_url}`;

        if(url) {
            url = filters === '' ? url : (!filters ? url : `${url}&` + filters.toString());
        } else {
            url = filters === '' ? new_url : (!filters ? new_url : `${new_url}?` + filters.toString());
        }

        const res = await fetch(url, {
            method: 'GET',
            headers: {
                ...headers,
                'Authorization': `Bearer ${token}`
            }
        });
        
        const { data } = await res.json();
        return data;
    }

    const createNews = async (values) => {
        const res1 = await fetch(`${base_url}`, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                ...headers,
                'Authorization': `Bearer ${token}`
            }
        });

        if(res1.status === 200)  {
            const data = await res1.json();
            return data;
        } else { 
            return 0;
        }
    }

    const setImage = async (body, id) => {
        const res = await fetch(`${base_url}/add/image/${id}`, {
            method: 'POST',
            body,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        if(res.status === 200)  {
            const data = await res.json();
            return data;
        } else { 
            return 0;
        }
    }

    const updateNews = async (values, id) => {
        const res = await fetch(`${base_url}/${id}`, {
            method: 'PUT',
            body: JSON.stringify(values),
            headers: {
                ...headers,
                'Authorization': `Bearer ${token}`
            }
        });

        if(res.status == 200) {
            const data = await res.json();
            return data;
        } else {
            return {
                success: false,
                errors: {
                    global: 'Hubo un error con la petición o con el servidor, intenta de nuevo.'
                }
            }
        }
    }

    const deleteNews = async (id) => {
        const res = await fetch(`${base_url}/${id}`, {
            method: 'DELETE',
            headers: {
                ...headers,
                'Authorization': `Bearer ${token}`
            }
        });

        const data = await res.json();
        return data; 
    }

    const getNewsTypes = async () => {
        const res = await fetch(`${base_url}/types`, {
            method: 'GET',
            headers: {
                ...headers,
                'Authorization': `Bearer ${token}`
            }
        })

        const data = await res.json();
        return data;
    }


  return {
    createNews,
    getNews,
    deleteNews,
    updateNews,
    setImage,
    getNewsTypes
  };
}
