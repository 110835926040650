import { Box, Button, Divider, Grid, GridItem, HStack, Heading, Link, Tab, TabList, TabPanel, TabPanels, Tabs, Text, VStack } from '@chakra-ui/react'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MXNFormat } from '../../helpers/general_functions';
import spei from '../../assets/images/payment/spei.png';
import amex from '../../assets/images/payment/amex.png';
import visa from '../../assets/images/payment/visa-and-mc.png';
import carnet from '../../assets/images/payment/carnet.png';
import 'react-credit-cards-2/dist/lib/styles.scss';
import { useNavigate } from 'react-router-dom';
import CardForm from '../../components/invoice/CardForm'
import usePayment from '../../hooks/usePayment';
import Swal from 'sweetalert2';
import { colors } from '../../themes/colors';
import { completeCart } from '../../redux/slices/cartSlice';

const InvoicePaymentPage = () => {

    const { cardPayment, referencePayment } = usePayment();
    const [paymentData, setPaymentData] = useState(null);
    const [processLoading, setProcessLoading] = useState(false);
    const cart = useSelector((state) => state.cart);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleCancelPayment = () => {
        navigate('/facturas');
    }

    const handleCardPayment = (values, { setErrors, setSubmitting }) => {
        setProcessLoading(true);
        window.OpenPay.setId(process.env.REACT_APP_OP_MERCHANT_ID);
        window.OpenPay.setApiKey(process.env.REACT_APP_OP_PUBLIC_KEY);
        window.OpenPay.setSandboxMode(true);

        let deviceDataId = window.OpenPay.deviceData.setup();
        let expirations = values.expiry.split("/");

        window.OpenPay.token.create({
            card_number: values.card_number.replaceAll(' ', ''),
            holder_name: values.holder_name,
            expiration_year: expirations[1],
            expiration_month: expirations[0],
            cvv2: values.cvv2
        }, ({ data }) => {
            // TODO: CREAR CARGO
            cardPayment({
                source_id: data.id,
                amount: cart.subtotal,
                device_session_id: deviceDataId
            })
                .then((data) => {
                    if (data.success) {
                        Swal.fire({
                            title: 'Cargo realizado',
                            text: 'Se ha realizado el cargo correctamente.',
                            icon: 'success',
                            showCloseButton: false,
                            didClose: () => {
                                navigate('/facturas');
                            },
                            confirmButtonColor: colors['primary']['900']
                        })
                            .then((result) => {
                                if (result.isConfirmed) {
                                    navigate('/facturas');
                                }
                            });
                    } else {
                        setErrors(data.errors);
                    }

                    setProcessLoading(false);
                    setSubmitting(false);
                })
        }, (response) => {
            setErrors({
                global: response.data.description
            });
            setSubmitting(false);
        });

    }

    const handleReferencePayment = (method) => {

        setProcessLoading(true);
        const payment_data = {
            amount: cart.subtotal,
            method,
        }

        referencePayment(payment_data)
            .then(({ errors, success, data }) => {
                if (success) {
                    setPaymentData(data.charge);
                    dispatch(completeCart());
                } else {
                    console.log(errors);
                }

                setProcessLoading(false);
            });
    }

    return (
        <>
            <Grid templateColumns={['repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(5, 1fr)', 'repeat(5, 1fr)']} gap={5}>
                <GridItem colSpan={3}>
                    {
                        !cart.completed || !paymentData
                            ? (
                                <>
                                    <Heading mb={3} variant='title' fontSize='2xl'>Realiza tu pago</Heading>
                                    <VStack>
                                        <Box w='100%' textAlign='center' padding={3}>
                                            <Tabs isFitted>
                                                <TabList>
                                                    <Tab>
                                                        <Box h='40px'>
                                                            <VStack h='100%'>
                                                                <HStack h='50%'>
                                                                    <img style={{ height: '100%' }} src={visa} />
                                                                    <img style={{ height: '100%' }} src={amex} />
                                                                </HStack>
                                                                <small>Tarjeta crédito/debito</small>
                                                            </VStack>
                                                        </Box>
                                                    </Tab>
                                                    <Tab>
                                                        <Box h='40px'>
                                                            <img style={{ height: '100%' }} src={spei} />
                                                        </Box>
                                                    </Tab>
                                                    <Tab>
                                                        <Box h='40px'>
                                                            <VStack h="100%">
                                                                <Text>Paynet</Text>
                                                                <small>Pago en tiendas afiliadas</small>
                                                            </VStack>
                                                        </Box>
                                                    </Tab>
                                                </TabList>
                                                <TabPanels>
                                                    <TabPanel>
                                                        <CardForm
                                                            primaryButtonTitle='Pagar'
                                                            loadingButtonTitle='Realizando cargo...'
                                                            onSubmit={handleCardPayment}
                                                            cancelFunction={handleCancelPayment}
                                                        />
                                                    </TabPanel>
                                                    <TabPanel>
                                                        <Button
                                                            w='full'
                                                            icon={<i className='bi bi-people'></i>}
                                                            fontSize='lg'
                                                            backgroundColor='secondary.900'
                                                            _hover={{
                                                                backgroundColor: 'secondary.700'
                                                            }}
                                                            color='white'
                                                            py={8}
                                                            onClick={() => handleReferencePayment('bank_account')}
                                                            isLoading={processLoading}
                                                        >
                                                            Generar referencia bancaria
                                                        </Button>
                                                    </TabPanel>
                                                    <TabPanel>
                                                        <Button
                                                            w='full'
                                                            icon={<i className='bi bi-people'></i>}
                                                            fontSize='lg'
                                                            backgroundColor='secondary.900'
                                                            _hover={{
                                                                backgroundColor: 'secondary.700'
                                                            }}
                                                            color='white'
                                                            py={8}
                                                            onClick={() => handleReferencePayment('store')}
                                                            isLoading={processLoading}
                                                        >
                                                            Generar referencia
                                                        </Button>
                                                    </TabPanel>
                                                </TabPanels>
                                            </Tabs>
                                        </Box>
                                    </VStack>
                                </>
                            )
                            : (
                                <>
                                    <VStack
                                        w='full'
                                    >
                                        <Heading mb={3} variant='title' fontSize='2xl'>Tu pago ha sido realizado correctamente.</Heading>
                                        <Box
                                            w='25%'
                                        >
                                            <svg viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
                                                <g stroke="currentColor" stroke-width="2" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                                                    <path class="circle" d="M13 1C6.372583 1 1 6.372583 1 13s5.372583 12 12 12 12-5.372583 12-12S19.627417 1 13 1z" />
                                                    <path class="tick" d="M6.5 13.5L10 17 l8.808621-8.308621" />
                                                </g>
                                            </svg>
                                        </Box>
                                        <Box w='full' my={2}>
                                            <Text>A tu factura se le ha generado una referencia para que puedas realizar el pago, para continuar es necesario que descargues el archivo PDF, que se anexa.</Text>
                                            <Link
                                                href={paymentData.url}
                                                target='_blank'
                                                download={true}
                                            >
                                                <Button 
                                                    mt={2}
                                                    bgColor='transparent'
                                                    borderColor='primary.900'
                                                    color='primary.900'
                                                    fontSize='lg' 
                                                    borderWidth={3} 
                                                    w='100%' 
                                                    borderRadius={3} 
                                                    py={5}
                                                    _hover={{
                                                        bgColor: 'blue.100',
                                                    }}
                                                >
                                                    <i className='bi bi-filetype-pdf'></i> Descargar archivo de pago
                                                </Button>
                                            </Link>
                                        </Box>
                                        <Box w='full'>
                                            <Button variant='primary'
                                                onClick={() => navigate('/facturas')}
                                            >
                                                Continuar
                                            </Button>
                                        </Box>
                                    </VStack>
                                </>
                            )
                    }
                </GridItem>
                <GridItem colSpan={2}>
                    <Box w='100%'>
                        <Heading mb={3} variant='title' fontSize='2xl'>Resumen de tu pago</Heading>
                        <VStack w='100%'>
                            {
                                cart.items.map((item) => (
                                    <HStack justifyContent='space-between' w='100%'>
                                        <Text fontSize='md'>{item.name}</Text>
                                        <Text fontSize='md'>{MXNFormat.format(item.total)}</Text>
                                    </HStack>
                                ))
                            }
                        </VStack>
                        <Divider my={3} />
                        <HStack justifyContent='space-between' w='100%'>
                            <Text fontSize='sm'>Subtotal</Text>
                            <Text fontSize='sm'>{MXNFormat.format(cart.subtotal)}</Text>
                        </HStack>
                        <Divider my={3} />
                        <HStack justifyContent='space-between' w='100%'>
                            <Text fontSize='lg' fontWeight='bold'>Total</Text>
                            <Text><small>MXN</small> {MXNFormat.format(cart.subtotal)}</Text>
                        </HStack>
                    </Box>
                </GridItem>
            </Grid >
        </>
    )
}

export default InvoicePaymentPage